import React, { useState, useEffect, useRef, useMemo } from 'react'

import 'react-h5-audio-player/lib/styles.css'

import AudioPlayer from 'react-h5-audio-player'
import './style/audioWidget.css'

import delete2 from '../../assets/imgs/delete2.png'
import pauseimg from '../../assets/imgs/pause.svg'
import playimg from '../../assets/imgs/play.svg'

function AudioWidget(props) {
  const playerRef = useRef(null)
  const [playing, setPlaying] = useState(false)
  const [duration, setDuration] = useState(0)

  const play = () => {
    setPlaying(true)
    playerRef.current.audio.current.play()
  }

  const pause = () => {
    setPlaying(false)
    playerRef.current.audio.current.pause()
  }

  const durationStr = useMemo(() => {
    const durationInt = Math.floor(duration)
    const mins = Math.floor(durationInt / 60)
    const secs = durationInt % 60

    return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`
  }, [duration])

  useEffect(() => {
    if (props.audio) {
      let _player = new Audio(props.audio)
      _player.addEventListener(
        'durationchange',
        function (e) {
          if (this.duration != Infinity) {
            let duration = this.duration
            _player.remove()
            setDuration(duration)
          }
        },
        false,
      )
      _player.load()
      _player.currentTime = 24 * 60 * 60 //fake big time
      _player.volume = 0
      _player.play()
    }
  }, [props.audio])

  useEffect(() => {
    // console.log(durationStr)
  }, [duration])

  return (
    <div>
      {props.audio && props.audioName ? (
        <div className="file_name">
          Name: <span>{props.audioName == '' ? 'N/A' : props.audioName.slice(0,-22) + props.audioName.slice(-4)}</span>
        </div>
      ) : (
        <></>
      )}

      <div
        className="audio_widget_item"
        style={{
          boxShadow:
            '-6px -6px 15px rgba(255, 255, 255, 0.78),6px 6px 16px rgba(0, 0, 0, 0.15)',
        }}
      >
        <div
          style={{
            border: props.status === 'recording' && '1px solid red',
            boxShadow: playing
              ? 'inset -6px -6px 15px rgba(255, 255, 255, 0.78),inset 6px 6px 16px rgba(0, 0, 0, 0.15)'
              : '-6px -6px 8px rgba(255, 255, 255, 0.78), 6px 6px 8px rgba(0, 0, 0, 0.15)',
          }}
        >
          <img
            src={playing ? pauseimg : playimg}
            onClick={(e) => {
              playing ? pause() : play()
            }}
            alt="play"
          />
        </div>

        {props.audio && props.audioName && duration != 0 ? (
          <AudioPlayer
            ref={playerRef}
            src={props.audio}
            defaultDuration={durationStr}
            onEnded={() => {
              setPlaying(false)
            }}
          />
        ) : ( props.audioName &&
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                height: '1.5em',
                width: '1.5em',
              }}
            >
              <div
                className="lds-dual-ring"
                style={{
                  height: '100%',
                  width: '100%',
                }}
              ></div>
            </div>
          </div>
        )}
        {props.audio && props.audioName ? (
          <span className="delete_icon" onClick={() => props.onRemoveAudio()}>
            <img src={delete2} alt="Remove Icon" />
          </span>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default AudioWidget
