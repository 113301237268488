import React, { useState, useContext, useCallback } from "react";
import Dialog from "../Components/Dialog/Dialog";

const DialogContext = React.createContext();

export function useDialog() {
  return useContext(DialogContext);
}

export function DialogProvider({ children }) {
  const [dialog, setDialog] = useState(null);
  const unSetDialog = useCallback(() => {
    setDialog();
  }, [setDialog]);

  const value = {
    unSetDialog,
    setDialog,
  };

  return (
    <DialogContext.Provider value={value}>
      {children}
      {dialog && <Dialog dialog={dialog} unSetDialog={unSetDialog} />}
    </DialogContext.Provider>
  );
}
