import React, { useState, useEffect } from 'react'

import ErrorIcon from '../../assets/imgs/error_icon.png'
import WarningIcon from '../../assets/imgs/warning_icon.png'
import SuccessIcon from '../../assets/imgs/success_icon.png'

import './styles/dialog.css'

function Dialog({ dialog, unSetDialog }) {
  const dialogIcon = () => {
    switch (dialog['type']) {
      case 'error':
        return ErrorIcon
      case 'alert':
        return WarningIcon
      case 'warning':
        return WarningIcon
      case 'success':
        return SuccessIcon
      default:
        return ''
    }
  }

  return (
    <div className="dialog">
      <div
        className="dialog_bg"
        onClick={'isLoader' in dialog ? () => {} : unSetDialog}
      ></div>
      <div
        className="dialog_body"
        style={{ paddingBottom: 'isLoader' in dialog ? '1em' : '30px' ,
                 width: 'isLoader' in dialog ? '20em' : '40vw' }}
      >
        {'isLoader' in dialog ? (
          <div>
            <div className="lds-dual-ring"></div>
            <p>Please wait.</p>
          </div>
        ) : (
          <div>
            <img src={dialogIcon()} alt="dialog_icon" />
            <p>{dialog['message']}</p>
            <div style={{ gap: '20px', display: 'flex' }}>
              <button className="dialog_btn" onClick={dialog['btnAction']}>
                {dialog['btnTxt']}
              </button>
              {dialog['type'] === 'alert' && (
                <button className="dialog_btn" onClick={dialog['btn2Action']}>
                  {dialog['btn2Txt']}
                </button>
              )}
              {dialog['btn3Txt'] !== undefined ?
                <button className="dialog_btn" onClick={dialog['btn3Action']}>
                  {dialog['btn3Txt']}
                </button>
                : null
              }
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Dialog
