import EditIcon from '../../assets/imgs/edit_icon.png'
import DeleteIcon from '../../assets/imgs/delete_icon.png'
import { useNavigate } from 'react-router-dom'
import { useDialog } from '../../Contexts/DialogContext'
import { DELETE_TOUR } from '../../constants'
import axios from 'axios'
import { useAuth } from '../../Contexts/AuthContext'
import { useEffect } from 'react'
import { useCache } from '../../Hooks/useCache'

import route from '../../assets/imgs/route.svg'
import time from '../../assets/imgs/time.svg'
import eye from '../../assets/imgs/eye.svg'
import money from '../../assets/imgs/money.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfStroke } from '@fortawesome/free-solid-svg-icons';
import Rating from '@mui/material/Rating';
import { formatDist, formatDur} from '../../Utils/utils'

import StarBorderIcon from '@mui/icons-material/StarBorder';

import { infoToast, successToast, errorToast, warningToast } from "../Dialog/Toast";

export const TourCard = (props) => {
  const { idToken } = useAuth()
  const { addToCache } = useCache()

  const { setDialog, unSetDialog } = useDialog()
  const showLoaderDialog = () => {
    let dialog = {
      isLoader: true,
    }

    setDialog(dialog)
  }
  const showDialog = (type, message, btnTxt, btnAction) => {
    let dialog = {
      type: type,
      message: message,
      btnTxt: btnTxt != undefined ? btnTxt : 'OK',
      btnAction: btnAction == undefined ? () => unSetDialog() : btnAction,
    }

    setDialog(dialog)
  }

  const deleteTour = async (tourID, callback) => {
    showLoaderDialog()
    await axios
      .delete(DELETE_TOUR, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${idToken}`,
        },
        data: {
          tourId: tourID,
        },
      })
      .then(async (res) => {
        if (res.status == 200) {
          callback()
          successToast("Tour deleted successfully!")
        }
      })
      .catch((e) => {
        unSetDialog()
        console.log(e)
        //showDialog('error', `Failed to delete tour.`)
        errorToast("Failed to delete tour.")
      })
  }

  //TODO: Clean these after resolving at the back-end
  const getTourStatus = (props) => {
    const basicStatus = "complete" in props
                        ? props.complete
                          ? props.published
                            ? 'Published'
                            : props.draft
                              ? 'Draft [Complete]'
                              : 'Suspended'
                          : 'Draft [Incomplete]'
                        : props.published
                          ? 'Published'
                          : props.draft
                            ? 'Draft'
                            : 'Suspended'
    const status = "superSuspended" in props 
                    ? props.superSuspended 
                      ? 'Suspended by Admin'
                      : basicStatus
                    : basicStatus

    return status;
  }

  const getTourStatusTextClass = (props) => {
    const status = getTourStatus(props);

    let cls = '';

    if (status == 'Suspended by Admin' || status == "Suspended"){
      cls = 'suspended_txt';
    } else if (status == 'Published') {
      cls = 'published_txt';
    } 
    
    return cls;
  }

  let navigate = useNavigate()
  return (
    <div
      className="tour_card_encompasser"
      onClick={() => navigate(`/builder/edit/${props['tourId']}`)}
    >
      <div className="tour_card"> 
        {'picturesURLs' in props && props['picturesURLs'].length > 0 ? (
          <img src={props['picturesURLs'][0].link} alt={props.name} />
        ) : (
          <></>
        )}
        <div className="hoverE">
          <div>
            <div className="header">
              <div>
                <div className="tour_name">{props.name.substring(0,27) + (props.name.length > 27 ?  "..." : "")}</div>
                {/*<div>{"ABCD ABCD ABCD ABCD ABCD ABCD ABCD ABCD ".substring(0,27) + ("ABCD ABCD ABCD ABCD ABCD ABCD ABCD ABCD ".length > 27 ?  "..." : "")}</div>*/}
                {/*<div>ABCD ABCD ABCD ABCD ABCD ABCD ABCD ABCD </div>*/}
                {/*<div>ABCD ABCD ABCD ABCD ABCD ABCD </div>*/}
                {/*<div>The Castle Entrance on the south side </div>*/}

                <div
                  className={
                    'tour_status_txt ' + getTourStatusTextClass(props)
                  }
                >
                  {/*{props.published
                    ? 'Published'
                    : props.draft
                    ? 'Draft'
                    : 'Suspended'}*/}

                  {
                    getTourStatus(props)
                  }
                </div>
              </div>

              <div className="rating">
                <Rating
                  name="ratingBar"
                  defaultValue={1}
                  max={1}
                  value={props.ratings 
                          ? props.ratings[props.tourId]
                            ? (props.ratings[props.tourId]["rating"] / 5.0)
                            : 0.0 
                        : 0.0}
                  precision={0.1}
                  emptyIcon={<FontAwesomeIcon icon={faStar} />}
                  icon={<FontAwesomeIcon icon={faStar} style={(props.ratings 
                                                                ? props.ratings[props.tourId]
                                                                    ? {color: '#f5b342',}
                                                                    : {color: '#818996',}
                                                                : {color: '#818996',})}/>}
                  readOnly
                />
                <div> {props.ratings 
                        ? props.ratings[props.tourId] 
                          ? !isNaN(props.ratings[props.tourId]["rating"])
                            ? props.ratings[props.tourId]["rating"]
                            : "N/A"
                          : "" 
                        : ""} 
                </div> 
              </div>
            </div>

            <div>{props.tourLanguage ? props.tourLanguage.substring(0, props.tourLanguage.indexOf('[')).trim() : 'Language: N/A'}</div>
            <div>
              {props.tourCurrency && props.tourPrice
                ? props.tourCurrency.toString() + " " + props.tourPrice.toString()
                : "Price: N/A"
              }
            </div>
            <div className="image_and_label distance_txt">
              <img src={route} />
              <div>
                Distance: {/*props.tourDistance 
                            ? props.tourDistance < 1000
                              ? <span> { Math.round(props.tourDistance).toString() + " m" } </span>
                              : <span> { Math.round(props.tourDistance/1000).toString() + ' km'.toLowerCase() } </span>
                            : 'N/A'
                          */} 

                          {
                            props.tourDistance 
                            ? <span> {formatDist(props.tourDistance)} </span>
                            : 'N/A'
                          }
              </div>
            </div>

            <div className="image_and_label">
              <img src={time}  />
              <div>
              Duration: {/*props.tourDuration 
                          ?  Math.round(props.tourDuration/60) < 60
                            ? <span> { Math.round(props.tourDuration/60).toString() + " min".toLowerCase() } </span>
                            : <span> { Math.round(props.tourDuration/3600).toString() + " hr" } </span>
                          : "N/A"
                        */} 

                        {
                          props.tourDistance 
                          ? <span> {formatDur(props.tourDuration)} </span>
                          : 'N/A'
                        }
              </div>
            </div>

            <div className="image_and_label views_count_txt">
              <img src={eye} />
              <div>
                Views: {props.tourClicked 
                          ? props.tourClicked > 1000
                            ? props.tourClicked > 1000000
                              ? (props.tourClicked/1000000).toStringAsFixed(2) + " M"
                              : (props.tourClicked/1000).toStringAsFixed(2) + " K"
                            : props.tourClicked
                          : "N/A"}
              </div>
            </div>
            <div className="image_and_label">
              <img src={money} />
              <div>
                Purchases: {props.tourPurchased 
                              ? props.tourPurchased > 1000
                                ? props.tourPurchased > 1000000
                                  ? (props.tourPurchased/1000000).toStringAsFixed(2) + " M"
                                  : (props.tourPurchased/1000).toStringAsFixed(2) + " K"
                                : props.tourPurchased
                              : "N/A"}
              </div>
            </div>
          </div>
          <div>
            <div>
              <img
                src={DeleteIcon}
                alt="Delete"
                className="card_icons"
                onClick={(e) => {
                  e.stopPropagation()
                  showDialog(
                    'warning',
                    'Are you sure that you want to delete this tour?',
                    'DELETE',
                    async () =>
                      await deleteTour(props['tourId'], () => {
                        let updatedTours = [...props.tours]
                        updatedTours = updatedTours.filter(
                          (tour) => tour['tourId'] != props['tourId'],
                        )
                        addToCache('Tours', updatedTours)
                        props.setTours(updatedTours)
                        unSetDialog()
                      }),
                  )
                }}
              />
              <img src={EditIcon} alt="Edit" className="card_icons" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
