import { useRef } from 'react'
import PlaceholderAddImage from '../../assets/imgs/placeholder_add_img.png'
import { Droppable, Draggable } from 'react-beautiful-dnd'

function StopImages({ images, onImageClickedHandler, onChange, stopId, showErrorToast}) {
  const locationImgUploadRef = useRef(null)

  const isImageFile = (file) => {
    const allowedTypes = ["image/png", "image/jpg", "image/jpeg"];

    return allowedTypes.includes(file.type);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file && isImageFile(file)) {
      onChange(stopId, {
        img: e.target.files[0],
        imgName: e.target.files[0].name,
      })
    } else {
      showErrorToast('Please select a valid image file.');
    }
  }

  return (
    <div className="stop_images">
      <div><p>IMAGES</p></div>

      <div className="add_stop_image_btn">
        <img
          src={PlaceholderAddImage}
          onClick={(e) => locationImgUploadRef.current.click()}
        />
        <input
          ref={locationImgUploadRef}
          type="file"
          onChange={(e) => handleFileChange(e)}
          onClick={(e) => {
            e.target.value = null
          }}
          accept="image/png, image/gif, image/jpeg"
        />
      </div>

      <div className="stop_images_row">
        <Droppable
          droppableId={`stop_images${stopId}`}
          type={`stop_images${stopId}`}
          direction="horizontal"
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                display: 'flex',
                width: `calc(${images.length * 3.5}em + ${
                  images.length * 0.5
                }rem + 10px)`,
                height: '4em',
              }}
            >
              {images.map((img, i) => (
                <Draggable
                  key={img.id}
                  draggableId={`stop_image_${stopId}_${img.id}`}
                  index={i}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="stop_location_image"
                      onClick={(e) => {
                        onImageClickedHandler(i)
                      }}
                      style={provided.draggableProps.style}
                    >
                      <img src={img.link} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  )
}

export default StopImages
