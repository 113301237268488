import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useAuth } from '../../Contexts/AuthContext'
import { useDialog } from '../../Contexts/DialogContext'
import {
  REGISTER,
  CREATE_USER_DATA,
  FIREBASE_API_KEY,
  DIALOG_CONSTANTS,
  COUNTRY_OPTIONS,
  LOGIN,
} from '../../constants'
import { useNavigate } from 'react-router'

import google from '../../assets/google.png'
import fb from '../../assets/fb.png'
import UserPlaceholder from '../../assets/imgs/user_placeholder.png'

import './styles/auth.css'
import Login from './Login'
import Register from './Register'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { infoToast, successToast, errorToast, warningToast } from "../Dialog/Toast";

function Auth() {
  const navigate = useNavigate()
  const {
    currentUser,
    signInWithEmail,
    signInWithRegisterEmail,
    logoutCurrentUser,
    idToken,
  } = useAuth()
  const { setDialog, unSetDialog } = useDialog()

  const [isLogin, setIsLogin] = useState(true)
  const [isRegistered, setIsRegistered] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [loginForm, setLoginForm] = useState({
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        placeholder: '',
        required: true,
      },
      value: '',
      elementLabel: 'Email',
      floatingButton: null,
    },
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        placeholder: '',
        required: true,
      },
      value: '',
      elementLabel: 'Password',
      // floatingButton: {
      //     for: "password",
      //     type: "toggle",
      // }
    },
  })

  const [registerForm, setRegisterForm] = useState({
    firstName: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'name',
        placeholder: '',
        required: true,
      },
      value: '',
      elementLabel: 'First Name',
      floatingButton: null,
    },
    lastName: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'name',
        placeholder: '',
        required: true,
      },
      value: '',
      elementLabel: 'Last Name',
      floatingButton: null,
    },
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        placeholder: '',
        required: true,
      },
      value: '',
      elementLabel: 'Email',
      floatingButton: null,
    },
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        placeholder: '',
        required: true,
      },
      value: '',
      elementLabel: 'Password',
      floatingButton: null,
    },
    confirmPassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'confirmPassword',
        placeholder: '',
        required: true,
      },
      value: '',
      elementLabel: 'Confirm Password',
      floatingButton: null,
    },
    country: {
      country: COUNTRY_OPTIONS[0],
    },
  })

  const getFormElementsArray = () => {
    let formElementsArray = []
    const iterateOver = isLogin ? loginForm : registerForm
    for (let key in iterateOver) {
      formElementsArray.push({
        id: key,
        config: iterateOver[key],
      })
    }
    return formElementsArray
  }

  const onInputChangedHandler = (event, inputIdentifier) => {
    if (isLogin) {
      const updatedLoginForm = { ...loginForm }
      const updatedFormElement = { ...updatedLoginForm[inputIdentifier] }
      updatedFormElement.value = event.target.value
      updatedLoginForm[inputIdentifier] = updatedFormElement
      setLoginForm(updatedLoginForm)
    } else {
      const updatedRegisterForm = { ...registerForm }
      const updatedFormElement = { ...updatedRegisterForm[inputIdentifier] }
      updatedFormElement.value = event.target.value
      updatedRegisterForm[inputIdentifier] = updatedFormElement
      setRegisterForm(updatedRegisterForm)
    }
  }

  const [checkBoxChecked, setCheckboxChecked] = useState(false)

  useEffect(async () => {
    if (currentUser !== undefined && currentUser !== null) {
      navigate('/')
    }
  }, [currentUser])

  useEffect(() => {
    setCheckboxChecked(false)
  }, [isLogin])

  useEffect(() => {
    if (isRegistered && currentUser) {
      let data = {
        uid: currentUser.uid,
        apikey: FIREBASE_API_KEY,
        userType: 'guide',
        userData: {
          firstName: registerForm['firstName']['value'],
          lastName: registerForm['lastName']['value'],
          email: registerForm['email']['value'],
          location: registerForm['country']['country']['value'],
          occupation: '',
          tourismExperience: '',
          specialty: '',
          biography: '',
          balance: 0.0,
          tourismExperience: '',
          tours: [],
        },
        profilePicture: UserPlaceholder.split(', ')[1],
      }

      axios
        .post(CREATE_USER_DATA, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${idToken}`,
          },
        })
        .then((res) => {
          setIsProcessing(false)
          if (res.status == 200) {
            if (res.data['status'] != 'error') {
              unSetDialog()
              navigate('/profile/edit')
            } else {
              unSetDialog()
              errorToast("Failed to create user");
              unSetDialog()
            }
          } else {
            unSetDialog()
            errorToast("Failed to create user");
            unSetDialog()
          }
        })
        .catch((e) => {
          unSetDialog()
          setIsProcessing(false)
          console.log(e)
          errorToast("Failed to create user");
          unSetDialog()
        })
    }
  }, [isRegistered, currentUser])

  const showLoginErrorDialog = (errorMessage) => {
    let dialog = DIALOG_CONSTANTS().INVALID_EMAIL_PASSWORD_DIALOG

    dialog['btnAction'] = () => unSetDialog()
    if (errorMessage != undefined) {
      dialog['message'] = errorMessage
    }

    setDialog(dialog)
  }

  const showRegisterErrorDialog = (message) => {
    let dialog = DIALOG_CONSTANTS().NEW_USER_CREATION_FAILED_DIALOG

    dialog['btnAction'] = () => unSetDialog()

    if (message) {
      dialog['message'] = message
    }

    setDialog(dialog)
  }

  const showWarningDialog = (message) => {
    let dialog = {
      type: 'warning',
      message: message,
      btnTxt: 'OK',
      btnAction: () => unSetDialog(),
    }

    setDialog(dialog)
  }

  const onFormSubmitHandler = (e) => {
    e.preventDefault()

    if (isLogin) {
      const loginEmail = loginForm['email']['value']
      const loginPassword = loginForm['password']['value']

      if (loginEmail != '' && loginPassword != '') {
        showLoaderDialog()
        setIsProcessing(true)
        axios
          .post(
            LOGIN,
            { email: loginEmail, password: loginPassword },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
          )
          .then((res) => {
            if (res.status == 200) {
              if (res.data.status == 'error') {
                if (res.data.message == 'EMAIL_NOT_FOUND') {
                  errorToast("No such email address found. Please confirm that the email is correct.")
                  unSetDialog()
                  /*showLoginErrorDialog(
                    'Error: No such email address found. Please check if the email is correct.',
                  )*/
                } else if (res.data.message == 'INVALID_PASSWORD') {
                  errorToast("Incorrect email or password entered. Please try again.")
                  unSetDialog()
                  /*showLoginErrorDialog(
                    'Error: The Password does not match the email. Please check if you have entered the correct password',
                  )*/
                }
                setIsProcessing(false)
              } else {
                signInWithEmail(
                  res.data.token,
                  () => {
                    unSetDialog()
                    setIsProcessing(false)
                    navigate('/')
                  },
                  () => {
                    unSetDialog()
                    setIsProcessing(false)
                  },
                )
              }
            }
          })
          .catch((e) => {
            unSetDialog()
            setIsProcessing(false)
            errorToast("Invalid email address or password");
            unSetDialog()
          })
      }
    } else {
      const registerEmail = registerForm['email']['value']
      const registerPassword = registerForm['password']['value']
      const registerConfirmPassword = registerForm['confirmPassword']['value']

      if (checkBoxChecked) {
        if (
          registerEmail != '' &&
          registerPassword != '' &&
          registerPassword == registerConfirmPassword &&
          registerPassword.length > 5
        ) {
          const data = new FormData()
          data.append('email', registerEmail)
          data.append('password', registerPassword)

          showLoaderDialog()
          setIsProcessing(true)
          axios
            .post(
              REGISTER,
              { email: registerEmail, password: registerPassword },
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              },
            )
            .then((res) => {
              if (res.status == 200) {
                if (res.data['status'] != 'error') {
                  signInWithRegisterEmail(
                    registerEmail,
                    registerPassword,
                    () => {
                      setIsRegistered(true)
                    },
                  )
                } else {
                  unSetDialog()
                  setIsProcessing(false)

                  if (res.data['message'] == 'EMAIL_EXISTS') {
                    //showRegisterErrorDialog('Email already exists')
                    errorToast("Email already exists.")
                    unSetDialog()
                  } else {
                    errorToast("Failed to create user");
                    unSetDialog()
                  }
                }
              } else {
                unSetDialog()
                setIsProcessing(false)
                errorToast("Failed to create user");
                unSetDialog()
              }
            })
            .catch((e) => {
              unSetDialog()
              setIsProcessing(false)
              errorToast("Failed to create user");
              unSetDialog()
            })
        }
        if (registerPassword.length < 6) {
          errorToast("Invalid Password! Password must be at least 6 characters long.")
          unSetDialog()
          /*showWarningDialog(
            'Invalid Password! Password must be at least 6 characters long.',
          )*/
        } else if (registerPassword != registerConfirmPassword) {
          errorToast("Password Mismatch! Please ensure that the passwords match.")
          unSetDialog()
          /*showWarningDialog(
            'Password Mismatch! Please ensure that the passwords match.',
          )*/
        }
      } else {
        errorToast("You must agree to the terms and conditions")
        unSetDialog()
        //showWarningDialog('You must agree to the terms and conditions')
      }
    }
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: '#5B5B5B',
      padding: '10px 20px',
      backgroundColor: state.isSelected ? '#9f9f9f44' : 'none',
      cursor: state.isSelected ? 'auto' : 'pointer',
      whiteSpace: 'nowrap',
      fontSize: '0.9em',
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: '#e9edf0',
      boxShadow:
        '-6px -6px 15px rgba(255, 255, 255, 0.78), 6px 6px 16px rgba(0, 0, 0, 0.15)',
      border: 'none',
      padding: '0 10px',
      borderRadius: '8px',
      height: '52px',
    }),
    menu: (style) => ({
      ...style,
      backgroundColor: '#e9edf0',
      borderRadius: '10px',
      padding: '10px',
    }),
    indicatorSeparator: () => null,
    dropdownIndicator: (style) => ({
      ...style,
      color: '#5B5B5B',
    }),
    singleValue: (style) => ({
      ...style,
      fontSize: '0.8em',
      color: '#5B5B5B',
      fontWeight: '500',
    }),
    valueContainer: (style) => ({
      ...style,
      color: 'red',
    }),
    container: (style) => ({
      ...style,
      width: '30em',
      marginTop: '25px',
    }),
  }

  const onDropdownChangedHandler = (newValue) => {
    const updatedRegisterForm = { ...registerForm }
    const updatedElement = { ...updatedRegisterForm['country'] }
    updatedElement.country = newValue
    updatedRegisterForm['country'] = updatedElement

    setRegisterForm(updatedRegisterForm)
  }

  const showLoaderDialog = () => {
    let dialog = {
      isLoader: true,
    }

    setDialog(dialog)
  }

  return (
    <div id="authScreen" style={{ pointerEvents: isProcessing ? 'none' : '' }}>
      <div
        className={isLogin ? 'loginOverFlow' : 'signUpOverFlow'}
        style={{ width: '100%', height: '100%' }}
      >
        <div id="loginRegisterTabs">
          <div
            className={isLogin ? '' : 'active_tab'}
            onClick={() => {
              if (isLogin) setIsLogin(false)
            }}
          >
            Sign Up
          </div>
          <span></span>
          <div
            className={isLogin ? 'active_tab' : ''}
            onClick={() => {
              if (!isLogin) setIsLogin(true)
            }}
          >
            Login
          </div>
        </div>
        <div
          style={{
            height: isLogin ? 'calc(100% - 80px)' : '100%',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          {isLogin ? (
            <Login
              onInputChangedHandler={onInputChangedHandler}
              onDropdownChangedHandler={onDropdownChangedHandler}
              registerForm={registerForm}
              getFormElementsArray={getFormElementsArray}
              customStyles={customStyles}
              onFormSubmitHandler={onFormSubmitHandler}
            />
          ) : (
            <Register
              isLogin={isLogin}
              onFormSubmitHandler={onFormSubmitHandler}
              checkBoxChecked={checkBoxChecked}
              setCheckboxChecked={setCheckboxChecked}
              onInputChangedHandler={onInputChangedHandler}
              onDropdownChangedHandler={onDropdownChangedHandler}
              registerForm={registerForm}
              getFormElementsArray={getFormElementsArray}
              customStyles={customStyles}
            />
          )}
        </div>
      </div>
      {/* <div className="Or">
        <span>Or</span>
      </div>
      <div className="auth_footer">
        <div>
          <button>
            <img src={fb}></img>
          </button>
        </div>
        <div>
          <button>
            <img src={google}></img>
          </button>
        </div>
      </div> */}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  )
}

export default Auth
