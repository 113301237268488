import React, { useState, useEffect } from 'react'
import { getDates } from "../../Utils/utils";

function BarGraph({ earnings, earningRange, clicks, clickRange, startTimestamp, endTimestamp, granularity, setGranularity, isLoading}) {
  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  function getDatesBetween(startTs, endTs) {
    const dates = [];
    const startDate = new Date(startTs);
    const endDate = new Date(endTs);

    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const date = currentDate.toISOString().split('T')[0]
      dates.push(date);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    /*for (let timestamp = startTs; timestamp <= endTs; timestamp += 86400000) {
      const date = new Date(timestamp); // Convert UNIX timestamp to milliseconds
      //dates.push(date.toLocaleString(undefined, options)); 
      dates.push(date.toISOString().split('T')[0])
    }*/
    console.log("Days between "+startTs+" & "+endTs+ " : "+dates)

    return dates;
  }

  function getMonthsBetween(startTs, endTs) {
    const months = [];
    const startDate = new Date(startTs);
    const endDate = new Date(endTs);

    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const monthYear = currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'numeric' });
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 to match the 1-12 month range
      const year = currentDate.getFullYear();
      months.push(`${month} ${year}`);
      currentDate.setDate(1);
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return months;
  }

  function getTimeMarkers(granularity, startTs, endTs){
    console.log("X between: ")
    if(granularity == "month"){
      const monthsBetween = getMonthsBetween(startTs, endTs)
      console.log("Months between "+startTs+" & "+endTs+ " : "+monthsBetween)
      return monthsBetween
    }
    else{
      const daysBetween = getDatesBetween(startTs, endTs)
      //console.log("Days between "+startTs+" & "+endTs+ " : "+daysBetween)
      return daysBetween
    }
  }

  useEffect(()=>{
    const barsDiv = document.querySelector('.bars');
    const xAxisDiv = document.querySelector('.x_axis');

    /*if(xAxisDiv){
        // Get the scroll width of the bottom div
      const scrollWidth = xAxisDiv.scrollWidth;

      // Set the width of the top div to match the scroll width of the bottom div
      barsDiv.style.width = `${scrollWidth}px`;
    }*/
  },[earnings, startTimestamp, endTimestamp])

  return (
    <div className="bar_graph_container">
      <div className="GraphH">
        <span>Overview</span>
        {/*<div>{JSON.stringify(earnings)} ::: {earningRange[0]}</div>*/}
        <div > Granularity:
          <div className={granularity == "day" ? "selectedGranularity" : ""}
                onClick={() => setGranularity("day")}>
                Day
          </div>
          <div className={granularity == "month" ? "selectedGranularity" : ""}
                onClick={() => setGranularity("month")}>
                Month
          </div>
       </div>
      </div>
      {!isEmpty(earnings)
      && <div className="overview_section">
          <p> Revenue </p>
          <div className="bar_graph">
            <div className="y_axis">
              {earningRange.map((rangeVal) => (
                <div>${rangeVal}</div>
              ))}
            </div>
            <div className="scrollable_graph">
              {/*<div className="bars">
                {Object.entries(earnings).map(([date, earning]) => (
                  <div>
                    <div style={{ height: `${Math.floor((earning/earningRange[earningRange.length - 1]) * 100)}%` }}></div>
                  </div>
                ))}
              </div>*/}
              <div className="bars">
                {getTimeMarkers(granularity, startTimestamp, endTimestamp).map((dateStr) => (
                  <div>
                    { earnings[(granularity === "day" ? dateStr : dateStr.split(" ").reverse().join("-"))] 
                      ? <div style={{ height: `${Math.floor((parseFloat(earnings[(granularity === "day" ? dateStr : dateStr.split(" ").reverse().join("-"))])/parseFloat(earningRange[0])) * 100)}%` }}></div>
                      : <div style={{ height: `${0}%` }}></div>
                    }
                  </div>
                ))}
              </div>
              <div className="x_axis">
                {getTimeMarkers(granularity, startTimestamp, endTimestamp).map((dateStr) => (
                  <div>{granularity == "day" 
                          ? dateStr.split('-').map((e, i) => (i === 0 ? e.slice(2) : e)).reverse().join('/')
                          : dateStr.replace(' ', '/')}
                  </div>
                ))}
              </div>
            </div>
          </div>
      </div>}
      {isEmpty(earnings) && <div className="no_data_message"> 
          {isLoading 
          ? <div className="lds-dual-ring-3"> </div>
          : <p>No revenue data for selected period</p> }
        </div>}

      {!isEmpty(clicks)
      && <div className="overview_section">
          <p> Clicks </p>
          <div className="bar_graph">
            <div className="y_axis">
              {clickRange.map((rangeVal) => (
                <div>{rangeVal}</div>
              ))}
            </div>
            <div className="scrollable_graph">
              {/*<div className="bars">
                {Object.entries(earnings).map(([date, earning]) => (
                  <div>
                    <div style={{ height: `${Math.floor((earning/earningRange[earningRange.length - 1]) * 100)}%` }}></div>
                  </div>
                ))}
              </div>*/}
              <div className="bars">
                {getTimeMarkers(granularity, startTimestamp, endTimestamp).map((dateStr) => (
                  <div>
                    { clicks[(granularity === "day" ? dateStr : dateStr.split(" ").reverse().join("-"))] 
                      ? <div style={{ height: `${Math.floor((parseFloat(clicks[(granularity === "day" ? dateStr.split(" ").reverse().join("-") : dateStr.split(" ").reverse().join("-"))])/parseFloat(clickRange[0])) * 100)}%` }}></div>
                      : <div style={{ height: `${0}%` }}></div>
                    }
                  </div>
                ))}
              </div>
              <div className="x_axis">
                {getTimeMarkers(granularity, startTimestamp, endTimestamp).map((dateStr) => (
                  <div>{granularity == "day" 
                          ? dateStr.split('-').map((e, i) => (i === 0 ? e.slice(2) : e)).reverse().join('/')
                          : dateStr.replace(' ', '/')}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>}
      {isEmpty(clicks) && <div className="no_data_message"> 
          {isLoading 
          ? <div className="lds-dual-ring-3"> </div>
          : <p>No clicks data for selected period</p> }
        </div>}
    </div>
  );
}

export default BarGraph;
