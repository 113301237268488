import React from "react";
import Select from "react-select";
import { COUNTRY_OPTIONS } from "../../constants";
import InputWidget from "../InputWidget/InputWidget";
import Logo from "../../assets/insidartours_logo.png"

function Login({
  onDropdownChangedHandler,
  onFormSubmitHandler,
  onInputChangedHandler,
  registerForm,
  getFormElementsArray,
  customStyles,
}) {
  return (
    <div style={{ margin: "auto 0" }}>
      <div className="logo" > <img src={Logo} /> </div>
      <div className="intro_text" > <p> Create and monetize </p> <h3> digital tours </h3> <p> in your city! </p> </div>
      <form id="forms" onSubmit={onFormSubmitHandler} autoComplete="off">
        {getFormElementsArray().map((elem) =>
          elem.id == "country" ? (
            <Select
              value={registerForm["country"]["country"]}
              onChange={onDropdownChangedHandler}
              options={COUNTRY_OPTIONS}
              styles={customStyles}
            />
          ) : (
            <InputWidget
              key={elem.id}
              elementType={elem.config.elementType}
              elementConfig={elem.config.elementConfig}
              value={elem.config.value}
              elementLabel={elem.config.elementLabel}
              floatingButton={elem.config.floatingButton}
              changed={(event) => onInputChangedHandler(event, elem.id)}
            />
          )
        )}
        <div
          className={"login_form_checkbox_and_link"}
          style={{ margin: "auto" }}
        >
          <a style={{ paddingTop: "5px" }} href="/forgot_password">
            Forgot password?
          </a>
        </div>
        <button id="loginRegisterButton" type="submit">
          Login
        </button>
      </form>
    </div>
  );
}

export default Login;
