import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDialog } from "../../Contexts/DialogContext";
import InputWidget from "../InputWidget/InputWidget";

import {
  DIALOG_CONSTANTS,
  FORGOT_PASSWORD,
  FIREBASE_API_KEY,
} from "../../constants";

import { auth } from "../../firebase";

import {
  connectAuthEmulator,
  confirmPasswordReset
} from 'firebase/auth';


import "./styles/forgotPassword.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { infoToast, successToast, errorToast, warningToast } from "../Dialog/Toast";

function ResetPassword() {
  const navigate = useNavigate();
  const { setDialog, unSetDialog } = useDialog();
  const [isProcessing, setIsProcessing] = useState(false);
  const [code, setCode] = useState(null)

  useEffect(() => {
    try{
      const queryParams = new URLSearchParams(window.location.search);
      const code = queryParams.get('oobCode');

      setCode(code)

      console.log('Reset code:', code);
    } catch (error){
      navigate("/auth")
    }
    
  }, []);

  const [resetPasswordForm, setResetPasswordForm] = useState({
    newPass: {
      id: 'newPass',
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'newPass',
        placeholder: '',
        required: true,
      },
      value: '',
      elementLabel: 'New Password',
      floatingButton: null,
    },
    newPassAgain: {
      id: 'newPassAgain',
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'newPassAgain',
        placeholder: '',
        required: true,
      },
      value: '',
      elementLabel: 'Confirm New Password',
      floatingButton: null,
    },
  })

  const onInputChangedHandler = (event, inputIdentifier) => {
    const updatedResetPasswordForm = { ...resetPasswordForm };
    const updatedFormElement = {
      ...updatedResetPasswordForm[inputIdentifier],
    };
    updatedFormElement.value = event.target.value;
    updatedResetPasswordForm[inputIdentifier] = updatedFormElement;
    setResetPasswordForm(updatedResetPasswordForm);
  };

  const confirmThePasswordReset = async (oobCode, newPassword) => {
    if(!oobCode && !newPassword) return;
    
    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      successToast("Changed password successfully!")

      setTimeout(()=>{
        navigate("/auth")
      },3000)
      
    } catch (error) {
      errorToast("An error occurred while attempting password reset");
    } finally {
      //warningToast("X");
    }

  }

  const onChangePasswordHandler = async (e) => {
    e.preventDefault();

    let newPass = resetPasswordForm['newPass']['value']
    let newPassAgain = resetPasswordForm['newPassAgain']['value']

    let errorMessage = 'Please fill all the required fields'

    if (newPass.length > 5 && newPass == newPassAgain) {
      await confirmThePasswordReset(code,newPass);

      //successToast("Done executing")
      
    } else if (newPass != newPassAgain) {
      //Passwords must match
      errorToast("Passwords must match. Please try again.")
    } else {
      //password must be at least 6 characters in length.
      errorToast("Password must be at least 6 characters in length.")
    }

  }

  return (
    <div
      id="resetPasswordScreen"
      style={{ pointerEvents: isProcessing ? "none" : "" }}
    >
      <div id="resetPasswordHeader">
        <div>Reset Password</div>
      </div>
      <form
        id="resetPasswordBody"
        onSubmit={onChangePasswordHandler}
        autoComplete="off"
      >
        <div style={{ position: "relative" }}>
          <div id="back_btn" onClick={() => navigate("/auth")}>
            &lt; Back to Login
          </div>
          <InputWidget
            elementType={resetPasswordForm['newPass'].elementType}
            elementConfig={resetPasswordForm['newPass'].elementConfig}
            value={resetPasswordForm['newPass'].value}
            elementLabel={resetPasswordForm['newPass'].elementLabel}
            floatingButton={resetPasswordForm['newPass'].floatingButton}
            changed={(event) => onInputChangedHandler(event, "newPass")}
            style={{ width: '50% !important'}}
          />

          <InputWidget
            elementType={resetPasswordForm['newPassAgain'].elementType}
            elementConfig={
              resetPasswordForm['newPassAgain'].elementConfig
            }
            value={resetPasswordForm['newPassAgain'].value}
            elementLabel={resetPasswordForm['newPassAgain'].elementLabel}
            floatingButton={
              resetPasswordForm['newPassAgain'].floatingButton
            }
            changed={(event) => onInputChangedHandler(event, "newPassAgain")}
            style={{ width: '50% !important'}}
          />
        </div>
        <button id="resetPasswordButton" type="submit">
          Reset Password
        </button>
      </form>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default ResetPassword;
