import React, { useState, useEffect, useRef } from 'react'
import { useDialog } from '../../Contexts/DialogContext'
import { Draggable } from 'react-beautiful-dnd'
import './styles/StopWidget.css'
import MarkerIcon from '../../assets/imgs/marker_icon.png'
import RemoveIcon from '../../assets/imgs/remove_btn.png'
import StopAudio from './StopAudio'
import ARMarkersList from './ARMarkersList'
import StopImages from './StopImages'
import InputWidget from '../InputWidget/InputWidget'

function StopWidget(props) {
  const stopAudioUploadRef = useRef(null)
  const { setDialog } = useDialog()

  useEffect(() => {
    if (props['audioURL'] || props.audio) {
      stopAudioUploadRef.current.value = ''
    }
  }, [props])

  const showLoaderDialog = () => {
    let dialog = {
      isLoader: true,
    }

    setDialog(dialog)
  }

  const isAudioFile = (file) => {
    const allowedTypes = ["audio/mp3", "audio/wav", "audio/m4a", "audio/mpeg"];

    return allowedTypes.includes(file.type);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file && isAudioFile(file)) {
      props.onChange(props.id, { audio: e.target.files[0] })
    } else {
      props.showErrorToast('Please select a valid audio file.');
    }
  }

  return (
    <div
      className={
        props.isSelected
          ? 'field_with_marker selected_stop'
          : 'field_with_marker'
      }
    >
      <div
        className="field_marker"
        style={{ top: '50%', transform: 'translate(-50%, -50%)' }}
      >
        <img src={MarkerIcon} />
      </div>
      <Draggable key={props.id} draggableId={props.id} index={props.index}>
        {(provided) => (
          <div
            isdraggingover={props.isdraggingover}
            style={{
              width: '85%',
              marginLeft: '10%',
              marginRight: '5%',
              border: props.chkEmpty && props.name === '' && '1px solid red',
              borderRadius: props.isdraggingover ? '16px' : '16px',
              background: props.isdraggingover ? '#c1c7cb77' : '',
            }}
            onClick={(e) => {
              e.stopPropagation()
              props.onClick()
            }}
          >
            <div
              className={
                props.isSelected
                  ? 'stop_object stop_object_active'
                  : 'stop_object'
              }
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div>
                <div
                  className="stopNumber"
                  style={{
                    background: props.isSelected
                      ? 'linear-gradient(to bottom right, #0378A6, #01A0DB)'
                      : 'linear-gradient(to bottom right, #DADADA, #FEFDFD)',
                  }}
                >
                  <span>{props.index + 1}</span>
                </div>
                <div className="stop_details">
                  <input
                    type="text"
                    placeholder="Type Location Name..."
                    value={props.name}
                    style={
                      props.isSelected
                        ? {
                            boxShadow:
                              'inset 6px 6px 16px rgba(0, 0, 0, 0.15), inset -6px -6px 15px rgba(255, 255, 255, 0.78)',
                          }
                        : {}
                    }
                    onChange={(e) =>
                      props.onChange(props.id, { name: e.target.value })
                    }
                  />
                </div>
                <div className="stop_header">
                  {/*<div
                    style={{
                      opacity: 'location' in props ? 0 : 1,
                    }}
                  >
                    Click anywhere on the map to set location
                  </div>*/}
                  <img
                    src={RemoveIcon}
                    alt="Remove Icon"
                    onClick={(e) => props.onRemove(e)}
                  />
                </div>
              </div>
              <div className="stop_audio">
                <div><p>AUDIO</p></div>
                <StopAudio
                  stopAudio={
                    props['audioURL']
                      ? {
                          audioURL: props['audioURL'],
                          audioName: props['audioName'],
                        }
                      : props.audio
                        ? {
                            audio: props.audio,
                            audioName: props['audioName'],
                          }
                        : {}
                  }
                  currentStop={props.id}
                  onStopAudioChange={props.onChange}
                  onRemoveAudio={props.onRemoveAudio}
                  onUploadClickedHandler={() =>
                    stopAudioUploadRef.current.click()
                  }
                  showLoaderDialog={showLoaderDialog}
                />
                <input
                  ref={stopAudioUploadRef}
                  type="file"
                  onChange={(e) => handleFileChange(e)}
                  style={{ display: 'none' }}
                  accept="audio/*"
                />
              </div>
              <StopImages
                images={'imgs' in props.stops[props.stop.id] ? props.stops[props.stop.id].imgs : []}
                onImageClickedHandler={(id) => props.selectImage(id)}
                onChange={props.onChange}
                stopId={props.id}
                showErrorToast={props.showErrorToast}
              />
              <ARMarkersList
                arMarkers={props['arMarker']}
                currentStop={props.id}
                selectedStop={props.selectedStop}
                selectedARMarker={props.selectedARMarker}
                selectedARMarkerType={props.selectedARMarkerType}
                onARMarkerClickedHandler={(id, type) => {
                  props.setSelectedARMarker(id)
                  props.setSelectedARMarkerType(type)
                }}
                openEditDialog={() => {
                  props.setIsARMarkerPictureDialogVisible(true)
                }}
              />
            </div>
          </div>
        )}
      </Draggable>
    </div>
  )
}

export default StopWidget
