import React, { useState, useEffect } from "react";

export function useCache() {
  const addToCache = (key, data) => {
    sessionStorage.setItem(key, JSON.stringify(data));
  };

  const getCache = (key) => {
    let tours = sessionStorage.getItem(key);
    if (tours != "undefined") {
      tours = JSON.parse(tours);
    }
    return tours;
  };
  const deleteCache = (key) => {
    sessionStorage.removeItem(key);
  };
  const deleteAll = () => {
    sessionStorage.clear();
  };
  return {
    getCache,
    addToCache,
    deleteCache,
    deleteAll,
  };
}
