import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDialog } from "../../Contexts/DialogContext";
import InputWidget from "../InputWidget/InputWidget";

import {
  DIALOG_CONSTANTS,
  FORGOT_PASSWORD,
  FIREBASE_API_KEY,
} from "../../constants";

import "./styles/forgotPassword.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { infoToast, successToast, errorToast, warningToast } from "../Dialog/Toast";

function ForgotPassword() {
  const navigate = useNavigate();
  const { setDialog, unSetDialog } = useDialog();
  const [isProcessing, setIsProcessing] = useState(false);
  const [forgotPasswordForm, setForgotPasswordForm] = useState({
    email: {
      elementType: "input",
      elementConfig: {
        type: "email",
        name: "email",
        placeholder: "",
        required: true,
      },
      value: "",
      elementLabel: "Email",
      floatingButton: null,
    },
  });

  const onInputChangedHandler = (event, inputIdentifier) => {
    const updatedForgotPasswordForm = { ...forgotPasswordForm };
    const updatedFormElement = {
      ...updatedForgotPasswordForm[inputIdentifier],
    };
    updatedFormElement.value = event.target.value;
    updatedForgotPasswordForm[inputIdentifier] = updatedFormElement;
    setForgotPasswordForm(updatedForgotPasswordForm);
  };

  const showSuccessDialog = (userEmail) => {
    let dialog = DIALOG_CONSTANTS().RESET_EMAIL_SENT_DIALOG;

    dialog["btnAction"] = () => {
      unSetDialog();
      navigate("/auth");
    };
    dialog[
      "message"
    ] = `Password reset instructions have been sent to ${userEmail}. Do check the spam folder if you can not find the email.`;

    setDialog(dialog);
  };

  const showErrorDialog = () => {
    let dialog = DIALOG_CONSTANTS().NO_EMAIL_ADDRESS_DIALOG;

    dialog["btnAction"] = () => unSetDialog();

    setDialog(dialog);
  };

  const onFormSubmitHandler = (e) => {
    e.preventDefault();

    showLoaderDialog();

    const forgotPasswordEmail = forgotPasswordForm["email"]["value"];

    if (forgotPasswordEmail != "") {
      setIsProcessing(true);
      axios
        .post(
          FORGOT_PASSWORD,
          { email: forgotPasswordEmail },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setIsProcessing(false);
          if (res.status == 200) {
            if (res.data["status"] != "error") {
              showSuccessDialog(forgotPasswordEmail);
            } else {
              //showErrorDialog();
              errorToast("There is no account linked to this email address")
              unSetDialog()
            }
          } else {
            //showErrorDialog();
            errorToast("There is no account linked to this email address")
            unSetDialog()
          }
        })
        .catch((e) => {
          setIsProcessing(false);
          console.log(e);
          //showErrorDialog();
          errorToast("There is no account linked to this email address")
          unSetDialog()
        });
    }
  };

  const showLoaderDialog = () => {
    let dialog = {
      isLoader: true,
    }

    setDialog(dialog)
  }


  return (
    <div
      id="forgotPasswordScreen"
      style={{ pointerEvents: isProcessing ? "none" : "" }}
    >
      <div id="forgotPasswordHeader">
        <div>Forgot Password</div>
      </div>
      <form
        id="forgotPasswordBody"
        onSubmit={onFormSubmitHandler}
        autoComplete="off"
      >
        <div style={{ position: "relative" }}>
          <div id="back_btn" onClick={() => navigate("/auth")}>
            &lt; Back to Login
          </div>
          <InputWidget
            elementType={forgotPasswordForm["email"].elementType}
            elementConfig={forgotPasswordForm["email"].elementConfig}
            value={forgotPasswordForm["email"].value}
            elementLabel={forgotPasswordForm["email"].elementLabel}
            floatingButton={forgotPasswordForm["email"].floatingButton}
            changed={(event) => onInputChangedHandler(event, "email")}
          />
        </div>
        <button id="sendResetRequestButton" type="submit">
          Send Reset Request
        </button>
      </form>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default ForgotPassword;
