import React from 'react'
import InputWidget from '../../InputWidget/InputWidget'


function ChangePassword({
  onChangePasswordHandler,
  settingsForms,
  selectedTab,
  onInputChangedHandler,
}) {
  return (
    <div>
      <div className="settingBtn">
        <div className="ChangeP">
          <span>Change Password</span>
        </div>
        <div className="setBtns">
          <button>CANCEL</button>
          <button onClick={onChangePasswordHandler}>UPDATE</button>
        </div>
      </div>
      <div className="settingValues" >
        <InputWidget
          elementType={settingsForms[selectedTab]['currentPass'].elementType}
          elementConfig={
            settingsForms[selectedTab]['currentPass'].elementConfig
          }
          value={settingsForms[selectedTab]['currentPass'].value}
          elementLabel={settingsForms[selectedTab]['currentPass'].elementLabel}
          floatingButton={
            settingsForms[selectedTab]['currentPass'].floatingButton
          }
          changed={(event) =>
            onInputChangedHandler(
              event,
              settingsForms[selectedTab]['currentPass'].id,
            )
          }
          style={{ width: '50% !important'}}
        />

        <InputWidget
          elementType={settingsForms[selectedTab]['newPass'].elementType}
          elementConfig={settingsForms[selectedTab]['newPass'].elementConfig}
          value={settingsForms[selectedTab]['newPass'].value}
          elementLabel={settingsForms[selectedTab]['newPass'].elementLabel}
          floatingButton={settingsForms[selectedTab]['newPass'].floatingButton}
          changed={(event) =>
            onInputChangedHandler(
              event,
              settingsForms[selectedTab]['newPass'].id,
            )
          }
          style={{ width: '50% !important'}}
        />

        <InputWidget
          elementType={settingsForms[selectedTab]['newPassAgain'].elementType}
          elementConfig={
            settingsForms[selectedTab]['newPassAgain'].elementConfig
          }
          value={settingsForms[selectedTab]['newPassAgain'].value}
          elementLabel={settingsForms[selectedTab]['newPassAgain'].elementLabel}
          floatingButton={
            settingsForms[selectedTab]['newPassAgain'].floatingButton
          }
          changed={(event) =>
            onInputChangedHandler(
              event,
              settingsForms[selectedTab]['newPassAgain'].id,
            )
          }
          style={{ width: '50% !important'}}
        />
      </div>
    </div>
  )
}

export default ChangePassword
