import React, { useState, useEffect, useRef } from 'react'

import './styles/InputWidget.css'

function InputWidget(props) {
  const [inputElement, setInputElement] = useState(null)

  const inputContainerRef = useRef(null)
  const inputRef = useRef(null)

  useEffect(() => {
    switch (props.elementType) {
      case 'input':
        setInputElement(
          <div
            className="input_tab"
            style={{
              border: props.chkEmpty && props.value === '' && '1px solid red',
            }}
          >
            <span>{'prefix' in props ? props.prefix : ''}</span>
            <input
              ref={inputRef}
              {...props.elementConfig}
              value={props.value}
              onChange={props.changed}
              disabled={'isDisabled' in props ? props.isDisabled : false}
              autoComplete="off"
              style={{ paddingLeft: 'prefix' in props ? '55px' : '' }}
            />
            <div
              className="input_fab"
              style={props.floatingButton == null ? { display: 'none' } : {}}
            >
              <img
                src={
                  props.floatingButton != null
                    ? props.floatingButton['buttonImage']
                    : ''
                }
                alt="FAB Button"
                onClick={() => props.floatingButtonAction()}
              />
            </div>
          </div>,
        )
        break
      case 'checkbox':
        setInputElement(
          <label ref={inputRef} className="checkbox_container">
            {props.elementLabel}
            <input
              {...props.elementConfig}
              checked={props.checked}
              onChange={props.changed}
              autoComplete="off"
            />
            <span className="checkmark"></span>
          </label>,
        )
        break
      case 'corsInput':
        setInputElement(
          <div className="input_tab cors">
            <span>{'prefix' in props ? props.prefix : ''}</span>
            <input
              ref={inputRef}
              {...props.elementConfig}
              value={props.value}
              onChange={props.changed}
              type={'number'}
              min={'0'}
              onInput={(e) => {
                e.target.validity.valid || (e.target.value = '')
              }}
              autoComplete="off"
              style={{
                paddingLeft: 'prefix' in props ? '75px' : '',
                outline: 'none',
                width: '100%',
              }}
            />
            <div
              className="input_fab"
              style={props.floatingButton == null ? { display: 'none' } : {}}
            >
              <img
                src={
                  props.floatingButton != null
                    ? props.floatingButton['buttonImage']
                    : ''
                }
                alt="FAB Button"
                onClick={() => props.floatingButtonAction()}
              />
            </div>
          </div>,
        )
        break
      case 'textarea':
        setInputElement(
          <textarea
            ref={inputRef}
            {...props.elementConfig}
            value={props.value}
            style={{
              border: props.chkEmpty && props.value === '' && '1px solid red',
            }}
            onChange={props.changed}
            disabled={'isDisabled' in props}
            autoComplete="off"
          />,
        )
        break
      case 'button':
        setInputElement(
          <div
            className="input_button"
            style={{
              border: props.chkEmpty && props.value === '' && '1px solid red',
            }}
          >
            {/*<span>{'prefix' in props ? props.prefix : ''}</span>*/}
            <div onClick={props.onClick}>
              {props.buttonComponent}
              {/*<img
                src={
                  props.buttonImage
                }
                alt="Add Stop Button"
                
              />*/}
              <span> ADD STOP </span>
              
            </div>
          </div>,
        )
        break
      default:
        setInputElement(
          <input
            ref={inputRef}
            {...props.elementConfig}
            value={props.value}
            onChange={props.changed}
            autoComplete="off"
          />,
        )
        break
    }
  }, [props])

  return (
    <div
      ref={inputContainerRef}
      className={
        'isDisabled' in props && props.isDisabled
          ? 'input_container not_editable'
          : 'input_container'
      }
    >
      {props.elementLabel !== undefined && props.elementType !== 'checkbox' ? (
        <label>{props.elementLabel}</label>
      ) : (
        <span></span>
      )}
      {inputElement}
    </div>
  )
}

export default InputWidget
