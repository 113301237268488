import { useEffect, useState } from 'react'

function Timer({ status }) {
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds == 59) {
        setSeconds(0)
        setMinutes(minutes + 1)
      }
      if (status === 'recording') {
        setSeconds(seconds + 1)
      } else {
        clearInterval(myInterval)
        setSeconds(0)
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  })

  return (
    <span>
      {minutes === 0 && seconds === 0 ? null : (
        <strong style={{ color: 'grey' }}>
          {' '}
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </strong>
      )}
    </span>
  )
}

export default Timer
