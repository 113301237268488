import tabimg from "../../assets/tableimg.png";
import ErrorIcon from '../../assets/imgs/error_icon.png'
import "./styles/tableData.css";

import React, {useState} from 'react'

function TableData({ index, tourDetails, subAnalytics, ratings}) {
  const [imgError, setImgError] = useState(true)

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  const getNumClicks = (data) => {
    let totalClicks = 0
    for (const date in data) {
      if (data.hasOwnProperty(date)) {
          totalClicks += parseFloat(data[date].tourClicked) || 0;
      }
    }

    return totalClicks
  }

  const getNumPurchases = (data) => {
    let totalPurchases = 0
    for (const date in data) {
      if (data.hasOwnProperty(date)) {
        totalPurchases += data[date].tourPurchased || 0;
      }
    }

    return totalPurchases
  }

  const getAmountEarned = (data) => {
    let moneyEarned = 0
    for (const date in data) {
      if (data.hasOwnProperty(date)) {
        moneyEarned += data[date].moneyEarnedInUSD || 0;
      }
    }

    return moneyEarned.toFixed(1);
  }

  const getAverageUnitPrice = (data) => {
    const amountEarned = getAmountEarned(data);
    const numPurchases = getNumPurchases(data);

    if(numPurchases == 0){
      return tourDetails["tourPrice"];
    }

    const avgPrice = amountEarned/numPurchases;

    return "$"+avgPrice.toFixed(1);
  }

  return (
    <div id="TableData">
      <div>
        <span>{index + 1}</span>
      </div>
      <div>
        <img src={tourDetails["coverImage"]} alt="" />
      </div>
      <div>
      </div>
      <div className="pullLeft">
        <span>{tourDetails["name"]}</span>
      </div>
      <div>
        <span>{getNumClicks(subAnalytics)}</span>
      </div>
      <div>
        <span>{getAverageUnitPrice(subAnalytics)}</span>
      </div>
      <div>
        <span>{getNumPurchases(subAnalytics)}</span>
      </div>
      <div>
        {/*<span>{(getNumPurchases(subAnalytics)*parseFloat(tourDetails["tourPrice"].replace("$", ""))).toFixed(1)}</span>*/}
        <span>{getAmountEarned(subAnalytics)}</span>
      </div>
      <div>
        {isEmpty(ratings)
          ? <span> ... </span>
          : <span> {ratings[tourDetails["tourId"]]["rating"]}  ({ratings[tourDetails["tourId"]]["count"]} reviews)</span>
        }
      </div>
    </div>
  );
}

export default TableData;
