import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useAuth } from '../../Contexts/AuthContext'
import { useProfile } from '../../Contexts/ProfileContext'
import {
  FIREBASE_API_KEY,
  CHANGE_PASSWORD,
  CREATE_USER_PREF,
  UPDATE_USER_PREF,
  LANGUAGE_OPTIONS,
  COUNTRY_OPTIONS,
  CURRENCY_OPTIONS,
  UPDATE_BANK_DETAILS,
} from '../../constants'
import { useDialog } from '../../Contexts/DialogContext'
import { useNavigate } from 'react-router-dom'

import Select from 'react-select'
import InputWidget from '../InputWidget/InputWidget'

import './styles/setting.css'
import ChangeCurrency from './SettingTabs/ChangeCurrency'
import ChangeLanguage from './SettingTabs/ChangeLanguage'
import ChangePassword from './SettingTabs/ChangePassword'
import ChangeBankDetails from './SettingTabs/ChangeBankDetails'
import { async } from '@firebase/util'
import { useCache } from '../../Hooks/useCache'
import { CODE_LENGTHS, isValidIBANNumber } from '../../Utils/utils'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { infoToast, successToast, errorToast, warningToast } from "../Dialog/Toast";

const paymentMethodOptions = [
  { value: 'card', label: 'Credit/Debit Card' },
  { value: 'paypal', label: 'PayPal' },
  { value: 'applePay', label: 'Apple Pay' },
  { value: 'googlePay', label: 'Google Pay' },
]

const cleanLanguageOptions = (languageOptions) => {
  const cleanedOptions = languageOptions.map(option => ({
    value: option.value,
    label: option.label.split('[')[0].trim()
  }));

  return cleanedOptions;
}

function Settings() {
  const navigate = useNavigate()
  const { currentUser, idToken, logoutCurrentUser } = useAuth()
  const {
    userPrefData,
    reloadPreferences,
    bankDetails,
    reloadBankingDetails,
  } = useProfile()
  const { setDialog, unSetDialog } = useDialog()

  const [isNewPref, setIsNewPref] = useState(false)
  const [chkEmpty, setChkEmpty] = useState(false)

  const [settingsForms, setSettingsForms] = useState({
    changePassword: {
      currentPass: {
        id: 'currentPass',
        elementType: 'input',
        elementConfig: {
          type: 'password',
          name: 'currentPass',
          placeholder: '',
          required: true,
        },
        value: '',
        elementLabel: 'CURRENT PASSWORD',
        floatingButton: null,
      },
      newPass: {
        id: 'newPass',
        elementType: 'input',
        elementConfig: {
          type: 'password',
          name: 'newPass',
          placeholder: '',
          required: true,
        },
        value: '',
        elementLabel: 'NEW PASSWORD',
        floatingButton: null,
      },
      newPassAgain: {
        id: 'newPassAgain',
        elementType: 'input',
        elementConfig: {
          type: 'password',
          name: 'newPassAgain',
          placeholder: '',
          required: true,
        },
        value: '',
        elementLabel: 'CONFIRM NEW PASSWORD',
        floatingButton: null,
      },
    },
    language: {
      lang: cleanLanguageOptions(LANGUAGE_OPTIONS)[0],
    },
    units_currency: {
      unit: 'metric',
      currency: CURRENCY_OPTIONS[0],
    },
    payment_method: {
      method: paymentMethodOptions[0],
    },
    bankDetails: {
      bankName: {
        id: 'bankName',
        elementType: 'input',
        elementConfig: {
          type: 'text',
          name: 'bankName',
          placeholder: '',
          required: true,
        },
        value: '',
        elementLabel: 'BANK NAME *',
        floatingButton: null,
      },
      swiftCode: {
        id: 'swiftCode',
        elementType: 'input',
        elementConfig: {
          type: 'text',
          name: 'swiftCode',
          placeholder: '',
          required: true,
        },
        value: '',
        elementLabel: 'SWIFT CODE *',
        floatingButton: null,
      },
      iban: {
        id: 'iban',
        elementType: 'input',
        elementConfig: {
          type: 'text',
          name: 'iban',
          placeholder: '',
          required: true,
        },
        value: '',
        elementLabel: 'IBAN *',
        floatingButton: null,
      },
      accountHolderName: {
        id: 'accountHolderName',
        elementType: 'input',
        elementConfig: {
          type: 'text',
          name: 'accountHolderName',
          placeholder: '',
          required: true,
        },
        value: '',
        elementLabel: 'ACCOUNT HOLDER NAME *',
        floatingButton: null,
      },
      address: {
        id: 'address',
        elementType: 'input',
        elementConfig: {
          type: 'text',
          name: 'address',
          placeholder: '',
          required: true,
        },
        value: '',
        elementLabel: 'ADDRESS OF HOLDER *',
        floatingButton: null,
      },
      country: COUNTRY_OPTIONS[0],
      currency: CURRENCY_OPTIONS[0],
    },
  })
  const [selectedTab, setSelectedTab] = useState('bankDetails')

  const [isProcessing, setIsProcessing] = useState(false)
  let { deleteAll } = useCache()

  function initSettings(prefData) {
    let updatedSettingsForm = { ...settingsForms }

    updatedSettingsForm['language']['lang'] = cleanLanguageOptions(LANGUAGE_OPTIONS).filter(
      (lang) => lang['value'] == prefData['language'],
    )[0]
    updatedSettingsForm['units_currency']['unit'] = prefData['measuringUnits']
    updatedSettingsForm['units_currency']['currency'] = CURRENCY_OPTIONS.filter(
      (currency) => currency['value'] == prefData['currency'],
    )[0]
    updatedSettingsForm['payment_method'][
      'method'
    ] = paymentMethodOptions.filter(
      (method) => method['value'] == prefData['paymentMethod'],
    )[0]

    setSettingsForms(updatedSettingsForm)
  }

  function initBankDetails(details) {
    let updatedSettingsForm = { ...settingsForms }

    let updatedBankDetails = { ...updatedSettingsForm['bankDetails'] }

    Object.keys(updatedBankDetails).forEach((key) => {
      if (key in details) {
        if (key == 'country') {
          updatedBankDetails[key] = COUNTRY_OPTIONS.filter(
            (country) => country.value == details[key],
          )[0]
        } else if (key == 'currency') {
          updatedBankDetails[key] = CURRENCY_OPTIONS.filter(
            (currency) => currency.value == details[key],
          )[0]
        } else {
          updatedBankDetails[key]['value'] = details[key]
        }
      }
    })

    updatedSettingsForm['bankDetails'] = updatedBankDetails

    setSettingsForms(updatedSettingsForm)
  }

  useEffect(() => {
    if (currentUser) {
      if (userPrefData === undefined) {
        showLoaderDialog()
      } else {
        unSetDialog()
        if (userPrefData === null) {
          setIsNewPref(true)
        } else {
          initSettings(userPrefData)
        }
      }
    }
  }, [currentUser, userPrefData])

  useEffect(() => {
    if (currentUser) {
      if (bankDetails !== null && bankDetails !== undefined) {
        initBankDetails(bankDetails)
      }
    }
  }, [bankDetails])

  const onInputChangedHandler = (event, inputIdentifier) => {
    const updatedForms = { ...settingsForms }
    const updatedForm = { ...updatedForms[selectedTab] }
    const updatedFormElement = { ...updatedForm[inputIdentifier] }
    updatedFormElement.value = event.target.value
    updatedForm[inputIdentifier] = updatedFormElement

    updatedForms[selectedTab] = updatedForm

    setSettingsForms(updatedForms)
  }

  const onDropdownChangedHandler = (newValue, keyStr) => {
    const updatedForms = { ...settingsForms }
    const updatedForm = { ...updatedForms[selectedTab] }

    if (selectedTab == 'language') {
      updatedForm.lang = newValue
    } else if (selectedTab == 'units_currency') {
      updatedForm.currency = newValue
    } else if (selectedTab == 'bankDetails') {
      updatedForm[keyStr] = newValue
    }

    updatedForms[selectedTab] = updatedForm

    setSettingsForms(updatedForms)
  }

  const onCheckboxChangedHandler = (value) => {
    const updatedForms = { ...settingsForms }
    const updatedForm = { ...updatedForms[selectedTab] }

    if (selectedTab == 'units_currency') {
      updatedForm.unit = value
      updatedForms[selectedTab] = updatedForm
    } else if (selectedTab == 'payment_method') {
      updatedForm.method = value
      updatedForms[selectedTab] = updatedForm
    }

    setSettingsForms(updatedForms)
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: '#5B5B5B',
      padding: '10px 20px',
      backgroundColor: state.isSelected ? '#9f9f9f44' : 'none',
      cursor: state.isSelected ? 'auto' : 'pointer',
      whiteSpace: 'nowrap',
      fontSize: '0.9em',
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: '#e9edf0',
      boxShadow:
        '-6px -6px 15px rgba(255, 255, 255, 0.78), 6px 6px 16px rgba(0, 0, 0, 0.15)',
      border: 'none',
      padding: '0 10px',
      borderRadius: '8px',
      height: '52px',
    }),
    menu: (style) => ({
      ...style,
      backgroundColor: '#e9edf0',
      borderRadius: '10px',
      padding: '10px',
    }),
    indicatorSeparator: () => null,
    dropdownIndicator: (style) => ({
      ...style,
      color: '#5B5B5B',
    }),
    singleValue: (style) => ({
      ...style,
      fontSize: '0.8em',
      color: '#5B5B5B',
      fontWeight: '500',
    }),
    valueContainer: (style) => ({
      ...style,
      justifyContent: 'center',
      color: 'red',
    }),
    container: (style) => ({
      ...style,
      width: selectedTab == 'units_currency' ? 'unset' : 'auto',
      flex: '1',
    }),
  }

  const createOrUpdateUserPref = async () => {
    let data = {
      uid: currentUser.uid,
      userType: 'guide',
      preferenceData: {
        language: settingsForms['language']['lang']['value'],
        measuringUnits: settingsForms['units_currency']['unit'],
        currency: settingsForms['units_currency']['currency']['value'],
        displayFeedback: true,
        allowContacting: true,
      },
    }

    showLoaderDialog()
    setIsProcessing(true)

    const apiForUserPref = isNewPref
      ? axios.post(CREATE_USER_PREF, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${idToken}`,
          },
        })
      : axios.put(UPDATE_USER_PREF, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${idToken}`,
          },
        })

    apiForUserPref
      .then((res) => {
        setIsProcessing(false)
        unSetDialog()
        if (res.status == 200) {
          reloadPreferences(currentUser.uid)
          /*showDialog('success', 'Update Successful.', () => {
            unSetDialog()
            navigate('/')
          })*/
          unSetDialog()
          successToast("Update successful.")
        } else {
          //showDialog('error', 'There was an error updating the settings.')
          errorToast("There was an error updating the settings.")
        }
      })
      .catch((e) => {
        setIsProcessing(false)
        console.log(e)
        unSetDialog()
        //showDialog('error', 'There was an error updating the settings.')
        errorToast("There was an error updating the settings.")
      })
  }

  const onChangePasswordHandler = async () => {
    let oldPass = settingsForms['changePassword']['currentPass']['value']
    let newPass = settingsForms['changePassword']['newPass']['value']
    let newPassAgain = settingsForms['changePassword']['newPassAgain']['value']

    let errorMessage = 'Please fill all the required fields'

    if (oldPass.length > 0) {
      if (oldPass == newPass) {
        errorMessage =
          'Invalid Password! Please use a password that is not the same as your old password.'
      } else if (newPass != newPassAgain && newPass.length > 0) {
        errorMessage =
          'Password Mismatch! Please ensure that the new passwords match.'
      }
    }

    if (oldPass != newPass && newPass.length > 0 && newPass == newPassAgain) {
      let data = {
        email: currentUser.email,
        oldPassword: oldPass,
        newPassword: newPass,
      }

      showLoaderDialog()
      setIsProcessing(true)
      const token = await currentUser.getIdToken()
      axios
        .post(CHANGE_PASSWORD, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${token}`,
          },
        })
        .then((res) => {
          setIsProcessing(false)
          unSetDialog()
          if (res.status == 200) {
            if ('success' in res.data) {
              /*showDialog(
                'success',
                'Password changed successfully.',
                async () => {
                  unSetDialog()
                  deleteAll()
                  await logoutCurrentUser()
                  navigate('/auth')
                },
              )*/
              successToast("Password changed successfully.")
            } else {
              //showDialog('error', 'Incorrect Old Password entered.')
              errorToast("Incorrect Old Password entered.")
              unSetDialog()
            }
          } else {
            //showDialog('error', 'Incorrect Old Password entered.')
            errorToast("Incorrect Old Password entered.")
            unSetDialog()
          }
        })
        .catch((e) => {
          setIsProcessing(false)
          unSetDialog()
          //showDialog('error', 'Incorrect Old Password entered.')
          errorToast("Incorrect Old Password entered.")
        })
    } else {
      //showDialog('error', errorMessage)
      errorToast(errorMessage)
      unSetDialog()
    }
  }

  function isValidSwiftCode(swiftCode) {
    const regex = new RegExp('^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$')
    const result = swiftCode.match(regex)
    if (result === null) {
      return false
    } else {
      return true
    }
  }

  const onBankDetailsSavedHandler = async () => {
    const bankingDetailsForm = { ...settingsForms['bankDetails'] }

    let isValid = true
    setChkEmpty(true)
    let data = {
      tourGuideId: currentUser.uid,
      bankDetails: {},
    }
    let errorMessage =
      'Invalid Bank Details! Please fill all the required fields'

    Object.keys(bankingDetailsForm).forEach((key) => {
      if (
        bankingDetailsForm[key].value == '' ||
        (key == 'iban' && !isValidIBANNumber(bankingDetailsForm[key].value)) ||
        (key == 'swiftCode' &&
          !isValidSwiftCode(bankingDetailsForm['swiftCode'].value))
      ) {
        isValid = false
        if (key == 'iban') {
          errorMessage = 'Invalid Bank Details! Please enter a valid IBAN.'
        } else if (key == 'swiftCode') {
          errorMessage =
            'Invalid Bank Details! Please enter a valid SWIFT Code.'
        }
        return
      } else {
        data['bankDetails'][key] = bankingDetailsForm[key].value
      }
    })

    if (isValid) {
      showLoaderDialog()
      setIsProcessing(true)
      setChkEmpty(false)
      const token = await currentUser.getIdToken()

      axios
        .put(UPDATE_BANK_DETAILS, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${token}`,
          },
        })
        .then((res) => {
          setIsProcessing(false)
          unSetDialog()
          if (res.status == 200) {
            if ('success' in res.data) {
              reloadBankingDetails(currentUser.uid)
              /*showDialog(
                'success',
                'Updated banking details successfully.',
                () => window.location.reload(),
              )*/
              successToast("Banking details updated successfully.")
            } else {
              //showDialog('error', 'Incorrect Details.')
              errorToast("Incorrect Details.")
              unSetDialog()
            }
          } else {
            //showDialog('error', 'Incorrect Details.')
            errorToast("Incorrect Details.")
            unSetDialog()
          }
        })
        .catch((e) => {
          setIsProcessing(false)
          unSetDialog()
          //showDialog('error', 'Incorrect Details.')
          errorToast("Incorrect Details.")
        })
    } else {
      //showDialog('error', errorMessage)
      errorToast(errorMessage)
      unSetDialog()
    }
  }

  const showLoaderDialog = () => {
    let dialog = {
      isLoader: true,
    }

    setDialog(dialog)
  }

  const showDialog = (type, message, btnAction) => {
    let dialog = {
      type: type,
      message: message,
      btnTxt: 'OK',
      btnAction: btnAction == undefined ? () => unSetDialog() : btnAction,
    }

    setDialog(dialog)
  }

  return (
    <div id="setting" style={{ pointerEvents: isProcessing ? 'none' : '' }}>
      <div className="settingbars">
        <button className={selectedTab == 'changePassword' ? "selectedTab": ""}
          onClick={() =>
            selectedTab != 'changePassword'
              ? setSelectedTab('changePassword')
              : {}
          }
        >
          Change Password
        </button>
        <button className={selectedTab == 'language' ? "selectedTab": ""}
          onClick={() =>
            selectedTab != 'language' ? setSelectedTab('language') : {}
          }
        >
          Language Settings
        </button>
        <button className={selectedTab == 'units_currency' ? "selectedTab": ""}
          onClick={() =>
            selectedTab != 'units_currency'
              ? setSelectedTab('units_currency')
              : {}
          }
        >
          Units & Currency
        </button>
        {/* <button
          onClick={() =>
            selectedTab != "payment_method"
              ? setSelectedTab("payment_method")
              : {}
          }
        >
          Payment Method
        </button> */}
        <button className={selectedTab == 'bankDetails' ? "selectedTab": ""}
          onClick={() =>
            selectedTab != 'bankDetails' ? setSelectedTab('bankDetails') : {}
          }
        >
          Bank Details
        </button>
      </div>
      <div className="settingData">
        {/*<div className="settingH">
          <span>Settings</span>
        </div>*/}
        <div className="settinginfo">
          {selectedTab == 'changePassword' ? (
            <ChangePassword
              settingsForms={settingsForms}
              selectedTab={selectedTab}
              onInputChangedHandler={onInputChangedHandler}
              onChangePasswordHandler={onChangePasswordHandler}
            />
          ) : selectedTab == 'language' ? (
            <ChangeLanguage
              onCheckboxChangedHandler={onCheckboxChangedHandler}
              settingsForms={settingsForms}
              selectedTab={selectedTab}
              createOrUpdateUserPref={createOrUpdateUserPref}
              onDropdownChangedHandler={onDropdownChangedHandler}
              customStyles={customStyles}
            />
          ) : selectedTab == 'units_currency' ? (
            <ChangeCurrency
              onCheckboxChangedHandler={onCheckboxChangedHandler}
              settingsForms={settingsForms}
              selectedTab={selectedTab}
              createOrUpdateUserPref={createOrUpdateUserPref}
              onDropdownChangedHandler={onDropdownChangedHandler}
              customStyles={customStyles}
            />
          ) : //   selectedTab == "payment_method" ? (
          //     <div>
          //       <div className="settingBtn">
          //         <div className="ChangeP">
          //           <span>Change Payment Method</span>
          //         </div>
          //         <div className="setBtns">
          //           <button>Cancel</button>
          //           <button onClick={createOrUpdateUserPref}>Save</button>
          //         </div>
          //       </div>
          //       <div className="settingValues">
          //         <div
          //           style={{
          //             display: "flex",
          //             columnGap: "20px",
          //             alignItems: "center",
          //           }}
          //         >
          //           <label className="dropdown_label">Payment Method:</label>
          //           <div>
          //             {paymentMethodOptions.map((method, i) => (
          //               <InputWidget
          //                 key={`payment_method_${i}`}
          //                 elementType="checkbox"
          //                 elementConfig={{ type: "checkbox", name: method.value }}
          //                 elementLabel={method.label}
          //                 checked={
          //                   settingsForms[selectedTab].method.value ==
          //                   method.value
          //                 }
          //                 changed={() => onCheckboxChangedHandler(method)}
          //               />
          //             ))}
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //   ) :
          selectedTab == 'bankDetails' ? (
            <ChangeBankDetails
              settingsForms={settingsForms}
              selectedTab={selectedTab}
              onDropdownChangedHandler={onDropdownChangedHandler}
              customStyles={customStyles}
              onInputChangedHandler={onInputChangedHandler}
              onBankDetailsSavedHandler={onBankDetailsSavedHandler}
              chkEmpty={chkEmpty}
            />
          ) : (
            <span></span>
          )}
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  )
}

export default Settings
