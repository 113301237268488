import React, { useContext, useEffect, useState } from "react";
import { auth } from "../firebase";
import {
  signInWithEmailAndPassword,
  signInWithCustomToken,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { SUPER_USER_ID } from "../constants";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [idToken, setIdToken] = useState(null);
  const [isSuperUser, setIsSuperUser] = useState(false);

  function signInWithEmail(token, callbackFunc, errorFunc) {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        return signInWithCustomToken(auth, token).then((v) => callbackFunc());
      })
      .catch((error) => {
        console.log(error);
        errorFunc();
      });
  }

  function signInWithRegisterEmail(email, password, callbackFunc, errorFunc) {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        return signInWithEmailAndPassword(auth, email, password).then((v) =>
          callbackFunc()
        );
      })
      .catch((error) => {
        console.log(error);
        errorFunc();
      });
  }

  function logoutCurrentUser() {
    setCurrentUser(null);
    setIdToken(null);
    setIsSuperUser(false);
    localStorage.clear();
    sessionStorage.clear();
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    return auth.signOut();
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);

      if (user != null) {
        user.getIdToken().then((token) => {
          setIdToken(token);
        });

        if(user.uid == SUPER_USER_ID){
          setIsSuperUser(true);
        }
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signInWithEmail,
    logoutCurrentUser,
    signInWithRegisterEmail,
    isSuperUser,
    idToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
