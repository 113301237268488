import {
  AR_MARKER_TYPE_TO_ICON,
  AR_MARKER_TYPE_TO_LABEL,
} from '../../Utils/BuilderUtils'

import { useState } from 'react'

function ARObjectsContainer({
  selectedARMarkerType,
  onARMarkerClickedHandler,
}) {
  return (
    <div id="addARObjectsContainer" >
      <div >
        Add AR Objects
        {/*<p  id="ar_helper_error" 
            style={!showError ? { display: 'none'} : {}}> 
            <b>(Please select a stop from the left bar first)</b>
        </p>*/}
      </div>
      <div>
        {[
          'food',
          'wheelChair',
          'washroom',
          'coffeeShop',
          'firstAid',
          'iceCream',
        ].map((arObject, i) => (
          <div
            key={`ar_object_${i}`}
            className={
              selectedARMarkerType == arObject
                ? 'ar_object selected_ar_object'
                : 'ar_object'
            }
            onClick={() => onARMarkerClickedHandler(arObject)}
          >
            <img src={AR_MARKER_TYPE_TO_ICON[arObject]} />
            <div>{AR_MARKER_TYPE_TO_LABEL[arObject]}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ARObjectsContainer
