import React, { useState, useEffect, useRef } from 'react'

import 'react-h5-audio-player/lib/styles.css'

import './style/audioWidget.css'

import delete2 from '../../assets/imgs/delete2.png'
import MicIcon from '../../assets/imgs/mic_icon.png'
import PausePlayIcon from '../../assets/imgs/pause_play_icon.png'
import stopPlay from '../../assets/imgs/stopPlay.png'
import PauseIcon from '../../assets/imgs/pause.png'
import PlayIcon from '../../assets/imgs/playaudio.png'
import PlayingImg from '../../assets/imgs/playing.png'
import AudioPlayer from 'react-h5-audio-player'
import Timer from './Timer'

function AudioRecordingDialog(props) {
  const [pushed, setPushed] = useState(false)

  const playerRef = useRef(null)

  const play = () => {
    playerRef.current.audio.current.play()
  }

  const pause = () => {
    playerRef.current.audio.current.pause()
  }

  const stop = () => {
    playerRef.current = null
  }

  return (
    <div className="dialog">
      <div className="dialog_body">
        <h3>Record Audio</h3>

        <div
          className="audio_widget_item"
          style={{
            boxShadow:
              '-6px -6px 15px rgba(255, 255, 255, 0.78),6px 6px 16px rgba(0, 0, 0, 0.15)',
          }}
        >
          <div
            style={{
              border: props.status === 'recording' && '1px solid red',
              boxShadow: pushed
                ? 'inset -6px -6px 15px rgba(255, 255, 255, 0.78),inset 6px 6px 16px rgba(0, 0, 0, 0.15)'
                : '-6px -6px 8px rgba(255, 255, 255, 0.78), 6px 6px 8px rgba(0, 0, 0, 0.15)',
            }}
            onClick={(e) => {
              if (props.status === 'idle' && !props.audio) {
                props.startRecording()
              } else if (props.status === 'recording') {
                props.stopRecording()
              } else if (playerRef !== null && pushed) {
                pause()
                setPushed(false)
              } else {
                play()
                setPushed(true)
              }
            }}
          >
            <img
              src={
                props.status === 'idle' && !props.audio
                  ? MicIcon
                  : props.status === 'recording'
                  ? stopPlay
                  : pushed
                  ? PauseIcon
                  : PlayIcon
              }
              alt="play"
            />
          </div>

          {props.status == 'recording' && props.status !== 'idle' && (
            <span>
              <img src={PlayingImg} alt="record" />{' '}
              <Timer status={props.status} />
            </span>
          )}
          {props.audio ? (
            <AudioPlayer
              ref={playerRef}
              src={props.audio}
              onEnded={() => {
                setPushed(false)
              }}
            />
          ) : (
            <div></div>
          )}
        </div>
        <div style={{ gap: '50px', display: 'flex', flexDirection: 'row' }}>
          <button
            className="dialog_btn"
            onClick={() => {
              props.stopRecording()
              stop()
              props.clearBlobUrl()
              props.onClose()
              setTimeout(() => {
                props.clearBlobUrl()
              }, 500)
            }}
          >
            Close
          </button>

          {props.status === 'recording' ||
          props.status === 'stopping' ||
          props.status === 'stopped' ? (
            <div style={{ gap: '50px', display: 'flex', flexDirection: 'row' }}>
              <button
                className="dialog_btn"
                onClick={() => {
                  props.stopRecording()
                  props.clearBlobUrl()
                  props.startRecording()
                }}
              >
                Record Again
              </button>

              <button
                className="dialog_btn"
                style={{ backgroundColor: '#0FAED8', color: 'white' }}
                onClick={() => {
                  props.stopRecording()
                  stop()
                  setTimeout(() => {
                    props.stopRecording()
                    props.onSave()
                  }, 100)
                }}
              >
                Save
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

export default AudioRecordingDialog
