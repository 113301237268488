import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import { useAuth } from './AuthContext'
import {
  FIREBASE_API_KEY,
  READ_BANK_DETAILS,
  READ_USER_DATA,
  READ_USER_PREF,
} from '../constants'
import { useCache } from '../Hooks/useCache'

const ProfileContext = React.createContext()

export function useProfile() {
  return useContext(ProfileContext)
}

export function ProfileProvider({ children }) {
  const { currentUser } = useAuth()
  const [profileData, setProfileData] = useState(null)
  const [userPrefData, setUserPrefData] = useState(undefined)
  const [bankDetails, setBankDetails] = useState(undefined)
  const { addToCache, getCache } = useCache()

  //   const fetchToken = async () => {
  //   const token = await currentUser.getIdToken();

  //   axios
  //   .get(READ_USER_DATA, data, {
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Authorization": `Token_${token}`
  //     },
  //   })
  //   .then((res) => {
  //     unSetDialog();
  //     if (res.status == 200) {
  //       // setUserDetails(res.data.data);
  //       setProfileData(res.data.data);
  //     }
  //   })
  //   .catch((e) => {
  //     console.log(e);
  //   });
  // }

  function updateProfile(data) {}

  async function reloadProfile(uid) {
    const token = await currentUser.getIdToken()
    axios
      .get(READ_USER_DATA, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`,
        },
        params: {
          uid: uid,
          userType: 'guide',
        },
      })
      .then((res) => {
        if (res.status == 200) {
          addToCache('profile', res.data.data)
          setProfileData(res.data.data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  async function reloadPreferences(uid) {
    const token = await currentUser.getIdToken()

    axios
      .get(READ_USER_PREF, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`,
        },
        params: {
          uid: uid,
          userType: 'guide',
        },
      })
      .then((res) => {
        if (res.status == 200) {
          if (res.data.data == null) {
            setUserPrefData(null)
          } else {
            setUserPrefData(res.data.data)
          }
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  async function reloadBankingDetails(uid) {
    const token = await currentUser.getIdToken()
    axios
      .get(READ_BANK_DETAILS, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`,
        },
        params: {
          tourGuideId: uid,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          if (res.data.data == null) {
            setBankDetails(null)
          } else {
            setBankDetails(res.data.data)
          }
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (currentUser) {
      const profileFromCache = getCache('profile')
      if (profileFromCache != null) {
        setProfileData(profileFromCache)
      }
      if (userPrefData === undefined) {
        reloadPreferences(currentUser.uid)
      }

      if (bankDetails === undefined) {
        reloadBankingDetails(currentUser.uid)
      }
    }
  }, [currentUser])

  let value = {
    profileData,
    setProfileData,
    reloadProfile,
    userPrefData,
    setUserPrefData,
    reloadPreferences,
    bankDetails,
    setBankDetails,
    reloadBankingDetails,
  }

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  )
}
