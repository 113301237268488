import React from "react";
import InputWidget from "../../InputWidget/InputWidget";
import Select from "react-select";
import { CURRENCY_OPTIONS } from "../../../constants";

function ChangeCurrency({
  createOrUpdateUserPref,
  onCheckboxChangedHandler,
  settingsForms,
  selectedTab,
  customStyles,
  onDropdownChangedHandler,
}) {
  return (
    <div>
      <div className="settingBtn">
        <div className="ChangeP">
          <span>Change Units & Currency</span>
        </div>
        <div className="setBtns">
          <button>CANCEL</button>
          <button onClick={createOrUpdateUserPref}>UPDATE</button>
        </div>
      </div>
      <div className="settingValues">
        <div
          style={{
            display: "flex",
            columnGap: "4em",
            alignItems: "center",
            marginLeft: "6.4em"
          }}
        >
          <label className="dropdown_label">Units:</label>
          <div>
            <InputWidget
              elementType="checkbox"
              elementConfig={{ type: "checkbox", name: "metric" }}
              elementLabel="Metric (km)"
              checked={settingsForms[selectedTab].unit == "metric"}
              changed={() => onCheckboxChangedHandler("metric")}
            />
            <InputWidget
              elementType="checkbox"
              elementConfig={{ type: "checkbox", name: "imperial" }}
              elementLabel="Imperial (miles)"
              checked={settingsForms[selectedTab].unit == "imperial"}
              changed={() => onCheckboxChangedHandler("imperial")}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            width: "30em",
            columnGap: "3em",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <label className="dropdown_label">Currency:</label>
          <Select
            value={settingsForms[selectedTab].currency}
            onChange={onDropdownChangedHandler}
            options={CURRENCY_OPTIONS}
            styles={customStyles}
          />
        </div>
      </div>
    </div>
  );
}

export default ChangeCurrency;
