import EditIcon from '../../assets/imgs/edit_pencil.png'
import RestaurantIcon from '../../assets/imgs/restaurant_icon.png'
import WheelChairIcon from '../../assets/imgs/wheelchair_icon.png'
import WCIcon from '../../assets/imgs/wc_icon.png'
import CoffeeIcon from '../../assets/imgs/coffee_icon.png'
import FirstAidKitIcon from '../../assets/imgs/first_aid_kit_icon.png'
import IceCreamIcon from '../../assets/imgs/icecream_icon.png'


const AR_MARKER_TYPE_TO_ICON = {
  food: RestaurantIcon,
  washroom: WCIcon,
  coffeeShop: CoffeeIcon,
  firstAid: FirstAidKitIcon,
  iceCream: IceCreamIcon,
  wheelChair: WheelChairIcon,
}

function ARMarkersList({
  arMarkers,
  currentStop,
  selectedStop,
  selectedARMarker,
  selectedARMarkerType,
  onARMarkerClickedHandler,
  openEditDialog,
}) {
  //console.log("ARML: AR Markers list updated: ", arMarkers)

  function editARMarker(e, arMarkerId, arMarkerType) {
    if (selectedStop == currentStop) {
      e.stopPropagation()

      if (arMarkerId !== selectedARMarker) {
        onARMarkerClickedHandler(arMarkerId, arMarkerType)
      }

      openEditDialog()
    }
  }

  function toggleARMarker(e, arMarkerId, arMarkerType) {
    if (selectedStop == currentStop) e.stopPropagation()

    if (arMarkerId !== selectedARMarker) {
      onARMarkerClickedHandler(arMarkerId, arMarkerType)
    } else {
      onARMarkerClickedHandler('', '')
    }
  }

  return (
    <div className="ar_stop_images">
      <div><p>AR MARKERS</p></div>
      <div>
        {/*<div style={{ marginLeft: '1.5px' }}>AR MARKERS</div>*/}
        <div className="ar_images">
          {arMarkers.length === 0 ? <div><p><b>None</b> (see bottom-right bar)</p></div> : null}
          {arMarkers.map((arMarker, i) => (
            <div
              key={`stop_ar_image_${i}`}
              className={
                arMarker['arMarkerId'] === selectedARMarker &&
                arMarker['stopId'] === selectedStop
                  ? 'arMarkerImages arMarker_active '
                  : 'arMarkerImages'
              }
              onClick={(e) =>
                toggleARMarker(e, arMarker['arMarkerId'], arMarker['type'])
              }
            >
              {/* <div
                onClick={(e) =>
                  editARMarker(e, arMarker['arMarkerId'], arMarker['type'])
                }
                className="editARMarker"
              >
                <img src={EditIcon} />
              </div> */}
              <img
                src={
                  'assetLink' in arMarker
                    ? arMarker['assetLink']
                    : arMarker['markerPicture']
                }
              />
              <div
                className="arMarkerType"
                onClick={(e) =>
                  editARMarker(e, arMarker['arMarkerId'], arMarker['type'])
                }
              >
                <img
                  className="ar_marker_type_icon"
                  src={AR_MARKER_TYPE_TO_ICON[arMarker['type']]}
                />
                <img className="ar_marker_edit_icon" src={EditIcon} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ARMarkersList
