import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAu-DZk1vdEUiPKvbOVMxtUNTc_TBNsjQM",
    authDomain: "insidartours.firebaseapp.com",
    databaseURL: "https://insidartours-default-rtdb.firebaseio.com",
    projectId: "insidartours",
    storageBucket: "insidartours.appspot.com",
    messagingSenderId: "802208291356",
    appId: "1:802208291356:web:2e6e0aff39dbbfe6eb5dd9",
    measurementId: "G-DYXH9VBMEY"
};
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export default app;