import { useRef } from 'react'

import CancelIcon from '../../assets/imgs/cancel_icon.png'

import { infoToast, successToast, errorToast, warningToast } from "../Dialog/Toast";

import  { UPLOAD_SIZE_LIMIT } from '../../constants'

function ImageUploadWidget({
  label,
  showDialog,
  unSetDialog,
  showLoaderDialog,
  uploadFile,
  tourImages,
  setTourImages,
  removeTourImage,
  chkEmpty,
}) {
  const tourImagesUploadRef = useRef(null)

  const isImageFile = (file) => {
    const allowedTypes = ["image/png", "image/jpg", "image/jpeg"];

    return allowedTypes.includes(file.type);
  };

  return (
    <div className="image_upload_widget">
      <label>{label}</label>
      <div
        style={{
          border: chkEmpty && tourImages.length < 1 && '1px solid red',
          marginTop: '0.5em'
        }}
      >
        <div
          className="upload_btn"
          onClick={() => tourImagesUploadRef.current.click()}
        >
          Upload Picture
        </div>
        <input
          ref={tourImagesUploadRef}
          type="file"
          onChange={async (e) => {
            const file = e.target.files[0];

            if (file && isImageFile(file)) {
              let result_base64 = await new Promise((resolve) => {
                var reader = new window.FileReader()
                reader.onload = (e) => resolve(reader.result)
                reader.readAsDataURL(e.target.files[0])
              })

              let fileSize = e.target.files[0].size

              if(fileSize > UPLOAD_SIZE_LIMIT){
                errorToast("File size must be less than 30MB")
              }else{
                let base64 = result_base64
                showLoaderDialog()

                await uploadFile(
                  'image',
                  e.target.files[0].name,
                  base64.split(',')[1],
                  (url, identifier) => {
                    unSetDialog()
                    setTourImages([
                      ...tourImages,
                      {
                        link: url,
                        name: identifier,
                      },
                    ])
                    successToast("Image added successfully")
                  },
                )
              }

              
              e.target.value = null
            } else {
              errorToast('Please select a valid image file.');
            }
          }}
          style={{ display: 'none' }}
          accept="image/png, image/gif, image/jpeg, image/jpg"
        />
        <div className="uploaded_images_grid">
          {tourImages.map((tourImage, i) => (
            <div key={`tour_img_${i}`}>
              <div>
                <img
                  src={CancelIcon}
                  alt="Remove Icon"
                  onClick={() => removeTourImage(i)}
                />
              </div>
              <img src={tourImage.link} alt="Tour Image" />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ImageUploadWidget
