import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css'
import { MAPBOX_ACCESS_TOKEN } from '../constants'
import axios from 'axios'
import { useCallback } from 'react'
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding'

export function initializeMap(mapContainerRef, hasTourId, setMapInitialized) {
  mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN
  let map = new mapboxgl.Map({
    container: mapContainerRef.current,
    style: 'mapbox://styles/mapbox/streets-v11',
    center: [19.073991, 47.50479],
    zoom: 8.6,
  })

  map.on('load', () => {
    if (!hasTourId) {
      navigator.geolocation.getCurrentPosition(function (position) {
        map.flyTo({
          center: [position.coords.longitude, position.coords.latitude],
          zoom: 14,
        })
      })
    }

    map.addSource('markers', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    })

    map.addSource('route', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    })

    map.addLayer({
      id: 'routeLines',
      type: 'line',
      source: 'route',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#149bd0',
        'line-width': 4,
      },
    })

    map.addLayer({
      id: 'routeDist',
      type: 'symbol',
      source: 'route',
      layout: {
        'text-font': ['Montserrat Bold', 'Open Sans Semibold', 'Arial Unicode MS Bold'],
        'text-field': '{distance} ',
        'symbol-placement': 'line',
        'text-offset': [0.5, 0.5],
        'text-size': 18,
      },
      paint: {
        "text-color": "#818996"
      },
    })

    map.addLayer({
      id: 'points',
      type: 'circle',
      source: 'markers',
      paint: {
        'circle-color': ['get', 'color'],
        'circle-radius': 22,
        'circle-stroke-width': 2,
        'circle-stroke-color': ['get', 'border'],
      },
    })
    
    map.addLayer({
      id: 'point',
      type: 'symbol',
      source: 'markers',
      layout: {
        'text-field': ['get', 'title'],
        'text-font': ['Montserrat Bold', 'Open Sans Semibold', 'Arial Unicode MS Bold'],
        'text-offset': [0, -1.5],
        'text-anchor': 'bottom',
        'text-size': 16,
      },
      paint: {
        "text-color": "#4a4a4a"
      },
    })

    map.addLayer({
      id: 'point_count',
      type: 'symbol',
      source: 'markers',
      layout: {
        'text-field': ['get', 'count'],
        'text-font': ['Montserrat Bold', 'Open Sans Semibold', 'Arial Unicode MS Bold'],
        'text-size': 18,
      },
      paint: {
        "text-color": "#818996"
      },
    })

    setMapInitialized(true)

    // map.on('mouseenter', 'point', (e) => {
    //   console.log(e)
    // })
  })

  return map
}

export async function getRoutes(stops) {
  let allRoutes = []

  for (let i = 0; i < stops.length - 1; i++) {
    allRoutes.push(
      new Promise((resolve) => {
        axios
          .get(
            `https://api.mapbox.com/directions/v5/mapbox/walking/${
              stops[i]['location']['lng']
            },${stops[i]['location']['lat']};${
              stops[i + 1]['location']['lng']
            },${
              stops[i + 1]['location']['lat']
            }?geometries=geojson&access_token=${MAPBOX_ACCESS_TOKEN}`,
          )
          .then((res) => {
            if (res.status == 200) {
              resolve({
                distance: res.data.routes[0].distance,
                duration: res.data.routes[0].duration,
                coordinates: res.data.routes[0]['geometry']['coordinates'],
              })
            }
          })
          .catch((e) => {
            console.log(e)
          })
      }),
    )
  }

  return await Promise.all(allRoutes)
}

export const fetchCoordinatesFromLocation = (inputText) => {
  const geocodingClient = mbxGeocoding({
    accessToken: MAPBOX_ACCESS_TOKEN,
  })

  // geocoding with countries
  return geocodingClient
    .forwardGeocode({
      query: inputText,
      autocomplete: true,
    })
    .send()
    .then((response) => {
      const match = response.body
      const coordinates = match.features[0].geometry.coordinates

      return coordinates
    })
}
