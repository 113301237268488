import React from "react";
import InputWidget from "../../InputWidget/InputWidget";
import Select from "react-select";
import { COUNTRY_OPTIONS, CURRENCY_OPTIONS } from "../../../constants";

function ChangeBankDetails({
  settingsForms,
  selectedTab,
  customStyles,
  onDropdownChangedHandler,
  onBankDetailsSavedHandler,
  onInputChangedHandler,
  chkEmpty,
}) {
  return (
    <div>
      <div className="settingBtn">
        <div className="ChangeP">
          <span>Edit Banking Details</span>
        </div>
        <div className="setBtns">
          <button>CANCEL</button>
          <button onClick={onBankDetailsSavedHandler}>UPDATE</button>
        </div>
      </div>
      <div className="settingValues">
        <div style={{ width: "30em" }}>
          <InputWidget
            elementType={settingsForms[selectedTab]["bankName"].elementType}
            elementConfig={settingsForms[selectedTab]["bankName"].elementConfig}
            value={settingsForms[selectedTab]["bankName"].value}
            elementLabel={settingsForms[selectedTab]["bankName"].elementLabel}
            floatingButton={
              settingsForms[selectedTab]["bankName"].floatingButton
            }
            chkEmpty={chkEmpty}
            changed={(event) =>
              onInputChangedHandler(
                event,
                settingsForms[selectedTab]["bankName"].id
              )
            }
          />
        </div>

        <div style={{ width: "30em" }}>
          <InputWidget
            elementType={settingsForms[selectedTab]["swiftCode"].elementType}
            elementConfig={
              settingsForms[selectedTab]["swiftCode"].elementConfig
            }
            value={settingsForms[selectedTab]["swiftCode"].value}
            elementLabel={settingsForms[selectedTab]["swiftCode"].elementLabel}
            floatingButton={
              settingsForms[selectedTab]["swiftCode"].floatingButton
            }
            chkEmpty={chkEmpty}
            changed={(event) =>
              onInputChangedHandler(
                event,
                settingsForms[selectedTab]["swiftCode"].id
              )
            }
          />
        </div>

        <div style={{ width: "30em" }}>
          <InputWidget
            elementType={settingsForms[selectedTab]["iban"].elementType}
            elementConfig={settingsForms[selectedTab]["iban"].elementConfig}
            value={settingsForms[selectedTab]["iban"].value}
            elementLabel={settingsForms[selectedTab]["iban"].elementLabel}
            floatingButton={settingsForms[selectedTab]["iban"].floatingButton}
            chkEmpty={chkEmpty}
            changed={(event) =>
              onInputChangedHandler(
                event,
                settingsForms[selectedTab]["iban"].id
              )
            }
          />
        </div>

        <div style={{ width: "30em" }}>
          <InputWidget
            elementType={
              settingsForms[selectedTab]["accountHolderName"].elementType
            }
            elementConfig={
              settingsForms[selectedTab]["accountHolderName"].elementConfig
            }
            value={settingsForms[selectedTab]["accountHolderName"].value}
            elementLabel={
              settingsForms[selectedTab]["accountHolderName"].elementLabel
            }
            floatingButton={
              settingsForms[selectedTab]["accountHolderName"].floatingButton
            }
            chkEmpty={chkEmpty}
            changed={(event) =>
              onInputChangedHandler(
                event,
                settingsForms[selectedTab]["accountHolderName"].id
              )
            }
          />
        </div>

        <div style={{ width: "30em" }}>
          <InputWidget
            elementType={settingsForms[selectedTab]["address"].elementType}
            elementConfig={settingsForms[selectedTab]["address"].elementConfig}
            value={settingsForms[selectedTab]["address"].value}
            elementLabel={settingsForms[selectedTab]["address"].elementLabel}
            floatingButton={
              settingsForms[selectedTab]["address"].floatingButton
            }
            chkEmpty={chkEmpty}
            changed={(event) =>
              onInputChangedHandler(
                event,
                settingsForms[selectedTab]["address"].id
              )
            }
          />
        </div>

        <div
          style={{
            display: "flex",
            columnGap: "20px",
            alignItems: "center",
            marginTop: "2em",
            width: "30em",
          }}
        >
          <label className="dropdown_label">COUNTRY *:</label>
          <Select
            value={settingsForms[selectedTab].country}
            onChange={(newVal) => onDropdownChangedHandler(newVal, "country")}
            options={COUNTRY_OPTIONS}
            styles={customStyles}
          />
        </div>

        <div
          style={{
            display: "flex",
            columnGap: "20px",
            alignItems: "center",
            marginTop: "2em",
            marginBottom: "2em",
            width: "30em",
          }}
        >
          <label className="dropdown_label">CURRENCY *:</label>
          <Select
            value={settingsForms[selectedTab].currency}
            onChange={(newVal) => onDropdownChangedHandler(newVal, "currency")}
            options={CURRENCY_OPTIONS}
            styles={customStyles}
          />
        </div>
      </div>
    </div>
  );
}

export default ChangeBankDetails;
