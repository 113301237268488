import React, { useState, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './Contexts/AuthContext'
import { ProfileProvider } from './Contexts/ProfileContext'
import { DialogProvider } from './Contexts/DialogContext'
import { WarningOnNavigateProvider } from './Contexts/WarningOnNavigateContext'
import { useWindowSize } from './Hooks/useWindowSize'

import Navbar from './Components/Navbar/Navbar'
import Routes from './Routes/Routes'

function App() {
  const [isMobile, setIsMobile] = useState(false)
  const size = useWindowSize()

  useEffect(() => {
    if (size.width) {
      setIsMobile(size.width <= 768)
    }
  }, [size])

  return (
    <div>
      {isMobile &&
      !(
        window.location.pathname.includes('tourist') ||
        window.location.pathname.includes('guide')
      ) ? (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
          }}
        >
          <h1 style={{ textAlign: 'center' }}>
            Please access the tour builder on a Desktop device
          </h1>
        </div>
      ) : (
        <div>
          <AuthProvider>
            <ProfileProvider>
              <WarningOnNavigateProvider>
                <DialogProvider>
                  <BrowserRouter getUserConfirmation={() => {}}>
                    <Routes />
                  </BrowserRouter>
                </DialogProvider>
              </WarningOnNavigateProvider>
            </ProfileProvider>
          </AuthProvider>
        </div>
      )}
    </div>
  )
}

export default App
