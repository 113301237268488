import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax

import RestaurantIcon from '../assets/imgs/restaurant_icon.png'
import WheelChairIcon from '../assets/imgs/wheelchair_icon.png'
import WCIcon from '../assets/imgs/wc_icon.png'
import CoffeeIcon from '../assets/imgs/coffee_icon.png'
import FirstAidKitIcon from '../assets/imgs/first_aid_kit_icon.png'
import IceCreamIcon from '../assets/imgs/icecream_icon.png'

import {
  COUNTRY_OPTIONS,
  LANGUAGE_OPTIONS,
  TOUR_TYPE_OPTIONS,
  TOUR_MODE_OPTIONS,
  TOUR_SPECIALTY_OPTIONS,
  CURRENCY_OPTIONS,
} from '../constants'
import { isNumeric } from './utils'
import { fetchCoordinatesFromLocation } from './MapUtils'

export function initializeBuilderForm(tourResponse, builderForm) {
  let responseKeys = Object.keys(tourResponse)

  responseKeys = responseKeys.filter(
    (key) =>
      ![
        'tourCountry',
        'tourType',
        'tourMode',
        'tourSpecialty',
        'tourLanguage',
      ].includes(key),
  )

  let updatedBuilderForm = { ...builderForm }

  for (let i = 0; i < responseKeys.length; i++) {
    if (responseKeys[i] in builderForm && responseKeys[i] != 'tourCurrency') {
      let updatedBuilderFormElement = updatedBuilderForm[responseKeys[i]]
      updatedBuilderFormElement.value = tourResponse[responseKeys[i]]
      updatedBuilderForm[responseKeys[i]] = updatedBuilderFormElement
    } else if (responseKeys[i] == 'tourCurrency') {
      updatedBuilderForm['tourPrice']['prefix'] = tourResponse['tourCurrency']
      updatedBuilderForm['tourCurrency'] = CURRENCY_OPTIONS.filter(
        (val) => val.value === tourResponse['tourCurrency'],
      )[0]
    }
  }

  updatedBuilderForm['tourCountry'] = tourResponse['tourCountry'] 
  ? COUNTRY_OPTIONS.filter(
    (country) => country['value'] == tourResponse['tourCountry'],
  )[0]
  : {
    label: "Select ...",
    value: "",
  } 

  updatedBuilderForm['tourCity'] = {
    label: tourResponse['tourCity'] ?? "Select ...",
    value: tourResponse['tourCity'] ?? "",
  } 

  updatedBuilderForm['tourType'] = tourResponse['tourType'] 
  ? TOUR_TYPE_OPTIONS.filter(
    (tourType) => tourType['value'] == tourResponse['tourType'],
  )[0]
  : {
    label: "Select ...",
    value: "",
  } 

  updatedBuilderForm['tourMode'] = tourResponse['tourMode'] 
  ? TOUR_MODE_OPTIONS.filter(
    (tourMode) => tourMode['value'] == tourResponse['tourMode'],
  )[0]
  : {
    label: "Select ...",
    value: "",
  } 

  updatedBuilderForm['tourSpecialty'] = tourResponse['tourSpecialty']
  ? TOUR_SPECIALTY_OPTIONS.filter(
    (tourSpecialty) => tourSpecialty['value'] == tourResponse['tourSpecialty'],
  )[0]
  : {
    label: "Select ...",
    value: "",
  } 

  updatedBuilderForm['tourLanguage'] = tourResponse['tourLanguage']
  ? LANGUAGE_OPTIONS.filter(
    (lang) => lang['value'] == tourResponse['tourLanguage'],
  )[0]
  : {
    label: "Select ...",
    value: "",
  } 

  console.log("Updated Builder Form: "+ JSON.stringify(updatedBuilderForm))

  return updatedBuilderForm
}

export function initializeTours(stopsObj, arMarkers) {
  let bounds = new mapboxgl.LngLatBounds()
  const updatedTourStops = {}
  const stops = Object.values(stopsObj)
  for (let i = 0; i < stops.length; i++) {
    const loadedStop = stops[i]

    const stopLocation = {
      lat: parseFloat(loadedStop['gpsCoordinates'][0]),
      lng: parseFloat(loadedStop['gpsCoordinates'][1]),
    }

    bounds.extend([
      loadedStop['gpsCoordinates'][1],
      loadedStop['gpsCoordinates'][0],
    ])

    let images = []
    if ('pictureURLs' in loadedStop) {
      const stopImages = loadedStop['pictureURLs']
      for (let j = 0; j < stopImages.length; j++) {
        stopImages[j]['id'] = j
      }
      images = stopImages
    }

    let loadedStopData = {
      id: loadedStop['id'],
      name: 'name' in loadedStop ? loadedStop['name'] : '',
      imgs: images,
      location: stopLocation,
      arMarker: arMarkers,
      order: loadedStop['order'],
    }

    if ('audioURL' in loadedStop) {
      if (loadedStop['audioURL'].length > 200) {
        loadedStopData['audioURL'] =
          'data:audio/mp3;base64,' + loadedStop['audioURL']
      } else {
        loadedStopData['audioURL'] = loadedStop['audioURL']
      }

      loadedStopData['audioName'] = loadedStop['audioName']
    }

    updatedTourStops[loadedStop['id']] = loadedStopData
  }

  if (stops.length == 0) {
    bounds = null
  }

  return {
    updatedTourStops,
    bounds,
  }
}

export const updateMapByCoordinates = (
  map,
  location,
  setIsVisible,
  showErrorDialog,
) => {
  if (isNumeric(location['latitude']) && isNumeric(location['longitude'])) {
    map.flyTo({
      center: [location['longitude'], location['latitude']],
    })
    setIsVisible(false)
  } else {
    showErrorDialog()
  }
}

export const updateMapBySearchLocation = (
  map,
  searchText,
  setLocation,
  hideDialog,
) => {
  if (searchText !== '' && typeof searchText == 'string') {
    // for searching with search bar
    const result = fetchCoordinatesFromLocation(searchText)
    const getResult = async () => {
      const arrayOfCoordinatesValues = await result
      setLocation({
        latitude: arrayOfCoordinatesValues[0],
        longitude: arrayOfCoordinatesValues[1],
      })
      map.flyTo({
        center: [arrayOfCoordinatesValues[0], arrayOfCoordinatesValues[1]],
        zoom: 14,
      })
    }
    getResult()
    hideDialog()
  }
}

export const AR_MARKER_TYPE_TO_ICON_FOR_SAVING = {
  food:
    'https://firebasestorage.googleapis.com/v0/b/insidartours.appspot.com/o/markerPictures%2Frestaurant_icon.jpg?alt=media&token=b534f7d8-1743-420f-9c7c-fe893595d81f',
  washroom:
    'https://firebasestorage.googleapis.com/v0/b/insidartours.appspot.com/o/markerPictures%2Fwc_icon.jpg?alt=media&token=7e56c864-1eea-49e2-a74e-9085b11f8ee2',
  coffeeShop:
    'https://firebasestorage.googleapis.com/v0/b/insidartours.appspot.com/o/markerPictures%2Fcoffee_icon.jpg?alt=media&token=7b857139-5491-4e87-9eab-9951be824bf7',
  firstAid:
    'https://firebasestorage.googleapis.com/v0/b/insidartours.appspot.com/o/markerPictures%2Ffirst_aid_kit_icon.jpg?alt=media&token=95f644e8-4cd1-42cd-b537-746fa1c94b13',
  iceCream:
    'https://firebasestorage.googleapis.com/v0/b/insidartours.appspot.com/o/markerPictures%2Ficecream_icon.jpg?alt=media&token=eda05611-c41e-464f-9fb7-466a70c99982',
  wheelChair:
    'https://firebasestorage.googleapis.com/v0/b/insidartours.appspot.com/o/markerPictures%2Fwheelchair_icon.jpg?alt=media&token=48fd3490-3611-4a7f-8a4d-fd4319be70c4',
}

export const AR_MARKER_TYPE_TO_ICON = {
  food: RestaurantIcon,
  washroom: WCIcon,
  coffeeShop: CoffeeIcon,
  firstAid: FirstAidKitIcon,
  iceCream: IceCreamIcon,
  wheelChair: WheelChairIcon,
}

export const AR_MARKER_TYPE_TO_LABEL = {
  food: 'Food Area',
  washroom: 'Washrooms',
  coffeeShop: 'Coffee Shop',
  firstAid: 'First Aid',
  iceCream: 'Ice Cream',
  wheelChair: 'Wheelchair',
}

export const errorMessages = {
  name: 'Incomplete Tour! Please enter a valid Tour Title',
  startLocation:
    'Incomplete Tour! Please enter a valid name for the first Tour Stop.',
  addStops:
    'Incomplete Tour! Please add at least 2 stops to make a valid tour.',
  tourStop:
    'Error Message: Incomplete Tour Stop! Please enter a valid name for the highlighted stop(s).',
  endLocation:
    ' Incomplete Tour! Please enter a valid name for the last Tour Stop.',
  tourCountry: 'Incomplete Tour! Please select a valid Tour Country.',
  tourCity: 'Incomplete Tour! Please select a valid Tour City.',
  tourType: 'Incomplete Tour! Please select a valid Tour Type.',
  tourMode: ' Incomplete Tour! Please select a valid Tour Mode.',
  tourSpecialty: ' Incomplete Tour! Please select a valid Tour Specialty.',
  tourPicture:
    'Incomplete Tour! Please add at least 1 tour picture to make a valid tour.',
  tourismDescription: 'Incomplete Tour! Please enter a valid Tour Description.',
  tourAudio:
    'Incomplete Tour! Please add at least 1 tour audio to make a valid tour.',
  tourLanguage: 'Incomplete Tour! Please select a valid Tour Language.',
  tourPrice: ' Incomplete Tour! Please enter a valid Tour Price.',
}
