import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import HelpIcon from '../../assets/imgs/help.svg'
import ErrorIcon from '../../assets/imgs/error_icon.png'
import WarningIcon from '../../assets/imgs/warning_icon.png'
import SuccessIcon from '../../assets/imgs/success_icon.png'

export const infoToast = (infoText) => toast.info(infoText, {
  icon: ({theme, type}) =>  <img src={HelpIcon}/>
});

export const successToast = (successText) => toast.success(successText, {
  className: "toast",
  bodyClassName: "toast",
  progressClassName: "toastProgressbar",
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  icon: ({theme, type}) =>  <img src={SuccessIcon}/>,
});

export const errorToast = (errorText) => toast.error(errorText, {
  className: "toast",
  bodyClassName: "toast",
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  icon: ({theme, type}) =>  <img src={ErrorIcon}/>
});

export const warningToast = (warningText) => toast.warning(warningText, {
  className: "toast",
  bodyClassName: "toast",
  progressClassName: "toastProgressbar",
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  icon: ({theme, type}) =>  <img src={WarningIcon}/>
});
