import React from "react";
import Select from "react-select";
import { COUNTRY_OPTIONS } from "../../constants";
import InputWidget from "../InputWidget/InputWidget";

function Register({
  setCheckboxChecked,
  checkBoxChecked,
  onDropdownChangedHandler,
  onFormSubmitHandler,
  onInputChangedHandler,
  registerForm,
  getFormElementsArray,
  customStyles,
}) {
  return (
    <div style={{ margin: "auto 0" }}>
      <form id="forms" onSubmit={onFormSubmitHandler} autoComplete="off">
        {getFormElementsArray().map((elem) =>
          elem.id == "country" ? (
            <div className="country_input">
              <label> Country
                <span> </span>
              </label>
              <Select
                  value={registerForm["country"]["country"]}
                  onChange={(val) => onDropdownChangedHandler(val)}
                  options={COUNTRY_OPTIONS}
                  styles={customStyles}
              />
            </div>
          ) : (
            <InputWidget
              key={elem.id}
              elementType={elem.config.elementType}
              elementConfig={elem.config.elementConfig}
              value={elem.config.value}
              elementLabel={elem.config.elementLabel}
              floatingButton={elem.config.floatingButton}
              changed={(event) => onInputChangedHandler(event, elem.id)}
            />
          )
        )}
        <div style={{ margin: "0.6em 0em 0.6em 22em"}}>
          <InputWidget
            elementType="checkbox"
            elementConfig={{
              type: "checkbox",
              name: "agreeToAll",
            }}
            elementLabel={"I agree to all terms and conditions"}
            checked={checkBoxChecked}
            changed={() => setCheckboxChecked(!checkBoxChecked)}
          />
        </div>
        <button id="loginRegisterButton" type="submit">
          Register
        </button>
      </form>
    </div>
  );
}

export default Register;
