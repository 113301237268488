import CancelIcon from '../../assets/imgs/close_icon.png'
import {useState, useEffect} from 'react'
import InputWidget from '../InputWidget/InputWidget'
import EditIcon from '../../assets/imgs/edit_pencil.png'

import { useProfile } from '../../Contexts/ProfileContext'
import { useNavigate, useParams } from 'react-router-dom'

function CashOutDialog({
  showDialog,
  unSetDialog,
  isVisible,
  setIsVisible,
  handleCashout,
}) {
  const { bankDetails } = useProfile()
  const navigate = useNavigate()
  const [amountToWithdraw, setAmountToWithdraw] = useState(0.0)

  function maskString(inputString) {
    const length = inputString.length;
    
    if (length > 4) {
      const lastFourChars = inputString.substring(length - 4); // Get the last 4 characters
      const maskedString = '*'.repeat(length - 4) + lastFourChars; // Replace the rest with asterisks
      return maskedString;
    } else {
      return inputString; 
    }
  }

  const handleInputChange = (event) => {
    setAmountToWithdraw(event.target.value);
  };

  return (
    <div id="cashOutDialog" style={{ display: isVisible ? '' : 'none' }}>
      <div
        className="dialog_bg"
        onClick={() => {
          setIsVisible(false)
        }}
      ></div>
      <div id="cashOutDialogBody">
        <div className="dialog_header">
          <h3>Cash Out</h3>
          <img
            src={CancelIcon}
            alt="Close Icon"
            onClick={() => {
              setIsVisible(false)
            }}
          />
        </div>

        <div className="cashOutDialogSubBody">
          <div className="cashOutDialogSubHeading">
            <h4> Primary Banking Details * </h4> 
            <div className="editIcon">
              <img 
                src={EditIcon} 
                onClick={()=>{navigate("/settings")}}/>
            </div>
          </div>
          <p className="bankingInfo"> Account Holder: {bankDetails ? bankDetails.accountHolderName : ""} </p>
          <p className="bankingInfo"> IBAN: {bankDetails ? maskString(bankDetails.iban) : ""} </p>
          <p className="bankingInfo"> Address: {bankDetails ? bankDetails.address : ""} </p>

          <p className="helperText"> * The funds will be deposited into your primary bank account. Please update your banking details if necessary. </p>


          <div className="cashOutDialogInput">
            <InputWidget
                chkEmpty={true}
                elementType='input'
                elementConfig={{
                  type: 'text',
                  name: 'cashout',
                  placeholder: '0.0',
                }}
                value={amountToWithdraw}
                elementLabel="Amount to withdraw"
                floatingButton={null}
                changed={handleInputChange}
              />
          </div> 

        </div> 


        <div className="">
          <div className="edit_btns">
            <button 
              className="normalBtn"
              onClick={async ()=>{
                const success = await handleCashout(amountToWithdraw)
                console.log("Cashout success: ",success)
                setIsVisible(!success)
              }}
            >
              Cash out
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CashOutDialog
