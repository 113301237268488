import React from "react";
import InputWidget from "../../InputWidget/InputWidget";
import Select from "react-select";
import { LANGUAGE_OPTIONS } from "../../../constants";

function ChangeLanguage({
  createOrUpdateUserPref,
  settingsForms,
  selectedTab,
  customStyles,
  onDropdownChangedHandler,
}) {

  const cleanLanguageOptions = (languageOptions) => {
    const cleanedOptions = languageOptions.map(option => ({
      value: option.value,
      label: option.label.split('[')[0].trim()
    }));

    return cleanedOptions;
  }

  return (
    <div>
      <div className="settingBtn">
        <div className="ChangeP">
          <span>Change Language</span>
        </div>
        <div className="setBtns">
          <button>CANCEL</button>
          <button onClick={createOrUpdateUserPref}>UPDATE</button>
        </div>
      </div>
      <div className="settingValues">
        <div
          style={{
            display: "flex",
            columnGap: "20px",
            alignItems: "center",
            marginTop: '1em',
            width: "30em",
          }}
        >
          <label className="dropdown_label">LANGUAGE:</label>
          <Select
            value={settingsForms[selectedTab].lang}
            onChange={onDropdownChangedHandler}
            options={cleanLanguageOptions(LANGUAGE_OPTIONS)}
            styles={customStyles}
          />
        </div>
      </div>
    </div>
  );
}

export default ChangeLanguage;
