import React, { Fragment, useMemo, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import LogoutIcon from '../../assets/imgs/logout_icon.png'
import profile from '../../assets/dashboard/profile.png'
import pen from '../../assets/dashboard/pen.png'
import { useCache } from '../../Hooks/useCache'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfStroke } from '@fortawesome/free-solid-svg-icons';
import Rating from '@mui/material/Rating';
import StarBorderIcon from '@mui/icons-material/StarBorder';

function UserDataSidePanel({
  logoutCurrentUser,
  setProfileData,
  setUserPrefData,
  profileData,
  tours,
  showLoaderDialog,
  unSetLoaderDialog,
  ratingVal,
}) {
  const navigate = useNavigate()

  const [rating, setRating] = useState(5);
  const [ratingColor, setRatingColor] = useState("#818996");

  let { deleteAll } = useCache()
  const getProfilePictureURL = (url) => {
    if(!url){
      return null;
    }
    
    if (url.includes('?')) {
      return url + '&' + Date.now()
    } else {
      return url + '?' + Date.now()
    }
  }

  const userDetails = useMemo(() => {
    const keys = ['email', 'occupation', 'tourismExperience', 'specialty']
    const labels = ['Email', 'Occupation', 'Tourism Experience', 'Specialty']
    let rows = []
    for (let i = 0; i < keys.length; i++) {
      rows.push({
        label: labels[i],
        value:
          profileData != null
            ? profileData[keys[i]] != ''
              ? profileData[keys[i]]
              : 'N/A'
            : 'N/A',
      })
    }

    if(profileData != null){
      let ratingVal = parseFloat((profileData?.rating?.average ?? 0).toFixed(2))
      setRating(ratingVal)
      setRatingColor("#f5b342")
      console.log("Rating, including color, set: "+ ratingVal)
    }

    return rows
  }, [profileData])

  /*
  const logoutWithSpinner = () => {
    loaderDialog();
    await logoutCurrentUser();
  }*/

  return (
    <div className="sidebar">
      <div className="logout_edit_btns">
        <div className="edit_button logout_button">
          <button
            style={{ paddingRight: 0 }}
            onClick={async () => {
              setProfileData(null)
              setUserPrefData(undefined)
              deleteAll()
              showLoaderDialog()
              await logoutCurrentUser()
              unSetLoaderDialog()
              navigate('/auth')
            }}
          >
            <img src={LogoutIcon} />
            <span>Log Out</span>
          </button>
        </div>
        <div className="edit_button">
          <button onClick={() => navigate('/profile/edit')}>
            <img src={pen} />
            <span>Edit Profile</span>
          </button>
        </div>
      </div>
      <div className="profile">
        <div className="profUp">
          <div className="profimg">
            <div>
              <img
                src={
                  profileData != null
                    ? getProfilePictureURL(profileData['profilePictureURL'])
                    : profile
                }
                alt="profile"
              />
            </div>
          </div>
          <div className="profName">
            <span>
              {profileData != null
                ? `${profileData['firstName']} ${profileData['lastName']}`
                : ''}
            </span>
          </div>
          <div className="profCountry">
            <span>{profileData != null ? profileData['location'] : ''}</span>
          </div>
          <div className="ratingStars">
            <Rating
              name="ratingBar"
              defaultValue={5}
              value={rating}
              precision={0.1}
              emptyIcon={<FontAwesomeIcon icon={faStar} />}
              icon={<FontAwesomeIcon icon={faStar} style={{color: ratingColor,}}/>}
              readOnly
            />

            <div>
              { profileData != null ? profileData?.rating?.average.toFixed(1) : 0}
            </div>

            <div>
              ({ profileData != null ? (profileData?.rating?.count ?? 0) : 0} reviews)
            </div>

          </div>
          {/*<div className="profV">
            <span>{tours.length} tours </span>
          </div>*/}
          <div></div>
        </div>
        <div className="divider"> </div>
        <div className="profDown">
          <div className="info">
            {userDetails.map((row, index) => (
              <div>
                <Fragment key={`row_${index}`}>
                  <div className="L_info">
                    <span>{row.label}</span>
                  </div>
                  <div className="R_info">
                    <span>{row.value}</span>
                  </div>
                </Fragment>
              </div>
            ))}

            {/* <div className="L_info">
              <span>Occupation</span>
            </div>
            <div className="R_info">
              <span>
                {profileData != null
                  ? profileData["occupation"] != ""
                    ? profileData["occupation"]
                    : "N/A"
                  : "N/A"}
              </span>
            </div>

            <div className="L_info">
              <span>Tourism Experience</span>
            </div>
            <div className="R_info">
              <span>
                {profileData != null
                  ? profileData["tourismExperience"] != ""
                    ? profileData["tourismExperience"]
                    : "N/A"
                  : "N/A"}
              </span>
            </div>

            <div className="L_info">
              <span>Specialty</span>
            </div>
            <div className="R_info">
              <span>
                {profileData != null
                  ? profileData["specialty"] != ""
                    ? profileData["specialty"]
                    : "N/A"
                  : "N/A"}
              </span>
            </div> */}
          </div>

          <div className="bio">
            <div className="bioH">
              <span>Biography</span>
            </div>
            <p>
              {profileData != null
                ? profileData['biography'] != ''
                  ? profileData['biography']
                  : 'None'
                : 'None'}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserDataSidePanel
