import React, { useState, useEffect, useRef } from 'react'
import { useAuth } from '../Contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import { Routes as DomRoutes, Route, useLocation } from 'react-router'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import './routes.css'

import Navbar from '../Components/Navbar/Navbar'
import Auth from './../Components/Auth/Auth'
import ForgotPassword from '../Components/ForgotPassword/ForgotPassword'
import ResetPassword from '../Components/ForgotPassword/ResetPassword'
import Dashboard from './../Components/Dashboard/Dashboard'
import TourBuilder from '../Components/TourBuilder/TourBuilder'
import Analytics from './../Components/Analytics/Analytics'
import EditProfile from './../Components/EditProfile/EditProfile'
import Settings from './../Components/Settings/Settings'

const Routes = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  let location = useLocation()
  const [isNavbarSolid, setNavbarSolid] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      setNavbarSolid(window.scrollY > 0)
    })
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0 })
    if (
      location.pathname.includes('tourist') ||
      location.pathname.includes('guide')
    ) {
      document.title = 'InsidARTours'
    } else {
      document.title = 'InsidARTours - Tour Builder'
    }
  }, [location.pathname])

  useEffect(() => {
    if (location.pathname != '/tourist' && location.pathname != '/guide') {
      if (currentUser !== undefined) {
        if (currentUser === null) {
          if (
            location.pathname != '/auth' &&
            location.pathname != '/forgot_password' &&
            location.pathname != '/reset_password'
          ) {
            navigate('/auth')
          }
        } else if (currentUser !== null) {
          if (
            location.pathname == '/auth' ||
            location.pathname == '/forgot_password' &&
            location.pathname != '/reset_password'
          ) {
            navigate('/')
          }
        }
      } else if (currentUser === undefined && currentUser === null) {
        navigate('/auth')
      }
    }
  }, [currentUser])

  return (
    <div>
      {location.pathname != '/auth' &&
      location.pathname != '/forgot_password' && 
      location.pathname != '/reset_password' &&
      location.pathname != '/tourist' &&
      location.pathname != '/guide' ? (
        <Navbar />
      ) : (
        // location.pathname.includes("home") ?
        //   <NavHome isSolid={isNavbarSolid} />
        // :
        <span></span>
      )}
      <TransitionGroup component="div">
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <DomRoutes location={location}>
            <Route exact path="/auth" element={<Auth />} />
            <Route exact path="/forgot_password" element={<ForgotPassword />} />
            <Route exact path="/" element={<Dashboard />} />
            <Route
              exact
              path="/builder"
              element={<TourBuilder isEdit={false} />}
            />
            <Route
              exact
              path="/builder/edit/:id"
              element={<TourBuilder isEdit={true} />}
            />
            <Route exact path="/analytics" element={<Analytics />} />
            <Route exact path="/profile/edit" element={<EditProfile />} />
            <Route exact path="/settings" element={<Settings />} />
            <Route exact path="/reset_password" element={<ResetPassword />} />
            <Route path="*" element={<Dashboard />} />
          </DomRoutes>
        </CSSTransition>
      </TransitionGroup>
    </div>
  )
}

export default Routes
