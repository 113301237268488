import React from 'react'
import styled from 'styled-components'
import useInput from './useInput'
import searchIcon from '../../assets/imgs/search.svg'
import { useState, useEffect } from 'react'


const SearchField = ({ search, onBlur }) => {
  const address = useInput('')

  const [selection, setSelection] = useState('')
  const [blurEvent, setBlurEvent] = useState(false)


  useEffect(() => {
    console.log("selection: ",selection)
    if (selection != ''){
      address.setValue(selection)
      address.setSuggestions([])
      search(selection)
      setSelection('')
    }
  }, [selection])

  return (
    <Wrapper onBlur={() => {
          if(selection != ''){
            onBlur()
          }else{
            setTimeout(onBlur.bind(this), 200)
          }
        }}>
      <Input
        placeholder="Search"
        {...address}
        isTyping={address.value !== ''}
        autoFocus
      />
      <img
        onClick={() => search(address.value)}
        style={{
          position: 'absolute',
          width: '25px',
          right: '15px',
          top: '14px',
        }}
        src={searchIcon}
      />
      {address.suggestions?.length > 0 && (
        <SuggestionWrapper>
          {address.suggestions.map((suggestion, index) => {
            return (
              <Suggestion
                key={index}
                onClick={() => setSelection(suggestion.place_name)}
                /*
                onClick={() => {
                  address.setValue(suggestion.place_name)
                  address.setSuggestions([])
                  search(suggestion.place_name)
                }}*/
              >
                {suggestion.place_name}
              </Suggestion>
            )
          })}
        </SuggestionWrapper>
      )}
    </Wrapper>
  )
}

export default SearchField

const Wrapper = styled.div`
  font-family: Montserrat,
  padding: 0px 5px 5px 5px;
  margin: 0 auto;
`

const Input = styled.input`
  width: 24.8rem;
  border: none;
  background: #e9edf0;
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78),
    inset 6px 6px 16px rgba(0, 0, 0, 0.15);
  padding: 10px 20px;
  border-radius: 30px;
  position: relative;
  display: grid;
  justify-self: center;
  &:focus {
    outline: none;
  }
`
/* focus: border-radius: ${(props) => props.isTyping && '10px 10px 0px 0px'};*/

const SuggestionWrapper = styled.div`
  color: "#5B5B5B"
  position: absolute;
  overflow-x: auto;
  padding: 5px 15px 0px 5px;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  margin-bottom: 0.2rem;
  border-radius: 0px 0px 10px 10px;

`

const Suggestion = styled.p`
  cursor: pointer;
  text-overflow: ellipsis;
  max-width: 100%;
  padding: 4px 4px 4px 2px;
  margin-right:0.3rem
  border-radius: 5px;
  &:hover {
    color: #149bd0;
    font-weight: 700;
  }
`
