import { useRef, useState } from 'react'
import {
  AR_MARKER_TYPE_TO_ICON,
  AR_MARKER_TYPE_TO_ICON_FOR_SAVING,
} from '../../Utils/BuilderUtils'
import CancelIcon from '../../assets/imgs/close_icon.png'
import { AR_MARKER_TYPES_ASSET_LINKS } from '../../constants'

import { infoToast, successToast, errorToast, warningToast } from "../Dialog/Toast";

import  { UPLOAD_SIZE_LIMIT } from '../../constants'

function ARMarkerPictureUploadDialog({
  arMarkers,
  selectedStop,
  selectedARMarker,
  selectedARMarkerType,
  arMarkerImageRef,
  addARMarker,
  updateARMarker,
  deleteARMarker,
  removeARMarker,
  showLoaderDialog,
  uploadFile,
  showDialog,
  unSetDialog,
  isVisible,
  setIsVisible,
}) {
  const arMarkerPictureUploadRef = useRef(null)

  const [newImageUploaded, setNewImageUploaded] = useState(false)
  const [updationLock, setUpdationLock] = useState(false)

  const isImageFile = (file) => {
    const allowedTypes = ["image/png", "image/jpg", "image/jpeg"];

    return allowedTypes.includes(file.type);
  };

  const uploadImageDynamic = async (e) => {
    const file = e.target.files[0];

    if (file && isImageFile(file)) {
      let result_base64 = await new Promise((resolve) => {
        var reader = new window.FileReader()
        reader.onload = (e) => resolve(reader.result)
        reader.readAsDataURL(e.target.files[0])
      })

      let fileSize = e.target.files[0].size

      if(fileSize > UPLOAD_SIZE_LIMIT){
        errorToast("File size must be less than 30MB")
      }else{
        let base64 = result_base64

        showLoaderDialog()

        await uploadFile(
          'image',
          e.target.files[0].name,
          base64.split(',')[1],
          (url, identifier) => {
            unSetDialog()
            arMarkerImageRef.current.setAttribute('src', url)
            arMarkerImageRef.current.setAttribute('data-name', identifier)

            setNewImageUploaded(true)

            successToast("AR Marker picture added sucessfully.")
            unSetDialog()
          },
        )
      }
      
      e.target.value = null
    } else {
      errorToast('Please select a valid image file.');
    }
  }


  const getImageBasic = () => {

    if (!newImageUploaded){
      let url = AR_MARKER_TYPES_ASSET_LINKS[selectedARMarkerType]['url']
      let identifier = AR_MARKER_TYPES_ASSET_LINKS[selectedARMarkerType]['identifier']

      arMarkerImageRef.current.setAttribute('src', url)
      arMarkerImageRef.current.setAttribute('data-name', identifier)
    }

  }

  const handleUpdation = () => {
    setUpdationLock(true); 
    updateARMarker({
      img: arMarkerImageRef.current.src,
      imgName: arMarkerImageRef.current.getAttribute('data-name'),
    }); 
    setUpdationLock(false);
  }

  const handleCreation = () => {
    if (!updationLock){
      getImageBasic(); 
      addARMarker(); 
      setNewImageUploaded(false);
    }
  }

  return (
    <div
      id="arMarkerPictureDialog"
      style={{ display: isVisible ? '' : 'none' }}
    >
      <div className="dialog_bg" onClick={() => setIsVisible(null)}></div>
      <div id="arMarkerPictureDialogBody"
           style={selectedARMarker !== '' ? { width : '40em' } : {}}>
        <div className="dialog_header">
          <h3>
            {selectedARMarker === '' ? (
              <> Create AR Marker </>
            ) : (
              <> Edit AR Marker </>
            )}
          </h3>
          <img
            src={CancelIcon}
            alt="Close Icon"
            onClick={() => {
              setIsVisible(false)
            }}
          />
        </div>
        <div className="img_container_2">
          <img
            ref={arMarkerImageRef}
            src={
              selectedARMarker !== '' &&
              arMarkers[selectedStop] &&
              arMarkers[selectedStop][selectedARMarker] &&
              arMarkers[selectedStop][selectedARMarker]['assetLink'] !==
                undefined
                ? arMarkers[selectedStop][selectedARMarker]['assetLink']
                : AR_MARKER_TYPE_TO_ICON[selectedARMarkerType]
            }
          />
          <input
            ref={arMarkerPictureUploadRef}
            type="file"
            onChange={(e) => {uploadImageDynamic(e)}}
            style={{ display: 'none' }}
            accept="image/png, image/gif, image/jpeg"
          />
        </div>

        <div className="action_buttons_3">
          {selectedARMarker !== '' ? (
            <button
              className="save_btn delete_button"
              style={{ marginLeft: '0' }}
              onClick={() => {
                showLoaderDialog()

                const imgName =
                  'assetName' in arMarkers[selectedStop][selectedARMarker]
                    ? arMarkers[selectedStop][selectedARMarker]['assetName']
                    : ''

                const modelName =
                  'modelName' in arMarkers[selectedStop][selectedARMarker]
                    ? arMarkers[selectedStop][selectedARMarker]['modelName']
                    : ''

                deleteARMarker(imgName, modelName, () => {
                  removeARMarker()
                })
              }}
            >
              Delete
            </button>
          ) : (
            ''
          )}

          <button className="save_btn replace_button" 
                  onClick={() => arMarkerPictureUploadRef.current.click()}>
            Replace Photo
          </button>

          <button
            className="save_btn confirm_button_2"
            onClick={() => {
              showLoaderDialog()

              selectedARMarker !== ''
                ? handleUpdation()
                : handleCreation()
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  )
}

export default ARMarkerPictureUploadDialog
