import React, { forwardRef, useState, useEffect, useRef } from 'react'
import { useAuth } from '../../Contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import { useDialog } from '../../Contexts/DialogContext'
import TableData from '../TableData/TableData'
import BarGraph from './BarGraph'
import axios from 'axios'
import {
  READ_ANALYTICS,
  READ_USER_BALANCE,
  HANDLE_CASHOUT,
  READ_GUIDE_TOURS,
  READ_GUIDE_REVIEWS,
  READ_EXCHANGE_RATES,
  FIREBASE_API_KEY,
} from '../../constants'
import './styles/analytics.css'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import CashOutDialog from './CashOutDialog'
import InputWidget from '../InputWidget/InputWidget'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { infoToast, successToast, errorToast, warningToast } from "../Dialog/Toast";

function Analytics() {
  const navigate = useNavigate()
  const {setDialog, unSetDialog } = useDialog()
  const {currentUser, idToken } = useAuth()
  const [earnings, setEarnings] = useState({})
  const [earningRange, setEarningRange] = useState([])
  const [clicks, setClicks] = useState({})
  const [clickRange, setClickRange] = useState([])
  const [sales, setSales] = useState([])
  const isLoading = useRef([true, true])
  const [startDate, setStartDate] = useState(new Date().setMonth((new Date()).getMonth() - 1))
  const [endDate, setEndDate] = useState(new Date())
  const [USDExchangeRates, setUSDExchangeRates] = useState({})
  const [analyticsData, setAnalyticsData] = useState({})
  const [tourSubAnalyticsData, setTourSubAnalyticsData] = useState({})
  const [revenue, setRevenue] = useState(null)
  const [withdrawn, setWithdrawn] = useState(null)
  const [balance, setBalance] = useState(null)
  const [granularity, setGranularity] = useState("day")
  const [baseError, setBaseError] = useState(false)

  const [isCashOutDialogVisible, setIsCashOutDialogVisible] = useState(false)

  const [tourIds, setTourIds] = useState(null)
  const [ratings, setRatings] = useState({})

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  const fetchUSDExchangeRatesAndThen = async (callbackFunction, extraArg) => {
    await axios
      .get(READ_EXCHANGE_RATES)
      .then(async (exchangeRates) => {
        setUSDExchangeRates(exchangeRates["data"]["data"])
        try {
          await callbackFunction(exchangeRates["data"]["data"], extraArg);
        } catch (e) {
          console.log("Prob in co: ", e);
          throw e;
        }
      })
      .catch((e)=>{
        console.log("Prob in parent: ",e)
        throw e;
      })
  }

  const fetchReviews = async () => {
    let aggregatedRatings = {}

    const requests = []
    for(const i in tourIds){
      requests.push(axios
      .get(READ_GUIDE_REVIEWS, {
        params: {
          tourId: tourIds[i],
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${idToken}`,
        },
      }))
    }

    Promise.all(requests)
    .then((responses) => {
      for(const i in responses){
        const reviewsData = responses[i]
        const reviews = reviewsData.data.data.reviews

        let numReviews = reviews.length
        let runningSum = 0
        for(const i in reviews){
          runningSum += parseFloat(reviews[i]["rating"])
        }

        let avgRating = ((runningSum / (numReviews * 5))*5).toFixed(1)

        aggregatedRatings[tourIds[i]] = {"rating": avgRating, "count": numReviews}
      }

      setRatings(aggregatedRatings)
    })
    .catch((error) => {
      console.error('Some requests failed:', error);
    })
  }

  const fetchGuideToursAnalytics = async (exchangeRates, tourIds) => {
    const oneDay = 24 * 60 * 60 * 1000; //in milliseconds

    let startTS = typeof startDate === 'number' ? ((startDate - oneDay) / 1000).toFixed(0) : ((startDate.getTime() - oneDay)/ 1000).toFixed(0)
    let endTS = typeof endDate === 'number' ? endDate : (endDate.getTime() / 1000).toFixed(0)

    console.log("TS's's: " + startTS + "  --- " + endTS)

    console.log("Tour ids: ",tourIds)

    axios
      .post(READ_ANALYTICS, {
          startTimestamp: startTS,
          endTimestamp: endTS,
          tourIds: tourIds.join(','),
          granularity: "day"
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${idToken}`,
          },
        }
      ).then((res) => {
        if (res.status == 200) {
          if (res.data['status'] != 'error') {
            console.log("Tours analytics data: "+JSON.stringify(res.data['data']))
            setTourSubAnalyticsData(res.data['data'])
            populateGraph(res.data, exchangeRates)
          }
          else{
            console.log("Error fetching analytics for tours")
          }
        } else {
          console.log("Error fetching analytics for tours")
        } 
      })
      .catch((e) => {
        console.log("Error fetching analytics for tours: ",e.response)
        throw e;
      });
  }

  const fetchBalance = async ({closeLoaderOnEnd}) => {
    const token = await currentUser.getIdToken()

    axios
      .get(READ_USER_BALANCE, {
        params: {
          tourGuideId: currentUser.uid,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`,
        },
      })
      .then((balanceData) => {
        if (balanceData.status == 200) {
          let balance = 0;

          if (balanceData.data.data) {
            balance = balanceData.data.data.toFixed(1)
          }

          console.log("BALANCE::: ",balance,currentUser.uid)

          setBalance(balance)
          //setWithdrawn(balance > revenue ? 0 : (revenue - balance).toFixed(1))

          if(closeLoaderOnEnd == true){
            unSetDialog()
          }
        } else{
          console.log("Balance Fetch Error: ",balanceData)
          showDialog('error', 'Failed to fetch user balance. Please try again.', () => {
            unSetDialog()

            navigate('/')
          })
        }
      })
      .catch((e) => {
        console.log("Balance Fetch Error: ",e)
        showDialog('error', 'Failed to fetch user balance. Please try again.', () => {
          unSetDialog()

          navigate('/')
        })
      })
  }

  const fetchBaseGuideTours = async (exchangeRates) => {
    const token = await currentUser.getIdToken()

    axios
      .get(READ_GUIDE_TOURS, {
        params: {
          apikey: FIREBASE_API_KEY,
          uid: currentUser.uid,
          userType: "guide"
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        console.log("guide id: ",currentUser.uid)

        if (res.status == 200) {
          if (res.data['status'] != 'error') {
            const tours = res.data.data

            console.log("Tours: "+ JSON.stringify(tours))

            let saleData = []
            let runningRevenue = 0
            let tourIDList = []

            for(let i in tours){
              if (tours[i].hasOwnProperty("published") && tours[i]["published"] == true){
                tourIDList.push(tours[i]["tourId"])

                let moneyEarned = ((tours[i]['tourPurchased'] ?? 0) * ((tours[i]['tourPrice'] ?? 0) / 
                      (exchangeRates["data"] 
                        ? exchangeRates["data"]["rates"][tours[i]['tourCurrency'].toUpperCase()] 
                          ? exchangeRates["data"]["rates"][tours[i]['tourCurrency'].toUpperCase()]
                          : NaN
                        : NaN)).toFixed(1)).toFixed(1)

                saleData.push({
                  name: tours[i]["name"],
                  //tourClicked: tours[i]['tourClicked'] ?? 0,
                  tourPurchased: "...",
                  uniqueCustomers: "??",
                  tourPrice: "$" + ((tours[i]['tourPrice'] ?? 0) / (exchangeRates["data"] 
                                                                      ? exchangeRates["data"]["rates"][tours[i]['tourCurrency'].toUpperCase()] 
                                                                        ? exchangeRates["data"]["rates"][tours[i]['tourCurrency'].toUpperCase()] 
                                                                        : NaN
                                                                      : NaN)).toFixed(1),
                  //tourPrice: "...",
                  //moneyEarned: "$" + moneyEarned,
                  //tourPurchased: tours[i]['tourPurchased'] ?? 0,
                  moneyEarned: "...",
                  tourPurchased: "...",
                  tourId: tours[i]["tourId"],
                  coverImage: tours[i]['picturesURLs'] ?
                                  tours[i]['picturesURLs'].length > 0 
                                    ? tours[i]['picturesURLs'][0]["link"]
                                    : "../../assets/imgs/error_icon.png"
                                  : "../../assets/imgs/error_icon.png",
                })

                runningRevenue += parseFloat(moneyEarned)
              }
            }

            setSales(saleData)
            setTourIds(tourIDList)

            //unSetDialog()
          }
        }
      })
  }

  const populateGraph = (data, exchangeRates) => {
    let totalRevenue = 0;

    let updatedEarnings = {};
    let updatedClicks = {};

    for (const tourId of tourIds) {
      if (data['data'][tourId]) {
        for (const timeslot in data['data'][tourId]) {
          if (!updatedEarnings[timeslot]) {
            updatedEarnings[timeslot] = { tourCustomers: {}, moneyWithdrawn: 0 };
          }

          Object.assign(updatedEarnings[timeslot].tourCustomers, data['data'][tourId][timeslot].tourCustomers);
          updatedEarnings[timeslot].moneyWithdrawn += data['data'][tourId][timeslot].moneyWithdrawn || 0;

          if (!updatedClicks[timeslot]) {
            updatedClicks[timeslot] = 0;
          }

          updatedClicks[timeslot] += data['data'][tourId][timeslot].tourClicked || 0;
        }
      }
    }

    const timeslots = [];
    let tourCustomers = {};
    let clicksData = {}

    let moneyWithdrawn = 0.0;

    if (granularity == "day"){
      for (const timeslot in updatedEarnings) {
        console.log("TSlot: ", timeslot);
        timeslots.push(timeslot);

        const tourCustomersList = updatedEarnings[timeslot].tourCustomers;
        if (tourCustomersList) {
          tourCustomers[timeslot] = tourCustomersList;
        }

        const withdrawnDuringPeriod = updatedEarnings[timeslot].moneyWithdrawn;
        console.log("TSlot W: ", timeslot, " ", withdrawnDuringPeriod);
        if (withdrawnDuringPeriod) {
          moneyWithdrawn += parseFloat(withdrawnDuringPeriod);
        }
      }

      clicksData = updatedClicks;
    } else {
      let uniqueMonthSlots = new Set();

      for (const timeslot in updatedEarnings) {
        const [year, month] = timeslot.split('-');
        const monthSlot = `${year}-${month}`;
        uniqueMonthSlots.add(monthSlot);
      }

      uniqueMonthSlots = Array.from(uniqueMonthSlots);
      
      for (const monthSlot of uniqueMonthSlots) {
        timeslots.push(monthSlot);

        for (const timeslot in updatedEarnings) {
          const [year, month] = timeslot.split('-');
          if (`${year}-${month}` === monthSlot) {
            const tourCustomersList = updatedEarnings[timeslot].tourCustomers;

            if (tourCustomersList) {
              if (!tourCustomers[monthSlot]) {
                tourCustomers[monthSlot] = {};
              }
              Object.assign(tourCustomers[monthSlot], tourCustomersList);
            }

            const withdrawnDuringPeriod = updatedEarnings[timeslot].moneyWithdrawn;
            if (withdrawnDuringPeriod) {
              moneyWithdrawn += parseFloat(withdrawnDuringPeriod);
            }

            if(clicksData[monthSlot]){
              clicksData[monthSlot] += parseFloat(updatedClicks[timeslot]) || 0;
            } else {
              clicksData[monthSlot] = parseFloat(updatedClicks[timeslot]) || 0;
            }

            console.log("TCs: ", tourCustomers);
          }
        }
      }
    }

    if(timeslots.length == 0){
      setEarnings({})
      setEarningRange([])
      setClicks({})
      setClickRange([])
      setRevenue(0.0)
      //setWithdrawn(0.0)

      unSetDialog();
    }else{
      let maxEarning = 0;
      let formattedEarnings = {};

      for (const timeslot in tourCustomers) {
        let earningInTimeslot = 0
        for (const subTimeslot in tourCustomers[timeslot]){
          const amountPaid = tourCustomers[timeslot][subTimeslot]["amountPaid"]
          const currency = tourCustomers[timeslot][subTimeslot]["currency"]

          //console.log("Analytics datum: "+subTimeslot+" : "+currency+" : "+amountPaid+" ::: ")

          const earning = (amountPaid / (exchangeRates["data"]
                                            ? exchangeRates["data"]["rates"][currency.toUpperCase()]
                                                ? exchangeRates["data"]["rates"][currency.toUpperCase()]
                                                : NaN
                                            : NaN)).toFixed(1)

          totalRevenue += parseFloat(earning)

          earningInTimeslot += parseFloat(earning)
        }

        formattedEarnings[timeslot] = earningInTimeslot

        if(earningInTimeslot > maxEarning){
          maxEarning = earningInTimeslot
        }
      }

      maxEarning = Math.ceil(maxEarning / 100) * 100;

      let updatedEarningRange = []
      for (let i = 0; i < 6; i++) {
        updatedEarningRange.push((i * (maxEarning / 5)).toFixed(0))
      }

      //console.log("Analytics datum: "+maxEarning+" : "+JSON.stringify(formattedEarnings)+" : "+updatedEarningRanges+" <> ")

      setRevenue(totalRevenue.toFixed(1))
      //setWithdrawn(moneyWithdrawn.toFixed(1))
      setEarnings(formattedEarnings)
      setEarningRange(updatedEarningRange.reverse())

      let maxClicks = 0;

      for (const timeslot in clicksData) {
        if(clicksData[timeslot] > maxClicks){
          maxClicks = clicksData[timeslot]
        }
      }

      let updatedClickRange = []
      for (let i = 0; i < 6; i++) {
        updatedClickRange.push((i * (maxClicks / 5)).toFixed(0))
      }

      setClicks(clicksData);
      setClickRange(updatedClickRange.reverse());

      unSetDialog();
    }
  }

  const fetchBaseAnalytics = async (exchangeRates) => {
    const oneDay = 24 * 60 * 60 * 1000; //in milliseconds

    let startTS = typeof startDate === 'number' ? ((startDate - oneDay) / 1000).toFixed(0) : ((startDate.getTime() - oneDay)/ 1000).toFixed(0)
    let endTS = typeof endDate === 'number' ? endDate : (endDate.getTime() / 1000).toFixed(0)

    //console.log("TS's's: " + startTS + "  --- " + endTS)

    axios
      .post(READ_ANALYTICS, {
          tourGuideId: currentUser.uid,
          startTimestamp: startTS,
          endTimestamp: endTS,
          granularity: "day"
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${idToken}`,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          if (res.data['status'] != 'error') {
            //console.log("Analytics data: "+JSON.stringify(res.data['data']))

            setAnalyticsData(res.data['data'][currentUser.uid])

            let totalRevenue = 0;

            let updatedEarnings = res.data['data'][currentUser.uid]

            const timeslots = [];
            let tourCustomers = {};

            let moneyWithdrawn = 0.0;

            if (granularity == "day"){
              for (const timeslot in updatedEarnings) {
                console.log("TSlot: ", timeslot);
                timeslots.push(timeslot);
  
                const tourCustomersList = updatedEarnings[timeslot].tourCustomers;
                if (tourCustomersList) {
                  tourCustomers[timeslot] = tourCustomersList;
                }

                const withdrawnDuringPeriod = updatedEarnings[timeslot].moneyWithdrawn;
                console.log("TSlot W: ", timeslot, " ", withdrawnDuringPeriod);
                if (withdrawnDuringPeriod) {
                  moneyWithdrawn += parseFloat(withdrawnDuringPeriod);
                }
                
              }
            } else {
              let uniqueMonthSlots = new Set();
  
              for (const timeslot in updatedEarnings) {
                const [year, month] = timeslot.split('-');
                const monthSlot = `${year}-${month}`;
                uniqueMonthSlots.add(monthSlot);
              }

              uniqueMonthSlots = Array.from(uniqueMonthSlots);
              
              for (const monthSlot of uniqueMonthSlots) {
                timeslots.push(monthSlot);

                for (const timeslot in updatedEarnings) {
                  const [year, month] = timeslot.split('-');
                  if (`${year}-${month}` === monthSlot) {
                    const tourCustomersList = updatedEarnings[timeslot].tourCustomers;

                    if (tourCustomersList) {
                      if (!tourCustomers[monthSlot]) {
                        tourCustomers[monthSlot] = {};
                      }
                      Object.assign(tourCustomers[monthSlot], tourCustomersList);
                    }

                    const withdrawnDuringPeriod = updatedEarnings[timeslot].moneyWithdrawn;
                    if (withdrawnDuringPeriod) {
                      moneyWithdrawn += parseFloat(withdrawnDuringPeriod);
                    }

                    console.log("TCs: ", tourCustomers);
                  }
                }
              }
            }

            if(timeslots.length == 0){
              //setEarnings({})
              //setEarningRanges([])
              //setRevenue(0.0)
              setWithdrawn(0.0)
            }else{
              let maxEarning = 0;
              let formattedEarnings = {};

              for (const timeslot in tourCustomers) {
                let earningInTimeslot = 0
                for (const subTimeslot in tourCustomers[timeslot]){
                  const amountPaid = tourCustomers[timeslot][subTimeslot]["amountPaid"]
                  const currency = tourCustomers[timeslot][subTimeslot]["currency"]

                  //console.log("Analytics datum: "+subTimeslot+" : "+currency+" : "+amountPaid+" ::: ")

                  const earning = (amountPaid / (exchangeRates["data"]
                                                    ? exchangeRates["data"]["rates"][currency.toUpperCase()]
                                                        ? exchangeRates["data"]["rates"][currency.toUpperCase()]
                                                        : NaN
                                                    : NaN)).toFixed(1)

                  totalRevenue += parseFloat(earning)

                  earningInTimeslot += parseFloat(earning)
                }

                formattedEarnings[timeslot] = earningInTimeslot

                if(earningInTimeslot > maxEarning){
                  maxEarning = earningInTimeslot
                }
              }

              maxEarning = Math.ceil(maxEarning / 100) * 100;

              let updatedEarningRange = []
              for (let i = 0; i < 6; i++) {
                updatedEarningRange.push((i * (maxEarning / 5)).toFixed(0))
              }

              //console.log("Analytics datum: "+maxEarning+" : "+JSON.stringify(formattedEarnings)+" : "+updatedEarningRanges+" <> ")

              //setRevenue(totalRevenue.toFixed(1))
              setWithdrawn(moneyWithdrawn.toFixed(1))
              //setEarnings(formattedEarnings)
              //setEarningRanges(updatedEarningRanges.reverse())

              setBaseError(false);
            }

          } else {
            console.log("Error 1.2")
          }
        } else {
          console.log("Error 2.1")
        }
      })
      .catch((e) => {
        setBaseError(true);
        console.log("Error fetching analytics: ",e)
        throw e;
      })
  }

  useEffect(() => {
    if(tourIds){
      fetchReviews()
      fetchUSDExchangeRatesAndThen(fetchGuideToursAnalytics,tourIds);
    }

  },[tourIds])

  useEffect(() => {
    if(isNaN(Number(revenue))){
      console.log("Revx: ",revenue)
      showDialog('error', 'Failed to fetch exchange rates data. Please try again.', () => {
        unSetDialog()
        navigate('/')
      })
    }else{
      console.log("Rev: ",revenue,isNaN(Number(revenue)))
    }

  }, [revenue])
  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        setRevenue(null);
        setSales([]);
        setTourSubAnalyticsData({});
        showLoaderDialog();
        try {
          await Promise.all([
            fetchUSDExchangeRatesAndThen(fetchBaseAnalytics).catch(err => {
              console.error('Error in fetchBaseAnalytics:', err);
              showDialog('error', 'An error occurred while fetching analytics data.', () => {
                unSetDialog()
                navigate('/')
              })
            }),
            fetchUSDExchangeRatesAndThen(fetchBaseGuideTours).catch(err => {
              console.error('Error in fetchBaseGuideTours:', err);
              showDialog('error', 'An error occurred while fetching analytics data.', () => {
                unSetDialog()
                navigate('/')
              })
            }),
            fetchBalance({ closeLoaderOnEnd: false }).catch(err => {
              console.error('Error in fetchBalance:', err);
              showDialog('error', 'An error occurred while fetching balance.', () => {
                unSetDialog()
                navigate('/')
              })
            })
          ]);
        } catch (err) {
          console.error('Unexpected error:', err);
          showDialog('error', 'An unexpected error occurred while fetching analytics data.', () => {
            unSetDialog()
            navigate('/')
          })
        }
      }
    };

    if (baseError) {
      console.log('Base error detected, re-executing fetchData...');
      fetchData();
    } else {
      fetchData();
    }
  }, [currentUser,granularity,startDate,endDate,baseError])

  const showLoaderDialog = () => {
    let dialog = {
      isLoader: true,
    }

    setDialog(dialog)
  }

  const showDialog = (type, message, btnAction) => {
    let dialog = {
      type: type,
      message: message,
      btnTxt: 'OK',
      btnAction: btnAction == undefined ? () => unSetDialog() : btnAction,
    }

    setDialog(dialog)
  }

  const handleCashout = async (amount) => {

    if(amount < 20){
      errorToast("Minimum withdrawal amount is $20.")
      return false;
    }

    if(amount > parseFloat(balance)){
      errorToast("Requested amount exceeds your balance.")
      return false;
    }

    showLoaderDialog()

    let success = false;

    await axios
      .put(HANDLE_CASHOUT, {
          tourGuideId: currentUser.uid,
          amount: amount,
          currency: "USD",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${idToken}`,
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          success = true;
        } else {
          errorToast("Failed to withdraw specified amount. Please try again.")
          console.log("Cashout error: ",res)
        }
      })
      .catch((e) => {
        errorToast("Failed to withdraw specified amount. Please try again.")
        console.log("Cashout error: ",e)
      });

      if(success){
        await fetchBalance({closeLoaderOnEnd : true});

        setWithdrawn((prev) => {
          const newAmount = parseFloat(parseFloat(prev) + parseFloat(amount)).toFixed(1)
          console.log("New amount: ", newAmount);
          return newAmount;
        })
        successToast("Funds withdrawn successfully.")
        return true;
      }else{
        return false;
      }
  }

  const DateSelectorButton = forwardRef(({ value, onClick }, ref) => (
    <button className="dateSelectorButton" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  return (
    <div id="Analytics">
      <div className="dateSelection">
        <div className="basicText"> Showing data from </div>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          showYearDropdown
          dateFormat="MMMM d, yyyy"
          customInput={<DateSelectorButton />}
        />
        <div className="basicText"> to </div>
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          showYearDropdown
          dateFormat="MMMM d, yyyy"
          customInput={<DateSelectorButton />}
        />
      </div>
      <div className="CashStats">
        <div className="MoneyBar">
          <div className="headingText">
            <span>Revenue</span>
          </div>
          <div className="subText">
            <span>(in selected period)</span>
          </div>
          <div>
            <span className="figures">${revenue ? revenue : "0"}</span>
          </div>
        </div>
        <div className="MoneyBar">
          <div className="headingText">
            <span>Withdrawn</span>
          </div>
          <div className="subText">
            <span>(in selected period)</span>
          </div>
          <div>
            <span className="figures">${revenue ? withdrawn : "0"}</span>
          </div>
        </div>
        <div className="MoneyBar">
          <div className="headingText">
            <span>Balance</span>
          </div>
          <div className="subText">
            <span>(in selected period)</span>
          </div>
          <div>
            <span className="figures">${revenue ? (revenue - withdrawn).toFixed(1) : "0"}</span>
          </div>
        </div>
        <div className="MoneyBar MoneyBarFinal">
          <div>
            <span>Current Balance</span>
          </div>
          <div>
            <span className="figures">${balance}</span>
          </div>
        </div>
        <div className="cashout">
          <div>
            <button /*onClick={()=>{handleCashout(10)}}*/
              onClick={()=>{setIsCashOutDialogVisible(true)}}>
                Initiate Cashout
            </button>
          </div>
        </div>
      </div>

      <BarGraph earnings={earnings} earningRange={earningRange} clicks={clicks} clickRange={clickRange} granularity={granularity} setGranularity={setGranularity} startTimestamp={startDate} endTimestamp={endDate}/>

      <div className="Graph">
        {/*<div className="GraphH" style={{ paddingLeft: '20px' }}>
          <span>Sales Breakdown</span>
        </div>*/}
        <div className="Gheadings">
          <div> Breakdown </div>
          <div></div>
          <div>
            <span>Tour Name</span>
          </div>
          <div>
            <span>Clicks</span>
          </div>
          <div>
            <span>Avg Unit Price</span>
          </div>
          <div>
            <span>Purchases</span>
          </div>
          <div>
            <span>Money Earned</span>
          </div>
          <div>
            <span>Rating</span>
          </div>
        </div>
        <div className="tabs">
          {sales.map((tourDetails, i) => {
            let subAnalytics;

            if(tourSubAnalyticsData){
              subAnalytics = tourSubAnalyticsData[tourDetails.tourId]
            }

            return <TableData index={i} tourDetails={tourDetails} subAnalytics={subAnalytics} ratings={ratings}/>
          })}
        </div>
      </div>

      <CashOutDialog
        showDialog={showDialog}
        unSetDialog={unSetDialog}
        isVisible={isCashOutDialogVisible}
        setIsVisible={(visibility) => setIsCashOutDialogVisible(visibility)}
        handleCashout={handleCashout}
      />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

    </div>
  )
}

export default Analytics