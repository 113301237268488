import React, { useContext, useEffect, useState } from 'react'
import { auth } from '../firebase'
import {
  signInWithEmailAndPassword,
  signInWithCustomToken,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth'

const WarningOnNavigateContext = React.createContext()

export function useWarningOnNavigate() {
  return useContext(WarningOnNavigateContext)
}

export function WarningOnNavigateProvider({ children }) {
  const [isShowWarning, setIsShowWarning] = useState()

  const value = {
    isShowWarning,
    setIsShowWarning,
  }

  return (
    <WarningOnNavigateContext.Provider value={value}>
      {children}
    </WarningOnNavigateContext.Provider>
  )
}
