import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { infoToast, successToast, errorToast, warningToast } from "../Dialog/Toast";

import axios from 'axios'
import { useAuth } from '../../Contexts/AuthContext'
import { useProfile } from '../../Contexts/ProfileContext'
import { useNavigate, useParams } from 'react-router-dom'
import CreatableSelect from 'react-select/creatable'

import {
  READ_TOUR_DATA_BASIC,
  READ_AR_DATA,
  UPLOAD_MEDIA,
  DELETE_MEDIA,
  UPLOAD_AR_MEDIA,
  CREATE_TOUR_DATA,
  UPDATE_TOUR_DATA,
  CREATE_TOUR_STOP,
  DELETE_TOUR_STOP,
  CREATE_AR_DATA,
  DELETE_AR_DATA,
  UPDATE_AR_DATA,
  PUBLISH_TOUR,
  SUSPEND_TOUR,
  COUNTRY_OPTIONS,
  COUNTRY_TO_CITY_MAP,
  LANGUAGE_OPTIONS,
  TOUR_TYPE_OPTIONS,
  TOUR_MODE_OPTIONS,
  TOUR_SPECIALTY_OPTIONS,
  CURRENCY_OPTIONS,
  DELETE_TOUR, 
} from '../../constants'

import  { UPLOAD_SIZE_LIMIT, UPLOAD_SIZE_LIMIT_TEST } from '../../constants'

import { useDialog } from '../../Contexts/DialogContext'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import Select from 'react-select'
import InputWidget from '../InputWidget/InputWidget'
import StopWidget from '../StopWidget/StopWidget'

import _ from 'lodash'

import 'mapbox-gl/dist/mapbox-gl'

import AddBtnIcon from '../../assets/imgs/plus.svg'
//import {ReactComponent as AddBtnAltIcon} from '../../assets/imgs/plusAlt.svg'
import AddBtnAltIcon from '../Miscellaneous/PlusIcon'
import LockIcon from '../../assets/imgs/lock_icon.png'
import CenterIcon from '../../assets/imgs/center.svg'
import SearchIcon from '../../assets/imgs/search.svg'
import HelpIcon from '../../assets/imgs/help.svg'
import ErrorIcon from '../../assets/imgs/error_icon.png'
import WarningIcon from '../../assets/imgs/warning_icon.png'
import SuccessIcon from '../../assets/imgs/success_icon.png'

import PlusIcon from '../../assets/imgs/plus.svg'
import MinusIcon from '../../assets/imgs/minus.svg'

import { formatDist, formatDur, clipText, prettifyARMarkerType } from '../../Utils/utils'
import './styles/tourBuilder.css'

import SearchField from '../SearchPlacesField/SearchField'
import { getRoutes, initializeMap } from '../../Utils/MapUtils'
import {
  initializeBuilderForm,
  initializeTours,
  AR_MARKER_TYPE_TO_ICON,
  AR_MARKER_TYPE_TO_ICON_FOR_SAVING,
  updateMapBySearchLocation,
  errorMessages,
} from '../../Utils/BuilderUtils'
import ARObjectsContainer from './ARObjectsContainer'
import LocationSelectionDialog from './LocationSelectionDialog'
import TourAudioWidget from './TourAudioWidget'
import ImageUploadWidget from './ImageUploadWidget'
import ARMarkerPictureUploadDialog from './ARMarkerPictureDialog'
import StopPictureDialog from './StopPictureDialog'
import { useWarningOnNavigate } from '../../Contexts/WarningOnNavigateContext'

const DEFAULT_COORDINATES = { lng: 19.073991, lat: 47.50479 }

function TourBuilder(props) {
  const { currentUser, idToken, isSuperUser } = useAuth()
  const { userPrefData } = useProfile()
  const navigate = useNavigate()
  const { id: tourIDFromParam } = useParams()
  const [tourID, setTourID] = useState()
  const tourIDRef = useRef(tourID)
  tourIDRef.current = tourID

  const { setDialog, unSetDialog } = useDialog()
  const { isShowWarning, setIsShowWarning } = useWarningOnNavigate()

  const mapContainer = useRef(null)
  const map = useRef(null)
  const [mapInitialized, setMapInitialized] = useState(false)
  const markerSource = useRef({})

  //const [originalTour, setOriginalTour] = useState({})
  const originalTour = useRef()
  const [stops, setStops] = useState({})
  const [arMarkers, setARMarkers] = useState({})

  const stopsRef = useRef(stops)
  stopsRef.current = stops

  const [interfaceJustInitialized, setInterfaceJustInitialized] = useState(true)
  const [tourBuilderJustOpened, setTourBuilderJustOpened] = useState(true)
  const [justCreated, setJustCreated] = useState(false)
  const [isPublished, setIsPublished] = useState(false)
  const [isDraft, setIsDraft] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [isSuperSuspended, setIsSuperSuspended] = useState(false)

  const [tourDataHasBeenRead, setTourDataHasBeenRead] = useState(false)

  const [hideBottomRightBar, setHideBottomRightBar] = useState(false)

  const [isStopPictureDialogVisible, setIsStopPictureDialogVisible] = useState(
    false,
  )
  const stopImageRef = useRef(null)
  const stopPictureUploadRef = useRef(null)
  const [selectedStopImage, setSelectedStopImage] = useState(-1)

  const [selectedStop, setSelectedStop] = useState('')
  const [selectedARMarkerType, setSelectedARMarkerType] = useState('')
  const [
    isARMarkerPictureDialogVisible,
    setIsARMarkerPictureDialogVisible,
  ] = useState(null)

  const arMarkerImageRef = useRef(null)

  const [tourImages, setTourImages] = useState([])

  const [tourCentralAudio, setTourCentralAudio] = useState(null)
  const tourCentralAudioRef = useRef(tourCentralAudio)
  tourCentralAudioRef.current = tourCentralAudio

  const [isProcessing, setIsProcessing] = useState(false)

  const [tourDistance, setTourDistance] = useState(0)
  const tourDistanceRef = useRef(tourDistance)
  tourDistanceRef.current = tourDistance
  const [tourDuration, setTourDuration] = useState(0)
  const tourDurationRef = useRef(tourDuration)
  tourDurationRef.current = tourDuration

  const [prefCurrency, setPrefCurrency] = useState(null)
  const [chkEmpty, setChkEmpty] = useState(false)
  const [isError, setIsError] = useState(false)

  const draftTimerInitialized = useRef(false)
  const draftTimerRef = useRef(null)
  const draftLastSavedTimeRef = useRef(new Date().toLocaleTimeString())

  const [countriesCities, setCountriesCities] = useState([{label: "Select...", value: "N/A",},])

  const selectedMarkerRef = useRef(null)

  const defaultTourName = useRef(`[DRAFT] Tour ${new Date().getTime()}`)

  const [builderForm, setBuilderForm] = useState({
    name: {
      id: 'name',
      elementType: 'input',
      chkEmpty: chkEmpty,
      elementConfig: {
        type: 'text',
        name: 'name',
        placeholder: '',
      },
      value: '',
      elementLabel: 'TOUR TITLE *',
      floatingButton: null,
    },
    startLocation: {
      id: 'startLocation',
      elementType: 'input',
      chkEmpty: chkEmpty,
      elementConfig: {
        type: 'text',
        name: 'startLocation',
        placeholder: '',
      },
      value: '',
      elementLabel: 'TOUR START LOCATION',
      floatingButton: null,
    },
    endLocation: {
      id: 'endLocation',
      elementType: 'input',
      chkEmpty: chkEmpty,
      elementConfig: {
        type: 'text',
        name: 'endLocation',
        placeholder: '',
      },
      value: '',
      elementLabel: 'TOUR DESTINATION',
      floatingButton: null,
    },
    addStop: {
      id: 'addStop',
      elementType: 'button',
      chkEmpty: false,
      elementConfig: {
        type: 'button',
        name: 'addStop',
        placeholder: '',
      },
      value: '',
      elementLabel: 'ADD STOP',
      floatingButton: {
        for: 'password',
        type: 'toggle',
        buttonImage: AddBtnIcon,
      },
    },
    tourType: '',
    tourMode: '',
    tourSpecialty: '',
    tourCountry: '',
    /*tourCity: '',*/
    tourCity: {
      id: 'tourCity',
      elementType: 'input',
      chkEmpty: chkEmpty,
      elementConfig: {
        type: 'text',
        name: 'tourCity',
        placeholder: '',
        list: 'cities',
      },
      value: '',
      elementLabel: 'TOUR CITY *',
      floatingButton: null,
    },
    tourismDescription: {
      id: 'tourismDescription',
      elementType: 'textarea',
      elementConfig: {
        type: 'text',
        name: 'tourismDescription',
        placeholder: '',
      },
      value: '',
      elementLabel: 'TOUR DESCRIPTION *',
      floatingButton: null,
    },
    tourLanguage: '',
    tourCurrency: prefCurrency ? prefCurrency : '',
    tourPrice: {
      id: 'tourPrice',
      elementType: 'input',
      chkEmpty: chkEmpty,
      elementConfig: {
        type: 'text',
        name: 'tourPrice',
        placeholder: '',
      },
      value: '',
      elementLabel: 'SET A PRICE *',
      floatingButton: null,
      prefix:
        tourID !== undefined
          ? CURRENCY_OPTIONS[0]['value']
          : userPrefData
          ? userPrefData['currency']
          : CURRENCY_OPTIONS[0]['value'],
    },
  })

  const builderFormRef = useRef(builderForm)
  builderFormRef.current = builderForm

  const defaultBuilderFormData = useRef({})

  const [isLocationSetterDialogVisible, setIsLocationSetterDialogVisible] = useState(false)

  const [isSearchVisible, setIsSearchVisible] = useState(false)
  const [isSearchSlideOut, setIsSearchSlideOut] = useState(false)
  const [selectedARMarker, setSelectedARMarker] = useState('')

  const [updatedLocation, setUpdatedLocation] = useState({
    latitude: '19.073991',
    longitude: '47.504790',
  })

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: '#5B5B5B',
      padding: '10px 20px',
      backgroundColor: state.isSelected ? '#9f9f9f44' : 'none',
      cursor: state.isSelected ? 'auto' : 'pointer',
      whiteSpace: 'nowrap',
      fontSize: '0.9em',
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: '#e9edf0',
      boxShadow:
        '-6px -6px 15px rgba(255, 255, 255, 0.78), 6px 6px 16px rgba(0, 0, 0, 0.15)',
      border: 'none',
      padding: '0 10px',
      borderRadius: '8px',
      height: '52px',
    }),
    menu: (style) => ({
      ...style,
      backgroundColor: '#e9edf0',
      borderRadius: '10px',
      padding: '10px',
    }),
    indicatorSeparator: () => null,
    dropdownIndicator: (style) => ({
      ...style,
      color: '#5B5B5B',
    }),
    singleValue: (style) => ({
      ...style,
      fontSize: '0.8em',
      color: '#5B5B5B',
      fontWeight: '500',
    }),
    valueContainer: (style) => ({
      ...style,
      color: 'red',
    }),
    container: (style) => ({
      ...style,
      width: '100%',
    }),
  }

  const orderedStops = useMemo(() => {
    return Object.values(stops).sort((a, b) =>
      a.order < b.order ? -1 : a.order > b.order ? 1 : 0,
    )
  }, [stops])

  useEffect(() => {
    if (!idToken){
      showLoaderDialog()
    }else if(tourBuilderJustOpened){
      showLoaderDialog()
    }

    if((tourDataHasBeenRead || (!tourBuilderJustOpened)) && mapInitialized && interfaceJustInitialized){
      //itialization confirmation")
      unSetDialog()

      setTimeout(() => {
        setInterfaceJustInitialized(false)
      }, 270);
    }

    if (map.current) return // initialize map only once
    showLoaderDialog()

    map.current = initializeMap(mapContainer, tourID !== undefined || tourIDFromParam !== undefined, setMapInitialized)
  })

  useEffect(() => {
    if (currentUser && idToken) {
      if (tourIDFromParam != undefined && tourID == undefined) {
        setTourID(tourIDFromParam)
      } else if (tourIDFromParam == undefined) {
        updateDraftTour(true)
        setJustCreated(true)
        draftTimerInitialized.current = true
        setImmediate(() => {
          draftTimerRef.current = setTimeout(() => {
            updateDraftTour()
          }, 10000)
        })
      }
    }

    ////
    return () => {
      clearTimeout(draftTimerRef.current);
    }

  }, [tourIDFromParam, currentUser, idToken])

  useEffect(() => {
    if (userPrefData != null && 'currency' in userPrefData) {
      setPrefCurrency(
        CURRENCY_OPTIONS.filter(
          (val) => val.value === userPrefData.currency,
        )[0],
      )
    }
  }, [])

  useEffect(() => {
    showLoaderDialog()
    if (userPrefData != null) {
      const updatedBuilderForm = { ...builderForm }

      updatedBuilderForm['tourCurrency'] = CURRENCY_OPTIONS.filter(
        (val) => val.value === userPrefData.currency,
      )[0]
      updatedBuilderForm['tourPrice']['prefix'] = CURRENCY_OPTIONS.filter(
        (val) => val.value === userPrefData.currency,
      )[0]['value']
      setBuilderForm(updatedBuilderForm)
    }
  }, [userPrefData])

  const getStopByOrder = (order) => {

  }

  const markerOnClickHandler = (e) => {
    setSelectedStop('')
    selectedMarkerRef.current = e.features[0].properties.count;
    let stopId = Object.values(stops).find(stop => stop.order === selectedMarkerRef.current - 1)["id"]
    setSelectedStop(stopId)

    map.current.getSource('markers').setData({
      type: 'FeatureCollection',
      features: Object.values(stops).map((stop, i) => {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            anchor: 'bottom',
            coordinates:
              'lng' in stop['location']
                ? [stop['location']['lng'], stop['location']['lat']]
                : stop['location'],
          },
          properties: {
            id: stop['id'],
            title: stop['name'],
            count: stop['order'] + 1,
            color: stopId == stop['id'] ? 'lightgrey' : '#e9edf0',
            border: stopId == stop['id'] ? '#01A0DB' : 'white',
          },
        }
      }),
    })
  }

  const mapOnClickHandler = (e) => {
    if (selectedARMarkerType == '') {
      if (selectedStop !== '') {
        setTimeout(() => {
          console.log("Selected Marker XXX: ",selectedMarkerRef.current)
          if(selectedMarkerRef.current == null){
            updateStop(selectedStop, {
              location: {
                lng: e.lngLat['lng'],
                lat: e.lngLat['lat'],
              },
            })
          } else {
            selectedMarkerRef.current = null;
          }
        }, 300)
        
      } else {
        setTimeout(() => {
          if(selectedMarkerRef.current == null){
            addStop({
              location: {
                lng: e.lngLat['lng'],
                lat: e.lngLat['lat'],
              },
            })
          } else {
            selectedMarkerRef.current = null;
          }
        }, 300)
      }
    } else {
      if (selectedARMarker !== '') {
        updateARMarker({
          location: {
            lng: e.lngLat['lng'],
            lat: e.lngLat['lat'],
          },
        })
      } else {
        setIsARMarkerPictureDialogVisible({
          type: selectedARMarkerType,
          location: {
            lng: e.lngLat['lng'],
            lat: e.lngLat['lat'],
          },
        })
      }
    }
  }

  useEffect(() => {
    if (map) {
      map.current.on('click', mapOnClickHandler)
      map.current.on('click', 'points', markerOnClickHandler)
    }

    return () => {
      if (map) {
        map.current.off('click', mapOnClickHandler)
        map.current.off('click', 'points', markerOnClickHandler)
      }
    }
  }, [
    map,
    stops,
    selectedStop,
    arMarkers,
    selectedARMarkerType,
    updatedLocation,
    selectedARMarker,
  ])

  useEffect(() => {
    console.log("Stops changed!")
    const calculateRoute = async () => {
      const allRoutes = await getRoutes(orderedStops)

      let distance = 0
      let duration = 0

      let features = []
      for (let i = 0; i < allRoutes.length; i++) {
        distance += allRoutes[i].distance
        duration += allRoutes[i].duration

        features.push({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: allRoutes[i].coordinates,
          },
          properties: {
            distance: formatDist(allRoutes[i].distance),
            color: '#ffffff',
          },
        })
      }

      map.current.getSource('route').setData({
        type: 'FeatureCollection',
        features: features,
      })

      setTourDistance(distance)
      setTourDuration(duration)
    }

    if (orderedStops.length >= 2 && map.current && map.current.getSource('route')) {
      calculateRoute()
    }

    if (orderedStops.length >= 2 && map.current && map.current.getSource('markers')) {
      markerSource.current = {
        type: 'FeatureCollection',
        features: Object.values(stops).map((stop, i) => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Point',
              anchor: 'bottom',
              coordinates: [
                stop['location']['lng'],
                stop['location']['lat'],
              ],
            },
            properties: {
              title: stop['name'],
              count: i + 1,
              color: '#e9edf0',
              border: 'white',
            },
          }
        }),
      }

      map.current.getSource('markers').setData(markerSource.current)
    }

    //unSetDialog()

    /*setTimeout(() => {
      console.log("Routes calculation SRC inner : ",map.current.getSource('route'))
      if (orderedStops.length >= 2 && map.current && map.current.getSource('route')) {
        calculateRoute()
      }else{
        routesPaintTracker.current += 1
        console.log("Routes calculation tracker: ",routesPaintTracker.current)
      }
    }, 3000)*/

     /*else {
      if (map.current && map.current.getSource('route')) {
        map.current.getSource('route').setData({
          type: 'FeatureCollection',
          features: [],
        })
      } 
    }*/
  }, [stops, orderedStops, mapInitialized])

  useEffect(() => {
    if (tourID !== undefined && idToken !== null) {
      showLoaderDialog()

      axios
        .get(READ_TOUR_DATA_BASIC, {
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            tourId: tourID,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            let tourResponse = res.data.data

            console.log("Tour DATA BASIC: ",tourResponse)

            //setOriginalTour(tourResponse['details'])
            originalTour.current = tourResponse['details']

            console.log("Tour resp: "+JSON.stringify(tourResponse))

            setIsPublished(tourResponse['published'])
            setIsDraft(tourResponse['draft'])

            //Add check to allow legacy tours
            if ('complete' in tourResponse){
              setIsComplete(tourResponse['complete'])
            }

            if ('superSuspended' in tourResponse){
              setIsSuperSuspended(tourResponse['superSuspended'])
            }

            setCountriesCities([
              {
                label: tourResponse['details']['tourCity'],
                value: tourResponse['details']['tourCity'],
              },
            ])

            setBuilderForm(
              initializeBuilderForm(tourResponse['details'], builderForm),
            )

            const { updatedTourStops, bounds } = initializeTours(
              'stops' in tourResponse['details']
                ? tourResponse['details']['stops']
                : [],
              arMarkers,
            )

            setStops(updatedTourStops)
            setTourDistance(tourResponse['details']['tourDistance'])
            setTourDuration(tourResponse['details']['tourDuration'])

            if ('picturesURLs' in tourResponse) {
              setTourImages(tourResponse['picturesURLs'])
            }

            if ('centralAudio' in tourResponse['details']) {
              setTourCentralAudio({
                url: tourResponse['details']['centralAudio'].url,
                name: tourResponse['details']['centralAudio'].name,
                length: tourResponse['details']['centralAudio'].length,
              })
            }

            markerSource.current = {
              type: 'FeatureCollection',
              features: Object.values(updatedTourStops).map((stop, i) => {
                return {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    anchor: 'bottom',
                    coordinates: [
                      stop['location']['lng'],
                      stop['location']['lat'],
                    ],
                  },
                  properties: {
                    title: stop['name'],
                    count: i + 1,
                    color: '#e9edf0',
                    border: 'white',
                  },
                }
              }),
            }

            if (map.current.getSource('markers')) {
              map.current.getSource('markers').setData(markerSource.current)
            } /* else {
              map.current.addSource('markers', {
                type: 'geojson',
                data: markerSource,
              })

              
              map.current.addSource('route', {
                type: 'geojson',
                data: {
                  type: 'FeatureCollection',
                  features: [],
                }
              })
            } */

            if (bounds != null) {
              console.log("Bounds-work: ", bounds);
              map.current.fitBounds(bounds, { duration: 0, padding: 70 })
            }

            setTourDataHasBeenRead(true)
            setTourBuilderJustOpened(false)

            //unSetDialog()
          }
        })
        .catch((e) => {
          unSetDialog()
          console.log(e)
        })

      //Deliberate on whether or not to make this call synchronous
      axios
        .get(READ_AR_DATA, {
          headers: {
            Authorization: `Token ${idToken}`,
          },
          params: {
            tourId: tourID,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            let arMarkersResponse = res.data

            if (arMarkersResponse['data'] != null) {
              arMarkersResponse = arMarkersResponse['data']

              const arMarkerStopIDs = Object.keys(arMarkersResponse)

              for (let i = 0; i < arMarkerStopIDs.length; i++) {
                let stopARMarkers = arMarkersResponse[arMarkerStopIDs[i]]

                const arMarkerIDs = Object.keys(stopARMarkers)

                for (let j = 0; j < arMarkerIDs.length; j++) {
                  const arMarker = stopARMarkers[arMarkerIDs[j]]

                  const el = document.createElement('div')
                  el.className =
                    'ar_marker ' +
                    (arMarker['locked'] ? 'ar_marker_locked' : '')

                  const typeImg = document.createElement('img')
                  typeImg.src = AR_MARKER_TYPE_TO_ICON[arMarker['type']]
                  typeImg.className = 'ar_marker_img'

                  el.appendChild(typeImg)

                  const lockImg = document.createElement('img')
                  lockImg.src = LockIcon
                  lockImg.className = 'ar_marker_lock_icon'
                  el.appendChild(lockImg)

                  let markerLocation = [
                    arMarker['gpsCoordinates'][0],
                    arMarker['gpsCoordinates'][1],
                  ]

                  let marker = new mapboxgl.Marker(el)
                    .setLngLat(markerLocation)
                    .addTo(map.current)

                  arMarkersResponse[arMarkerStopIDs[i]][arMarkerIDs[j]][
                    'marker'
                  ] = marker
                }
              }

              setARMarkers(arMarkersResponse)
            }
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }

  }, [tourID, idToken])


  /* TODO: Review hook def */
  useEffect(() => {
    if (!draftTimerInitialized.current) {
      draftTimerRef.current = setTimeout(() => {
        draftTimerInitialized.current = true
        updateDraftTour()
      }, 10000)
    } else {
      draftTimerRef.current = setTimeout(() => {
        updateDraftTour()
      }, 30000)
    }

    // There shouldn't technically be anything to clear if the draftTimer is not initialized
    /*return () => {
      if (!draftTimerInitialized.current) {
        clearTimeout(draftTimerRef.current)
      }
    }*/

    return () => {
      if (draftTimerInitialized.current) {
        clearTimeout(draftTimerRef.current)
      }
    }
  }, [
    currentUser,
    originalTour.current,
    builderForm,
    tourCentralAudio,
    tourDistance,
    tourDuration,
    stops,
  ])

  useEffect(() => {
    return () => {
      clearTimeout(draftTimerRef.current)
      setIsShowWarning("")
    }
  }, [])

  useEffect(() => {
    if (isShowWarning && isShowWarning != '') {

      let tourDataBasic = getFormElementsValues(builderFormRef.current)
      let tourData = getLatestTourData(tourDataBasic, false)

      let tourDataCopy = JSON.parse(JSON.stringify(tourData));

      for (const key in originalTour.current) {
          if (!(key in tourDataCopy)) {
              tourDataCopy[key] = originalTour.current[key];
          }
      }

      if (originalTour.current){
        for (const stopId in originalTour.current.stops) {
          if(!originalTour.current.stops[stopId].hasOwnProperty("audioName")){
            originalTour.current.stops[stopId]["audioName"] = ""
          }

          if(!originalTour.current.stops[stopId].hasOwnProperty("audioURL")){
            originalTour.current.stops[stopId]["audioURL"] = ""
          }
        }
      }

      console.log("originalTour: ", originalTour.current)
      console.log("Default Builder Form Data: ", defaultBuilderFormData.current)
      console.log("New: ", tourDataCopy)

      if ( _.isEqual(tourDataCopy, defaultBuilderFormData.current)){
        deleteTourAndNavigate(tourID, isShowWarning)
        //navigate(isShowWarning)
      } else if (isDraft && !_.isEqual(originalTour.current, tourDataCopy)) {
        showDialog(
          'alert',
          'The tour has unsaved changes! Do you wish to save the new changes or discard them?',
          'Save',
          () => {
            updateDraftTour(false, true, true)
            //setIsShowWarning('')
            //unSetDialog()
            //navigate(isShowWarning)
          },
          'Discard',
          () => {
            if(justCreated){
              deleteTourAndNavigate(tourID)
            }else{
              navigate(isShowWarning, isShowWarning)
              unSetDialog()
            }
          },
          'Stay',
          () => {
            setIsShowWarning('')
            unSetDialog()
          }
        )
      } else {
        navigate(isShowWarning)
        unSetDialog()
      }
    }
  }, [isShowWarning, isDraft])

  useEffect(() => {
    if (isSearchSlideOut){
      if(document.getElementById('search_map_bar')){
        document.getElementById('search_map_bar').classList.add('slide_out_search');
      }

      setTimeout(() => {
        setIsSearchVisible(false)
      }, 270);

      setIsSearchSlideOut(false)
    }
  }, [isSearchSlideOut])

  const addStop = (stopDetails = {}) => {
    let currentStops = { ...stops }

    let markerLocation =
      'location' in stopDetails
        ? [stopDetails['location']['lng'], stopDetails['location']['lat']]
        : map.current 
          ? [ map.current.getCenter().lng.toFixed(4), map.current.getCenter().lat.toFixed(4) ]
            : [
              DEFAULT_COORDINATES['lng'] + Math.random() / 5,
              DEFAULT_COORDINATES['lat'] + Math.random() / 5,
            ]
        /*
        : [
            DEFAULT_COORDINATES['lng'] + Math.random() / 5,
            DEFAULT_COORDINATES['lat'] + Math.random() / 5,
          ]*/

    const data = {
      tourId: tourID,
      stopData: {
        order: orderedStops.length,
        name: 'name' in stopDetails ? stopDetails['name'] : '',
        gpsCoordinates: [markerLocation[1], markerLocation[0]],
      },
    }

    showLoaderDialog()

    axios
      .post(CREATE_TOUR_STOP, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${idToken}`,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          if (res.data['status'] == 'success') {
            currentStops[res.data['stopId']] = {
              id: res.data['stopId'],
              name: 'name' in stopDetails ? stopDetails['name'] : '',
              imgs: [],
              location: {
                lat: markerLocation[1],
                lng: markerLocation[0],
              },
              order: orderedStops.length,
            }

            map.current.getSource('markers').setData({
              type: 'FeatureCollection',
              features: Object.values(currentStops).map((stop, i) => {
                return {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    anchor: 'bottom',
                    coordinates:
                      'lng' in stop['location']
                        ? [stop['location']['lng'], stop['location']['lat']]
                        : stop['location'],
                  },
                  properties: {
                    id: stop['id'],
                    title: stop['name'],
                    count: stop['order'] + 1,
                    color: selectedStop == stop['id'] ? 'lightgrey' : '#e9edf0',
                    border: selectedStop == stop['id'] ? '#01A0DB' : 'white',
                  },
                }
              }),
            })

            unSetDialog()

            setStops(currentStops)
            setSelectedStop(res.data['stopId'])
            setSelectedARMarkerType('')
          } else {
            unSetDialog()
          }
        } else {
          unSetDialog()
        }
      })
      .catch((e) => {
        unSetDialog()
        console.error(e)
      })
  }

  const removeStop = (e, id) => {
    e.stopPropagation()

    let currentStops = { ...stops }
    let currentARMarkers = { ...arMarkers }

    showLoaderDialog()

    const stopsAfterCurrent = orderedStops
      .filter((stop) => stop['order'] > currentStops[id]['order'])
      .map((stop) => stop['id'])

    axios
      .delete(DELETE_TOUR_STOP, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${idToken}`,
        },
        data: {
          tourId: tourID,
          stopId: id,
          stopData: currentStops[id],
          stopsAfterCurrent: stopsAfterCurrent,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status == 'success') {
            delete currentStops[id]

            console.log("The id in question: ",id)
            console.log("And current AR Markers: ",currentARMarkers)

            if (id in currentARMarkers) {

              setSelectedStop(id)

              Object.getOwnPropertyNames(currentARMarkers[id]).forEach((key) => {
                if (key != 'length') {
                  const arMarker = currentARMarkers[id][key];

                  setSelectedARMarker(key)
                  console.log("key: ", key)
                  console.log("Extract items from this for deletion: ",arMarker)

                  const imgName =
                    'assetName' in arMarker
                      ? arMarker['assetName']
                      : ''

                  const modelName =
                    'modelName' in arMarker
                      ? arMarker['modelName']
                      : ''

                  console.log("Extracted items: ",imgName, " ;;; ", modelName)

                  deleteARMarker(imgName, 
                                  modelName, 
                                  () => {removeARMarker(key)},
                                  key)
                }

                //currentARMarkers[id][i]['marker'].remove()

              })

              //delete currentARMarkers[id]

              /*for (let i = 0; i < currentARMarkers[id].length + 1; i++) {
                

                
                //currentARMarkers[id][i]['marker'].remove()
              }*/
              //delete currentARMarkers[id]
            }

            //setSelectedStop('')
            //setSelectedARMarker('')

            map.current.getSource('markers').setData({
              type: 'FeatureCollection',
              features: Object.values(currentStops).map((stop, i) => {
                return {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates:
                      'lng' in stop['location']
                        ? [stop['location']['lng'], stop['location']['lat']]
                        : stop['location'],
                  },
                  properties: {
                    title: stop['name'],
                    count: stop['order'] + 1,
                    color: selectedStop == stop['id'] ? 'lightgrey' : '#e9edf0',
                    border: selectedStop == stop['id'] ? '#01A0DB' : 'white',
                  },
                }
              }),
            })

            unSetDialog()

            setSelectedStop('')
            setSelectedStopImage(-1)
            //setARMarkers(currentARMarkers)
            setStops(currentStops)
          } else {
            unSetDialog()
          }
        } else {
          unSetDialog()
        }
      })
      .catch((e) => {
        unSetDialog()
        console.error(e)
      })
  }

  //TODO: 1) Modularize repeating code  2) For replacing picture, instead of UPLOAD + DELETE, design PUT
  const updateStop = async (id, details, callback) => {
    let currentStops = { ...stops }
    let currentStop = { ...currentStops[id] }
    currentStop = { ...currentStop, ...details }

    console.log("Stops before: ", JSON.stringify(currentStops))

    if (('img' in details) && ('img_index' in details)){
      let result_base64 = await new Promise((resolve) => {
        var reader = new window.FileReader()
        reader.onload = (e) => resolve(reader.result)
        reader.readAsDataURL(details['img'])
      })
      
      let fileSize = details['img'].size

      if(fileSize > UPLOAD_SIZE_LIMIT){
        errorToast("File size must be less than 30MB")
      }else{
        let base64 = result_base64
        base64 = base64.split(',')[0] + ',' + base64.split(',')[1]

        if (!('imgs' in currentStop)) {
          currentStop['imgs'] = []
        }

        showLoaderDialog()

        let successfulUpload = null;
        let url = null;
        let identifier = null;

        await uploadFile(
          'image',
          details['imgName'],
          base64.split(',')[1],
          (u, i) => {
            url = u;
            identifier = i;
            successfulUpload = true;
          },
        )

        if(successfulUpload){
          const existingImage = currentStop['imgs'][details['img_index']]

          if (typeof existingImage == 'object') {
            showLoaderDialog()

            await axios
              .delete(DELETE_MEDIA, {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${idToken}`,
                },
                data: {
                  identifier: existingImage.name,
                },
              })
              .then((res) => {
                if (res.status == 200) {
                  if (res.data.status == 'success') {
                    currentStop['imgs'].splice(details['img_index'], 1, {
                      id: details['img_index'],
                      link: url,
                      name: identifier,
                    })

                    //Fix indices
                    const updatedImgs = currentStop.imgs.map((img, index) => ({
                                          ...img,
                                          id: index.toString(), // Set the id to be 0 to N-1 as a string
                                        }));
                    currentStop = {
                      ...currentStop,
                      imgs: updatedImgs,
                    };

                    
                    //showDialog('success', 'Image replaced successfully.')
                    successToast('Image replaced successfully.')
                    unSetDialog()
                    setStops(currentStops)
                  } else {
                    console.log("identifier: ", existingImage.name, "deletion attempt result: ", res.data.status)
                  }
                }
              })
              .catch(() => {
                //showDialog('error', 'There was an error replacing the image. Please try again.')
                errorToast('There was an error replacing the image. Please try again.')
                unSetDialog()
                console.log("identifier: ", existingImage.name, "deletion attempt failed")
                //unSetDialog()
              })
          } else {
            currentStop['imgs'].splice(details['img_index'], 1)
          }
        } else {
          //showDialog('error', 'There was an error replacing the image. Please try again.')
          errorToast('There was an error replacing the image. Please try again.')
          unSetDialog()
        }
      }

    } else if ('img' in details) {
      let result_base64 = await new Promise((resolve) => {
        var reader = new window.FileReader()
        reader.onload = (e) => resolve(reader.result)
        reader.readAsDataURL(details['img'])
      })

      let fileSize = details['img'].size

      if(fileSize > UPLOAD_SIZE_LIMIT){
        errorToast("File size must be less than 30MB")
      }else{
        let base64 = result_base64
        base64 = base64.split(',')[0] + ',' + base64.split(',')[1]

        if (!('imgs' in currentStop)) {
          currentStop['imgs'] = []
        }

        showLoaderDialog()

        await uploadFile(
          'image',
          details['imgName'],
          base64.split(',')[1],
          (url, identifier) => {
            unSetDialog()
            currentStop['imgs'].push({
              id: currentStop['imgs'].length,
              link: url,
              name: identifier,
            })
            //showDialog('success', 'Picture added successfully.')
            successToast('Image added sucessfully.')
            unSetDialog()
          },
        )
      }
    } else if ('img_index' in details) {
      const existingImage = currentStop['imgs'][details['img_index']]

      if (typeof existingImage == 'object') {
        showLoaderDialog()

        await axios
          .delete(DELETE_MEDIA, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${idToken}`,
            },
            data: {
              identifier: existingImage.name,
            },
          })
          .then((res) => {
            if (res.status == 200) {
              if (res.data.status == 'success') {
                console.log("identifier: ", existingImage.name, "deletion attempt result: ", res.data.status)
                currentStop['imgs'].splice(details['img_index'], 1)

                //Fix indices
                const updatedImgs = currentStop.imgs.map((img, index) => ({
                                      ...img,
                                      id: index.toString(), // Set the id to be 0 to N-1 as a string
                                    }));
                currentStop = {
                  ...currentStop,
                  imgs: updatedImgs,
                };

                console.log("Stops after axios: ", JSON.stringify(currentStops))
                //showDialog('success', 'Picture deleted.')
                successToast('Image deleted.')
                unSetDialog()
              } else {
                console.log("identifier: ", existingImage.name, "deletion attempt result: ", res.data.status)
              }
            }
            //unSetDialog()
          })
          .catch(() => {
            console.log("identifier: ", existingImage.name, "deletion attempt failed")
            unSetDialog()
          })

      } else {
        currentStop['imgs'].splice(details['img_index'], 1)
      }
    }

    delete currentStop['img']

    if ('audio' in details) {
      if (details['audio'] instanceof Blob) {
        let result_base64 = await new Promise((resolve) => {
          var reader = new window.FileReader()
          reader.onload = (e) => resolve(reader.result)
          reader.readAsDataURL(details['audio'])
        })

        let fileSize = details['audio'].size

        if(fileSize > UPLOAD_SIZE_LIMIT){
          errorToast("File size must be less than 30MB")
        }else{
          let base64 = result_base64
          base64 = base64.split(',')[0] + ',' + base64.split(',')[1]

          showLoaderDialog()

          await uploadFile(
            'audio',
            details['audio'].name,
            base64.split(',')[1],
            (url, identifier) => {
              unSetDialog()
              currentStop['audioURL'] = url
              currentStop['audioName'] = identifier
              //showDialog('success', 'Audio added successfully.')
              successToast('Audio added successfully.')

            },
          )
        }
      } else {
        if ('audio' in currentStop && details['audio'] == '') {
          if ('audioName' in currentStop) {
            await axios
              .delete(DELETE_MEDIA, {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${idToken}`,
                },
                data: {
                  identifier: currentStop['audioName'],
                },
              })
              .then((res) => {
                if (res.status == 200) {
                  if (res.data.status == 'success') {
                    delete currentStop['audio']
                    delete currentStop['audioName']
                    delete currentStop['audioURL']
                  }
                }

                unSetDialog()
              })
              .catch(() => {
                unSetDialog()
              })
          } else {
            delete currentStop['audio']
            delete currentStop['audioName']
            delete currentStop['audioURL']
          }
        }
      }
    }

    if ('recording' in details) {
      await uploadFile(
        'audio',
        details['recordingName'],
        details['recording'],
        (url, identifier) => {
          unSetDialog()
          currentStop['audioURL'] = url
          currentStop['audioName'] = identifier
          //showDialog('success', 'Audio added successfully.')
          successToast('Audio added successfully.')
        },
      )

      delete currentStop['recording']
      delete currentStop['recordingName']
    }

    currentStops[id] = currentStop

    map.current.getSource('markers').setData({
      type: 'FeatureCollection',
      features: Object.values(currentStops).map((stop, i) => {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates:
              'lng' in stop['location']
                ? [stop['location']['lng'], stop['location']['lat']]
                : stop['location'],
          },
          properties: {
            title: stop['name'],
            count: stop['order'] + 1,
            color: selectedStop == stop['id'] ? 'lightgrey' : '#e9edf0',
            border: selectedStop == stop['id'] ? '#01A0DB' : '#fff',
          },
        }
      }),
    })

    console.log("Stops after: ", JSON.stringify(currentStops))
    console.log("Image Index was: ", JSON.stringify(details['img_index']))

    setStops(currentStops)
  }

  const addARMarker = () => {
    console.log("Add AR Marker Called")

    let currentARMarkers = { ...arMarkers }

    const el = document.createElement('div')
    el.className = 'ar_marker'

    const typeImg = document.createElement('img')
    typeImg.src = AR_MARKER_TYPE_TO_ICON[isARMarkerPictureDialogVisible['type']]
    typeImg.className = 'ar_marker_img'

    el.appendChild(typeImg)

    /*
    console.log("AR markers: ",currentARMarkers)
    console.log("Aux Data: ", isARMarkerPictureDialogVisible)
    console.log("Selected AR Marker: ", selectedARMarker)
    console.log("Selected Stop: ", stops[selectedStop])
    console.log("Selected AR Marker Data: ", arMarkers[selectedStop][selectedARMarker])*/

    let markerLocation = []

    if (selectedARMarker && selectedStop) {
      markerLocation = [
        arMarkers[selectedStop][selectedARMarker]['gpsCoordinates'][0],
        arMarkers[selectedStop][selectedARMarker]['gpsCoordinates'][1],
      ]
    } else {
      markerLocation = [
        isARMarkerPictureDialogVisible['location']['lng'],
        isARMarkerPictureDialogVisible['location']['lat'],
      ]
    }

    console.log("Current AR Markers for selected stop: ",currentARMarkers[selectedStop])

    /*const updateCurrentARMarkersforStop =
      selectedStop in currentARMarkers
        ? Array.isArray(currentARMarkers[selectedStop]) ||
          ? [...currentARMarkers[selectedStop]]
          : [currentARMarkers[selectedStop]]
        : []*/

    let updateCurrentARMarkersforStop = []

    try {
      updateCurrentARMarkersforStop =
        selectedStop in currentARMarkers
          ? {...currentARMarkers[selectedStop]}
          : []
    } catch (error) {
      console.log("Error unpacking currentARMarkers: ", error)

      updateCurrentARMarkersforStop =
        selectedStop in currentARMarkers
          ? JSON.parse(JSON.stringify(currentARMarkers[selectedStop]))
          : []
    }

    console.log("Copy of AR Markers for selected stop: ",updateCurrentARMarkersforStop)


    let stopARMarker = {
      stopId: selectedStop,
      type: isARMarkerPictureDialogVisible['type'],
      gpsCoordinates: markerLocation,
      assetLink: arMarkerImageRef.current.src,
      assetName: arMarkerImageRef.current.getAttribute('data-name'),
    }

    axios
      .post(
        CREATE_AR_DATA,
        {
          tourId: tourID,
          arMarkerData: stopARMarker,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${idToken}`,
          },
        },
      )
      .then((res) => {
        unSetDialog()
        if (res.status == 200) {
          if (res.data['status'] == 'success') {
            let marker = new mapboxgl.Marker(el)
              .setLngLat(markerLocation)
              .addTo(map.current)

            el.addEventListener('click', (e) => {
              e.stopPropagation()

              /*
              setIsARMarkerPictureDialogVisible({
                type: selectedARMarkerType,
                location: {
                  lng: isARMarkerPictureDialogVisible['location']['lng'],
                  lat: isARMarkerPictureDialogVisible['location']['lat'],
                },
              })*/

              setIsARMarkerPictureDialogVisible({
                type: selectedARMarkerType,
                location: {
                  lng: markerLocation[0],
                  lat: markerLocation[1],
                },
              })

            })

            let stopARMarker = {
              stopId: selectedStop,
              arMarkerId: updateCurrentARMarkersforStop.length,
              type: isARMarkerPictureDialogVisible['type'],
              gpsCoordinates: markerLocation,
              assetLink: arMarkerImageRef.current.src,
              assetName: arMarkerImageRef.current.getAttribute('data-name'),
              marker: marker,
            }

            let stopARMarkerForUpload = { ...stopARMarker }
            delete stopARMarkerForUpload['marker']

            stopARMarkerForUpload = res.data.data
            stopARMarker = {
              marker: stopARMarker['marker'],
              ...stopARMarkerForUpload,
            }

            console.log("New AR Marker: ", stopARMarker)

            //updateCurrentARMarkersforStop.push(stopARMarker)
            updateCurrentARMarkersforStop[stopARMarker['arMarkerId']] = stopARMarker
            currentARMarkers[selectedStop] = updateCurrentARMarkersforStop
            arMarkerImageRef.current.src =
              AR_MARKER_TYPE_TO_ICON_FOR_SAVING[
                isARMarkerPictureDialogVisible['type']
              ]

            console.log("AR Markers Before: ", arMarkers)

            setARMarkers(currentARMarkers)

            console.log("AR Markers After: ", currentARMarkers)

            setIsARMarkerPictureDialogVisible(null)

            console.log("Add AR Marker Exited Succesfully")
          }
        } else {
          console.log("An error occured ", res.status, res.data)
        }
      })
      .catch((e) => {
        unSetDialog()
        console.log(e)
      })
  }

  const updateARMarker = (arMarkerDetails) => {
    console.log("Update AR Marker Called")

    let currentARMarkers = { ...arMarkers }

    const currentStopARMarker =
      selectedStop in currentARMarkers
        ? { ...currentARMarkers[selectedStop] }
        : []

    const currentARMarker = { ...currentStopARMarker[selectedARMarker] }

    if ('type' in arMarkerDetails) {
      currentARMarker['type'] = arMarkerDetails['type']
      currentARMarker['assetLink'] = AR_MARKER_TYPE_TO_ICON_FOR_SAVING[
        arMarkerDetails['type']
      ].split(',')[1]
      currentARMarker['assetName'] = ''
      currentARMarker['marker']['_element'].style.backgroundImage = `url(${
        AR_MARKER_TYPE_TO_ICON[arMarkerDetails['type']]
      })`
    }

    if ('location' in arMarkerDetails) {
      if (!currentARMarker['locked']) {
        let newLocation = arMarkerDetails['location']
        currentARMarker['marker'].setLngLat([
          newLocation['lng'],
          newLocation['lat'],
        ])
      } else {
        return
      }
    }

    if ('img' in arMarkerDetails && arMarkerDetails['img'].includes('http')) {
      currentARMarker['assetLink'] = arMarkerDetails['img']
      currentARMarker['assetName'] = arMarkerDetails['imgName']
    }

    currentStopARMarker[selectedARMarker] = currentARMarker
    currentARMarkers[selectedStop] = currentStopARMarker

    showLoaderDialog()

    const arMarkerDataForUpload = { ...currentARMarker }
    delete arMarkerDataForUpload['marker']

    axios
      .put(
        UPDATE_AR_DATA,
        {
          tourId: tourID,
          stopId: selectedStop,
          markerId: selectedARMarker,
          arMarkerData: arMarkerDataForUpload,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${idToken}`,
          },
        },
      )
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status == 'success') {
            setARMarkers(currentARMarkers)
            setIsARMarkerPictureDialogVisible(null)
          }
        }

        console.log("Update AR Marker Exited Succesfully")

        unSetDialog()
      })
      .catch((e) => {
        console.log(e)
        unSetDialog()
      })
  }

  const removeARMarker = (arMarkerId) => {
    console.log("Remove AR Marker Called")

    console.log("Selected Stop: ", selectedStop)
    console.log("AR Markers List: ",arMarkers)

    let currentARMarkers = { ...arMarkers }

    let currentARMarker = currentARMarkers[selectedStop]

    if (arMarkerId && arMarkerId in currentARMarker) {
      console.log("using key")
      currentARMarker[arMarkerId]['marker'].remove()
      delete currentARMarker[arMarkerId]
    } else if (selectedARMarker in currentARMarker) {
      console.log("not using key")
      currentARMarker[selectedARMarker]['marker'].remove()
      delete currentARMarker[selectedARMarker]
    }

    currentARMarkers[selectedStop] = currentARMarker

    console.log("New AR Markers List: ",currentARMarkers)

    setARMarkers(currentARMarkers)
    setSelectedARMarker('')
    setIsARMarkerPictureDialogVisible(false)

    console.log("Remove AR Marker Exited Succesfully")
  }

  const getFormElementsValues = (form) => {
    const builderFormTemp = { ...form }

    let formElementValues = {}
    for (let key in builderFormTemp) {
      if (builderFormTemp[key] == '' || builderFormTemp[key] == undefined) {
        formElementValues[key] = ''
      } else {
        formElementValues[key] = builderFormTemp[key].value
      }
    }

    delete formElementValues['addStop']

    formElementValues['tourCurrency'] = builderFormTemp.tourPrice.prefix

    if (builderFormTemp['startLocation'].value == '') {
      if (orderedStops.length > 0) {
        formElementValues['startLocation'] = orderedStops[0]['name']
      } else {
        formElementValues['startLocation'] = ''
      }
    }

    if (builderFormTemp['endLocation'].value == '') {
      if (orderedStops.length > 0) {
        formElementValues['endLocation'] =
          orderedStops[orderedStops.length - 1]['name']
      } else {
        formElementValues['endLocation'] = ''
      }
    }

    return formElementValues
  }

  const onInputChangedHandler = (event, inputIdentifier, isReset = false) => {
    const updatedBuilderForm = { ...builderForm }
    const updatedFormElement = { ...updatedBuilderForm[inputIdentifier] }
    updatedFormElement.value = isReset ? '' : event.target.value
    updatedBuilderForm[inputIdentifier] = updatedFormElement
    setBuilderForm(updatedBuilderForm)
  }

  const removeTourImage = (index) => {
    const updatedTourImages = [...tourImages]

    if (typeof updatedTourImages[index] == 'object') {
      showLoaderDialog()

      axios
        .delete(DELETE_MEDIA, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${idToken}`,
          },
          data: {
            identifier: updatedTourImages[index].name,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.status == 'success') {
              updatedTourImages.splice(index, 1)
              setTourImages(updatedTourImages)
            }
          }

          unSetDialog()
        })
        .catch(() => {
          unSetDialog()
        })
    } else {
      updatedTourImages.splice(index, 1)
      setTourImages(updatedTourImages)
    }
  }

  // const createOrEditARMarker = async (tourID, allARMarkers) => {
  //   let arMarkersList = [...Object.values(allARMarkers)]
  //   for (let i = 0; i < arMarkersList.length; i++) {
  //     for (let j = 0; j < arMarkersList[i].length; j++) {
  //       delete arMarkersList[i][j]['marker']
  //     }
  //   }

  //   let data = {
  //     tourId: tourID,
  //     arMarkerData: arMarkersList,
  //   }

  //   axios
  //     .post(CREATE_AR_DATA, data, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Token ${idToken}`,
  //       },
  //     })
  //     .catch((e) => {
  //       console.log(e)
  //     })
  // }

  const deleteARMarker = (arImgName, arModelName, callback, arMarkerId) => {
    let data = {
      tourId: tourID,
      stopId: selectedStop,
      markerId: arMarkerId ? arMarkerId : selectedARMarker,
      arImgName: arImgName,
      arModelName: arModelName,
    }

    console.log("Selected AR Marker from deleteARMarker func: ", arMarkerId)

    axios
      .delete(DELETE_AR_DATA, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${idToken}`,
        },
        data,
      })
      .then(() => {
        unSetDialog()
        callback()
      })
      .catch((error) => {
        unSetDialog()

        const errorMessage = JSON.stringify(error.response.data)

        console.log("Error in DELETE AR DATA: ",errorMessage)

        //Actually a success; objects sometimes get prematurely deleted
        if (errorMessage.includes("No such object")){
          callback()
        }

      })
  }

  const publishOrUnPublishTour = async (type) => {
    let data = {
      tourId: tourID,
    }

    if(isSuperUser){
      data["isSuperAction"] = true;
    }

    showLoaderDialog()
    await axios
      .put(isPublished ? SUSPEND_TOUR : PUBLISH_TOUR, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${idToken}`,
        },
      })
      .then((res) => {
        unSetDialog()
        if (res.status == 200) {
          setIsPublished(!isPublished)
          navigate('/')
          toast.success(`Tour ${isPublished ? 'suspended' : 'published'} successfully.`)
        } else {
          showDialog(
            'error',
            `Failed to ${isPublished ? 'Suspend' : 'Publish'}!`,
          )
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const deleteTourAndNavigate = async (tourID,route) => {
    showLoaderDialog()
    await axios
      .delete(DELETE_TOUR, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${idToken}`,
        },
        data: {
          tourId: tourID,
        },
      })
      .then(async (res) => {
        if (res.status == 200) {
          unSetDialog()
          navigate(route)
          unSetDialog()
          console.log("Empty draft trashed.")
        }
      })
      .catch((e) => {
        console.log("Empty draft could not be trashed: ",e)
        unSetDialog()
        navigate(route)
      })
  }

  function handleOnDragEnd(result) {
    console.log(result)
    if (
      !result.destination ||
      result.destination.index === result.source.index ||
      result.destination.droppableId != result.source.droppableId
    )
      return
    if (result.draggableId.includes('stop_image_')) {
      const updatedStops = { ...stops }
      const updatedSelectedStop = { ...updatedStops[selectedStop] }

      const updatedSelectedStopImages = [...updatedSelectedStop['imgs']]

      const [reorderedItem] = updatedSelectedStopImages.splice(
        result.source.index,
        1,
      )

      updatedSelectedStopImages.splice(
        result.destination.index,
        0,
        reorderedItem,
      )

      updatedSelectedStop['imgs'] = updatedSelectedStopImages
      updatedStops[selectedStop] = updatedSelectedStop

      setSelectedStopImage(-1)
      setStops(updatedStops)
    } else {
      const items = [...orderedStops]
      const [reorderedItem] = items.splice(result.source.index, 1)
      items.splice(result.destination.index, 0, reorderedItem)
      for (let i = 0; i < items.length; i++) {
        items[i]['order'] = i
      }
      map.current.getSource('markers').setData({
        type: 'FeatureCollection',
        features: items.map((stop, i) => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates:
                'lng' in stop['location']
                  ? [stop['location']['lng'], stop['location']['lat']]
                  : stop['location'],
            },
            properties: {
              title: stop['name'],
              count: stop['order'] + 1,
              color: selectedStop == stop['id'] ? 'lightgrey' : '#e9edf0',
              border: selectedStop == stop['id'] ? '#62AECC' : '#fff',
            },
          }
        }),
      })
      const itemsObj = {}
      items.forEach((item) => {
        itemsObj[item['id']] = item
      })
      setSelectedStop('')
      setSelectedARMarker('')
      setStops(itemsObj)
    }
  }

  const createOrEditTourHandler = async () => {
    setChkEmpty(true)
    setIsError(false)

    let tourData = getFormElementsValues(builderFormRef.current)
    //let errorMessage = `Incomplete Tour Info Entered. Please fill all the fields marked with *`
    let errorMessage = ""
    const tourDataKeys = Object.keys(tourData)
    let isValid = !Object.values(tourData).includes('')
    for (let i = 0; i < orderedStops.length; i++) {
      if (orderedStops[i].name === '') {
        errorMessage = errorMessages['tourStop']
        isValid = false
      }
    }
    for (let i = 0; i < tourDataKeys.length; i++) {
      if (
        tourData[tourDataKeys[i]] === '' ||
        tourData[tourDataKeys[i]] === undefined
      ) {
        isValid = false
        errorMessage = errorMessages[tourDataKeys[i]]
        break
      }
    }

    if (orderedStops.length < 2) {
      errorMessage = errorMessages['addStops']
    }
    if (isValid) {
      if (tourImages.length < 1) {
        isValid = false
        errorMessage = errorMessages['tourPicture']
      } else if (tourCentralAudio == null) {
        isValid = false
        errorMessage = errorMessages['tourAudio']
      }
    }

    console.log("1. Tour create/update error: ",errorMessage)

    if (isValid && orderedStops.length > 1) {
      let stopsObj = {}
      for (let i = 0; i < orderedStops.length; i++) {
        let stopData = {
          order: i,
          name: orderedStops[i]['name'],
          gpsCoordinates: [
            'lat' in orderedStops[i]['location']
              ? orderedStops[i]['location']['lat']
              : orderedStops[i]['location'][1],
            'lng' in orderedStops[i]['location']
              ? orderedStops[i]['location']['lng']
              : orderedStops[i]['location'][0],
          ],
          pictureURLs: [],
          audioURL: '',
          audioName: '',
        }

        let existingStopPictures =
          'pictureURLs' in orderedStops[i] ? orderedStops[i]['pictureURLs'] : []

        for (let j = 0; j < orderedStops[i]['imgs'].length; j++) {
          existingStopPictures.push(orderedStops[i]['imgs'][j])
        }

        stopData['pictureURLs'] = existingStopPictures

        if ('audioURL' in orderedStops[i]) {
          stopData['audioURL'] = orderedStops[i]['audioURL']
          stopData['audioName'] = orderedStops[i]['audioName']
        }

        if ('audio' in orderedStops[i]) {
          stopData['audioURL'] = orderedStops[i]['audio']
          stopData['audioName'] = orderedStops[i]['audioName']
        }

        stopsObj[orderedStops[i]['id']] = stopData
      }

      tourData['stops'] = stopsObj
      tourData['tourGuideId'] = currentUser.uid

      tourData['tourDistance'] = tourDistance
      tourData['tourDuration'] = tourDuration

      if (tourImages == 0 || tourCentralAudio == null) {
        return
      }

      if (tourCentralAudio != null) {
        tourData['centralAudio'] = {
          url: tourCentralAudio.url,
          name: tourCentralAudio.name,
          length: tourCentralAudio.length,
        }
      }

      console.log("2. Tour create/update error: ",errorMessage)

      let data = {
        tourId: tourID,
        tourGuideId: currentUser.uid,
        tourData: tourData,
        tourPictures: tourImages,
        isPublished: isPublished,
        isDraft: false,
        isComplete: true,
      }

      showLoaderDialog()
      setIsProcessing(true)

      updateTourAPIRequest(data)
    } else {
      console.log("3. Tour create/update error: ",errorMessage)
      unSetDialog()
      setIsError(true)
      setIsProcessing(false)
      //showDialog('error', errorMessage)
      //errorToast(errorMessage)
    }
  }

  const getLatestTourData = (tourDataBasic, isNew) => {
    let tourData = tourDataBasic

    const tourDataKeys = Object.keys(tourData)

    for (let i = 0; i < tourDataKeys.length; i++) {
      if (
        (!isNew &&
          (tourData[tourDataKeys[i]] === '' ||
            tourData[tourDataKeys[i]] === undefined)) ||
        (isNew && tourData[tourDataKeys[i]] === undefined)
      ) {
        delete tourData[tourDataKeys[i]]
      }
    }

    if (!tourData.hasOwnProperty("name") || tourData['name'] == '') {
      tourData['name'] = defaultTourName.current
    }

    let stopsObj = {}

    const orderedStops = Object.values(stopsRef.current).sort((a, b) =>
      a.order < b.order ? -1 : a.order > b.order ? 1 : 0,
    )

    for (let i = 0; i < orderedStops.length; i++) {
      let stopData = {
        id: orderedStops[i]['id'],
        order: i,
        name: orderedStops[i]['name'],
        gpsCoordinates: [
          'lat' in orderedStops[i]['location']
            ? orderedStops[i]['location']['lat']
            : orderedStops[i]['location'][1],
          'lng' in orderedStops[i]['location']
            ? orderedStops[i]['location']['lng']
            : orderedStops[i]['location'][0],
        ],
        audioURL: '',
        audioName: '',
      }

      let existingStopPictures =
        'pictureURLs' in orderedStops[i] ? orderedStops[i]['pictureURLs'] : []

      for (let j = 0; j < orderedStops[i]['imgs'].length; j++) {
        existingStopPictures.push(orderedStops[i]['imgs'][j])
      }

      if (existingStopPictures.length > 0) {
        stopData['pictureURLs'] = existingStopPictures
      }

      if ('audioURL' in orderedStops[i]) {
        stopData['audioURL'] = orderedStops[i]['audioURL']
        stopData['audioName'] = orderedStops[i]['audioName']
      }

      stopsObj[orderedStops[i]['id']] = stopData
    }

    if (Object.keys(stopsObj).length > 0) {
      tourData['stops'] = stopsObj
    }
    tourData['tourGuideId'] = currentUser.uid

    tourData['tourDistance'] = tourDistanceRef.current
    tourData['tourDuration'] = tourDurationRef.current

    if (tourCentralAudioRef.current != null) {
      tourData['centralAudio'] = {
        url: tourCentralAudioRef.current.url,
        name: tourCentralAudioRef.current.name,
        length: tourCentralAudioRef.current.length,
      }
    }

    return tourData
  }

  const updateDraftTour = (isNew, isExit, isExplictSave) => {
    if(isExplictSave){
      showLoaderDialog()
      setJustCreated(false)
      setIsProcessing(true)
    }

    let tourDataBasic = getFormElementsValues(builderFormRef.current)

    let tourData = getLatestTourData(tourDataBasic, isNew)

    /*This elaborate if condition passes on the following conditions being simultaneously satisfied:
      1) Either the tour should have just been created OR if an existing tour, the data must have been read (latter important to know if a tour is complete).
      2) Either the first ever run of the updateTourDraft function OR the tour data must have been modified
    */
    if ((isNew || !_.isEqual(originalTour.current, tourData))
        && (tourIDFromParam == undefined || tourDataHasBeenRead)) 
    {

      let data = isNew
        ? {
            tourData: tourData,
            isDraft: true,
            isComplete: false,
          }
        : {
            tourId: tourIDRef.current,
            tourData: tourData,
            tourPictures: tourImages,
            isPublished: isPublished,
            isDraft: true,
            isComplete: isComplete,
          }

      const requestHeader = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${idToken}`,
        },
      }

      const createOrUpdateDraft = isNew
        ? axios.post(CREATE_TOUR_DATA, data, requestHeader)
        : axios.put(UPDATE_TOUR_DATA, data, requestHeader)

      createOrUpdateDraft
        .then((res) => {
          if (res.status == 200) {
            if (res.data.status == 'success') {
              setTourBuilderJustOpened(false)
              if (isNew) {
                setTourID(res.data.tourId)
                defaultBuilderFormData.current = tourData
              }

              //setIsPublished(false)
              setIsDraft(true)

              //setOriginalTour(tourData)
              originalTour.current = tourData
              draftLastSavedTimeRef.current = new Date().toLocaleTimeString()

              if(isExplictSave){
                unSetDialog()
                setIsProcessing(false)
                /*showDialog(
                  'success',
                  'Changes saved successfully.',
                  'OK',
                  () => {
                    unSetDialog()
                  }
                )*/
                successToast("Changes saved successfully.")
              }
            }
          }

          if (isExit) {
            navigate(isShowWarning)
          } /*else {
            if (!isNew) {
              draftTimerRef.current = setTimeout(() => {
                updateDraftTour()
              }, 5000)
            }
          }*/
        })
        .catch((e) => {
          console.log(e)

          if (isExit) {
            navigate('/')
          } else {
            if(isExplictSave){
              unSetDialog()
              setIsProcessing(false)
              //showDialog('error', "There was an error while saving the changes.")
              errorToast('There was an error while saving the changes.')
            }

            /*if (!isNew) {
              
              draftTimerRef.current = setTimeout(() => {
                updateDraftTour()
              }, 5000)
            }*/
          }
        })
    } else {
      if (isExit) {
        navigate('/')
      } else {
        if(isExplictSave){
          unSetDialog()
          setIsProcessing(false)
          //showDialog('success', "All changes are already saved.")
          successToast("All changes saved.")
        }

        /*if (!isNew) {
          draftTimerRef.current = setTimeout(() => {
            updateDraftTour()
          }, 5000)
        }*/
      }
    }
  }

  const updateTourAPIRequest = (data, tourData) => {
    const requestHeader = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${idToken}`,
      },
    }
    const createOrUpdateTour = axios.put(UPDATE_TOUR_DATA, data, requestHeader)

    createOrUpdateTour
      .then(async (res) => {
        if (res.status == 200) {
          if (res.data.status == 'success') {
            const newTourID = tourID ? tourID : res.data['tourId']

            // await createOrEditARMarker(newTourID, arMarkers)

            unSetDialog()

            if (tourID == undefined){
              showDialog(
                'success',
                `Congratulations! Tour ${builderForm['name']['value']} is created, but won't be available to users until it's published.`,
                'OK',
                () => {
                  unSetDialog()
                  navigate('/builder/edit/' + newTourID, { replace: true })
                },
              )
            } else {
              successToast("Tour Edited Succesfully!")
            }
            
          } else {
            unSetDialog()
            setIsProcessing(false)
            errorToast("Incomplete Tour Info Entered. Please see and fill all the fields marked with *")
          }
        } else {
          unSetDialog()
          setIsProcessing(false)
          errorToast("Incomplete Tour Info Entered. Please see and fill all the fields marked with *")
        }
      })
      .catch((e) => {
        unSetDialog()
        setIsProcessing(false)
        console.log(e)
        showDialog(
          'error',
          `Some Error Occurred. Please Try Again.`,
          data,
          tourData,
        )
      })
  }

  const showLoaderDialog = () => {
    let dialog = {
      isLoader: true,
    }

    setDialog(dialog)
  }

  const selectARMarker = (type) => {
    if (type == selectedARMarkerType) {
      setSelectedARMarkerType('')
      setSelectedARMarker('')
    } else if (selectedStop != '') {
      //TODO: Review edge-case of updating type of existing AR Marker. (Just let user delete and recreate for now)
      /*if (selectedStop in arMarkers) {
        updateARMarker({
          type: type,
        })
      }*/
      setSelectedARMarker('')
      setSelectedARMarkerType(type)
    }
  }

  const onDropdownChangedHandler = (newValue, inputIdentifier) => {
    let updatedBuilderForm = { ...builderForm }
    let updatedFormElement = { ...updatedBuilderForm[inputIdentifier] }

    updatedFormElement = newValue

    updatedBuilderForm[inputIdentifier] = updatedFormElement

    if (inputIdentifier === 'tourCurrency') {
      let tourPriceFormElement = { ...updatedBuilderForm['tourPrice'] }
      tourPriceFormElement['prefix'] = newValue['value']
      updatedBuilderForm['tourPrice'] = tourPriceFormElement
    }

    if (inputIdentifier === 'tourCountry') {
      if (
        Object?.entries(COUNTRY_TO_CITY_MAP)
          ?.map((val) => val?.filter((e) => val?.includes(newValue['value'])))
          ?.filter((e) => e?.length)[0] !== undefined
      ) {
        let countryCities = Object?.entries(COUNTRY_TO_CITY_MAP)
          ?.map((val) => val?.filter((e) => val?.includes(newValue['value'])))
          ?.filter((e) => e.length)[0][1]
        let formattedcountryCities = countryCities.map((val, index) => ({
          value: val,
          label: val,
        }))
        setCountriesCities(formattedcountryCities)
        updatedBuilderForm['tourCity'] = formattedcountryCities[0]
      } else {
        setCountriesCities([{label: "Select...", value: "N/A"}])
        updatedBuilderForm['tourCity'] = ""
      }
    }
    if (inputIdentifier === 'tourCity') {
      let List = countriesCities.filter((city) => city.value == newValue.value)
      if (List.length == 0) {
        setCountriesCities([...countriesCities, newValue])
      }
    }

    setBuilderForm(updatedBuilderForm)
  }

  const showDialog = (
    type,
    message,
    btnTxt,
    btnAction,
    btn2Txt,
    btn2Action,
    btn3Txt,
    btn3Action
  ) => {

    let dialog = {
      type: type,
      message: message,
      btnTxt: btnTxt !== undefined ? btnTxt : 'OK',
      btnAction: btnAction == undefined ? () => unSetDialog() : btnAction,
      btn2Txt: btn2Txt !== undefined ? btn2Txt : 'NO',
      btn2Action: btn2Action == undefined ? () => unSetDialog() : btn2Action,
    }

    if (btn3Txt !== undefined) {
      dialog['btn3Txt'] = btn3Txt
      dialog['btn3Action'] = btn3Action
    }

    setDialog(dialog)
  }

  const uploadARMarkerImage = async (type, fileName, file, callback) => {
    let data = {
      mediaType: type,
      media: file,
      mediaName: fileName,
    }

    await axios
      .post(UPLOAD_AR_MEDIA, data, {
        headers: {
          Authorization: `Token ${idToken}`,
        },
      })
      .then(async (res) => {
        if (res.status == 200) {
          if (res.data['status'] !== 'error') {
            let mediaURL = res.data.data['url']
            let mediaIdentifier = res.data.data['identifier']

            callback(mediaURL, mediaIdentifier)
          } else {
            unSetDialog()
          }
        } else {
          unSetDialog()
        }
      })
      .catch((e) => {
        unSetDialog()
        setIsProcessing(false)
        console.log(e, data)
        //showDialog('error', `Failed to upload image.`)
        errorToast("Failed to upload image.")
        unSetDialog()
      })
  }

  const uploadFile = async (type, fileName, file, callback) => {
    let data = {
      mediaType: type,
      media: file,
      mediaName: fileName,
    }

    console.log("Upload file called with data: " + JSON.stringify(data))

    await axios
      .post(UPLOAD_MEDIA, data, {
        headers: {
          Authorization: `Token ${idToken}`,
        },
      })
      .then(async (res) => {
        if (res.status == 200) {
          if (res.data['status'] !== 'error') {
            let mediaURL = res.data.data['url']
            let mediaName = res.data.data['identifier']

            callback(mediaURL, mediaName)

            //console.log("Image uploaded to url: ", mediaURL)
          } else {
            unSetDialog()
          }
        } else {
          unSetDialog()
        }
      })
      .catch((e) => {
        unSetDialog()
        setIsProcessing(false)
        console.log(e, data)
        //showDialog('error', `Failed to upload image.`)
        errorToast('Failed to upload file: '+e.toString())
      })
  }

  const handleBottomRightBarHover = (isEnter) => {
    if (isEnter && selectedStop == '') {
      setHideBottomRightBar(true)
    } else if (!isEnter) {
      setHideBottomRightBar(false)
    }
  }

  function selectedStopUpdate(id) {
    map.current.getSource('markers').setData({
      type: 'FeatureCollection',
      features: orderedStops.map((stop, i) => {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates:
              'lng' in stop['location']
                ? [stop['location']['lng'], stop['location']['lat']]
                : stop['location'],
          },
          properties: {
            title: stop['name'],
            count: stop['order'] + 1,
            color: id == stop['id'] ? 'lightgrey' : '#e9edf0',
            border: id == stop['id'] ? '#01A0DB' : '#fff',
          },
        }
      }),
    })
    setSelectedStop(id)
    setSelectedARMarker('')
  }

  const cleanLanguageOptions = (languageOptions) => {
    const cleanedOptions = languageOptions.map(option => ({
      value: option.value,
      label: option.label.split('[')[0].trim()
    }));

    return cleanedOptions;
  }

  const isActionButtonVisible = () => {
    if(isSuperSuspended){
      if(isSuperUser){
        return true;
      }else{
        return false;
      }
    }else{
      return true;
    }
  }

  return (
    <div>
      {/* <RouterPrompt
        when={showPrompt}
        message="Leave this page"
        cancelBtnText="Cancel"
        actionBtnText="Confirm"
        onActionBtnClickHandler={() => true}
        onCancelBtnClickHandler={() => false}
      /> */}

      <ToastContainer
        style={{ height: "2em" }}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <LocationSelectionDialog
        isVisible={isLocationSetterDialogVisible}
        setIsVisible={(isVisible) =>
          setIsLocationSetterDialogVisible(isVisible)
        }
        map={map.current}
        showErrorDialog={() => {
          setDialog({
            type: 'error',
            message: 'Please enter a valid location.',
            btnTxt: 'Try Again',
            btnAction: () => unSetDialog(),
          })
        }}
        chkEmpty={chkEmpty}
        stops={stops}
      />

      <ARMarkerPictureUploadDialog
        arMarkers={arMarkers}
        selectedStop={selectedStop}
        selectedARMarker={selectedARMarker}
        selectedARMarkerType={selectedARMarkerType}
        arMarkerImageRef={arMarkerImageRef}
        addARMarker={addARMarker}
        updateARMarker={updateARMarker}
        deleteARMarker={deleteARMarker}
        removeARMarker={removeARMarker}
        showLoaderDialog={showLoaderDialog}
        uploadFile={uploadARMarkerImage}
        showDialog={showDialog}
        unSetDialog={unSetDialog}
        isVisible={isARMarkerPictureDialogVisible}
        setIsVisible={(visibility) =>
          setIsARMarkerPictureDialogVisible(visibility)
        }
      />

      <StopPictureDialog
        stops={stops}
        updateStop={updateStop}
        selectedStopImage={selectedStopImage}
        setSelectedStopImage={(image) => setSelectedStopImage(image)}
        stopImageRef={stopImageRef}
        stopPictureUploadRef={stopPictureUploadRef}
        selectedStop={selectedStop}
        showDialog={showDialog}
        unSetDialog={unSetDialog}
        isVisible={isStopPictureDialogVisible}
        setIsVisible={(visibility) => setIsStopPictureDialogVisible(visibility)}
      />

      <div
        id="tourBuilder"
        style={{ pointerEvents: isProcessing ? 'none' : '' }}
      >
        <div
          id="tourBuilderForm"
          onClick={() => {
            setSelectedStop('')
            setSelectedARMarker('')
            setSelectedARMarkerType('')
            setSelectedStopImage(-1)
          }}
        >
          <div id="builderFormStartArea">
            <div className="edit_heading">
              <div className="form_heading">
                {!props.isEdit  ? 'Create New Tour'
                                :  isDraft ? 'Edit Draft' 
                                           : 'Edit Tour'}
                <p className="subtext_colored" > last saved at <b> {draftLastSavedTimeRef.current} </b> </p>
                {!isActionButtonVisible() && <p className="subtext_red" ><b> This tour has been suspended by an admin. You may edit the tour, but only an admin can re-publish it. </b></p>}
              </div>
              <div className="edit_btns">
                <button onClick={() => updateDraftTour(null,false,true)}>
                  SAVE
                </button>
                {isActionButtonVisible() && <button
                  className={isComplete 
                              ? isPublished 
                                ? "suspend_btn"
                                : "publish_btn"
                              : "inactive_btn"}
                  onClick={() => {
                    if(isComplete){
                      showDialog(
                        'alert',
                        `Are you sure that you want to ${
                          isPublished ? 'suspend' : 'publish'
                        } this tour? ${(isSuperUser && isPublished) ? 'If you suspend it, only a super-user (including you) can re-publish it.' : ''}`,
                        'YES',
                        () => publishOrUnPublishTour(),
                        'NO',
                        () => unSetDialog(),
                      )
                    } else {
                      warningToast("Please fill in all fields marked with (*) before publishing the tour.")
                      createOrEditTourHandler()
                    }
                  }}
                >
                  {isPublished ? 'SUSPEND' : 'PUBLISH'}
                </button>}
              </div>
            </div>
            {/*{!props.isEdit ? (
              <div className="form_heading">Welcome to Tour Builder!</div>
            ) : (
              <span></span>
            )}
            {!props.isEdit ? (
              <div className="form_ins">
                Here you can setup and configure your tours easily.
              </div>
            ) : (
              <span></span>
            )}*/}

          </div>
          <div id="builderFormInpArea">

            <div className="form_subheading">
              Fill in the form to configure your tour
            </div>

            <InputWidget
              chkEmpty={chkEmpty}
              elementType={builderForm['name'].elementType}
              elementConfig={builderForm['name'].elementConfig}
              value={builderForm['name'].value}
              elementLabel={builderForm['name'].elementLabel}
              floatingButton={builderForm['name'].floatingButton}
              changed={(event) =>
                onInputChangedHandler(event, builderForm['name'].id)
              }
            />

            { orderedStops.length < 2 ? null :
            <div style={{ width: '80%', margin: 'auto', marginTop: '20px' }}>
              <label className="summary_text">
                TOTAL DISTANCE: <p className="subtext_colored_2" > {formatDist(tourDistance)} </p>
              </label>

              <label className="summary_text">
                WALKING TIME: <p className="subtext_colored_2" > {formatDur(tourDuration)} </p>
              </label>
            </div>
            }

            <div id="tourRouteData">
              { orderedStops.length == 0 ? null : <div className="route_path"></div> }
              { orderedStops.length == 0 ? null :
              <div className="field_with_marker">
                <div
                  className="field_marker"
                  style={{ top: 0, transform: 'translateX(-50%)' }}
                >
                  <span></span>
                </div>

                <InputWidget
                  chkEmpty={chkEmpty}
                  elementType={builderForm['startLocation'].elementType}
                  elementConfig={builderForm['startLocation'].elementConfig}
                  value={
                    orderedStops.length != 0 && 'name' in orderedStops[0]
                      ? orderedStops[0]['name']
                      : ''
                  }
                  elementLabel={builderForm['startLocation'].elementLabel}
                  floatingButton={builderForm['startLocation'].floatingButton}
                  isDisabled={true}
                  changed={(event) =>
                    onInputChangedHandler(
                      event,
                      builderForm['startLocation'].id,
                    )
                  }
                />
              </div>
              }

              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="stop">
                  {(provided, snapshot) => (
                    <div
                      className="stops"
                      style={{
                        display: orderedStops.length == 0 ? 'none' : '',
                      }}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      isdraggingover={snapshot.isdraggingover}
                    >
                      {orderedStops.map((stop, i) => (
                        <StopWidget
                          key={stop.id}
                          index={i}
                          {...stop}
                          arMarkerImageRef={arMarkerImageRef}
                          arMarker={
                            stop['id'] in arMarkers
                              ? Object.values(arMarkers[stop['id']])
                              : []
                          }
                          setSelectedARMarker={setSelectedARMarker}
                          selectedARMarker={selectedARMarker}
                          onClick={() => {
                            selectedStopUpdate(stop.id)
                          }}
                          setSelectedARMarkerType={setSelectedARMarkerType}
                          selectedARMarkerType={selectedARMarkerType}
                          setIsARMarkerPictureDialogVisible={
                            setIsARMarkerPictureDialogVisible
                          }
                          chkEmpty={chkEmpty}
                          isSelected={selectedStop == stop['id']}
                          selectedStop={selectedStop}
                          onChange={(id, details, callback) =>
                            updateStop(id, details, callback)
                          }
                          /*onRemove={(e) => removeStop(e, stop.id)}*/
                          onRemove={(e) => {showDialog('alert', 
                                          'Are you sure you want to delete this stop and all the markers associated with it?',
                                          'DELETE',
                                          ()=>{removeStop(e, stop.id)},
                                          'CANCEL')}}
                          selectImage={(index) => {
                            setSelectedStopImage(index)
                            setIsStopPictureDialogVisible(true)
                          }}
                          onRemoveAudio={() =>
                            showDialog(
                              'alert',
                              `Are you sure that you want to delete this audio?`,
                              'DELETE',
                              () => {
                                unSetDialog()
                                updateStop(stop.id, { audio: '' })
                                showLoaderDialog()
                              },
                              'CANCEL',
                              () => unSetDialog(),
                            )
                          }
                          isdraggingover={snapshot.isdraggingover}
                          showDialog={showDialog}
                          unSetDialog={unSetDialog}
                          stop={stop}
                          stops={stops}
                          showErrorToast={errorToast}
                        />
                      ))}
                      <div
                        style={{
                          borderRadius: snapshot.isdraggingover ? '16px' : '',
                          background: snapshot.isdraggingover
                            ? '#c1c7cb77'
                            : '',
                          margin: 'auto',
                          width: '80%',
                        }}
                      >
                        {provided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <InputWidget
                chkEmpty={orderedStops.length < 2 ? chkEmpty : false}
                elementType={builderForm['addStop'].elementType}
                elementConfig={builderForm['addStop'].elementConfig}
                value={builderForm['addStop'].value}
                elementLabel=""
                floatingButton={null}
                buttonComponent={<AddBtnAltIcon/>}
                onClick={() => {
                  onInputChangedHandler(null, 'addStop', true)
                  addStop({ name: builderForm['addStop'].value })
                }}
                changed={(event) =>
                  onInputChangedHandler(event, builderForm['addStop'].id)
                }
              />

              {/*<InputWidget
                chkEmpty={false}
                elementType="button"
                elementConfig={ {
                      type: 'button',
                      name: 'addStop',
                      placeholder: '',
                    } } 
                value=""
                elementLabel=""
                floatingButton={null}
                buttonImage={AddBtnAltIcon}
                buttonComponent={<AddBtnAltIcon/>}
                onClick={() => {
                  onInputChangedHandler(null, 'addStop', true)
                  addStop({ name: builderForm['addStop'].value })
                }}
                changed={(event) =>
                  onInputChangedHandler(event, builderForm['addStop'].id)
                }
              />*/}

              {/*<InputWidget
                chkEmpty={false}
                elementType={builderForm['addStop'].elementType}
                elementConfig={builderForm['addStop'].elementConfig}
                value={builderForm['addStop'].value}
                elementLabel={builderForm['addStop'].elementLabel}
                floatingButton={builderForm['addStop'].floatingButton}
                floatingButtonAction={() => {
                  onInputChangedHandler(null, 'addStop', true)
                  addStop({ name: builderForm['addStop'].value })
                }}
                changed={(event) =>
                  onInputChangedHandler(event, builderForm['addStop'].id)
                }
              />*/}

              { orderedStops.length == 0 ? null :
              <div className="field_with_marker">
                <div className="field_marker">
                  <span
                    style={{
                      background: '#90EE90',
                      boxShadow:
                        'inset -2px -2px 4px rgba(255, 255, 255, 0.21), inset 2px 2px 3px rgba(0, 0, 0, 0.15)',
                    }}
                  ></span>
                </div>
                <InputWidget
                  chkEmpty={chkEmpty}
                  elementType={builderForm['endLocation'].elementType}
                  elementConfig={builderForm['endLocation'].elementConfig}
                  value={
                    orderedStops.length != 0 &&
                    'name' in orderedStops[orderedStops.length - 1]
                      ? orderedStops[orderedStops.length - 1]['name']
                      : ''
                  }
                  elementLabel={builderForm['endLocation'].elementLabel}
                  floatingButton={builderForm['endLocation'].floatingButton}
                  isDisabled={true}
                  changed={(event) =>
                    onInputChangedHandler(event, builderForm['endLocation'].id)
                  }
                />
              </div>
            }
            </div>

            <div style={{ width: '85%', marginLeft: '10%', marginRight: '5%', marginTop: '20px' }}>
              <label className="dropdown_label">TOUR COUNTRY *</label>
              <Select
                value={builderForm.tourCountry}
                onChange={(val) => onDropdownChangedHandler(val, 'tourCountry')}
                options={COUNTRY_OPTIONS}
                styles={customStyles}
                className={
                  chkEmpty && builderForm.tourCountry && builderForm.tourCountry.value == '' && 'selectError'
                }
              />
            </div>

            <div style={{ width: '85%', marginLeft: '10%', marginRight: '5%', marginTop: '20px' }}>
              <label className="dropdown_label">TOUR CITY *</label>
              <CreatableSelect
                value={builderForm.tourCity}
                onChange={(val) => {
                  onDropdownChangedHandler(val, 'tourCity')
                }}
                options={countriesCities}
                styles={customStyles}
                className={
                  chkEmpty && builderForm.tourCity && builderForm.tourCity.value == "" && 'selectError'
                }
              />
            </div>

            <div style={{ width: '85%', marginLeft: '10%', marginRight: '5%', marginTop: '20px' }}>
              <label className="dropdown_label">TOUR TYPE *</label>
              <Select
                value={builderForm.tourType}
                onChange={(val) => onDropdownChangedHandler(val, 'tourType')}
                options={TOUR_TYPE_OPTIONS}
                styles={customStyles}
                className={
                  chkEmpty && builderForm.tourType && builderForm.tourType.value == '' && 'selectError'
                }
              />
            </div>

            <div style={{ width: '85%', marginLeft: '10%', marginRight: '5%', marginTop: '20px' }}>
              <label className="dropdown_label">TOUR MODE *</label>
              <Select
                value={builderForm.tourMode}
                onChange={(val) => onDropdownChangedHandler(val, 'tourMode')}
                options={TOUR_MODE_OPTIONS}
                className={
                  chkEmpty && builderForm.tourMode && builderForm.tourMode.value == '' && 'selectError'
                }
                styles={customStyles}
              />
            </div>

            <div style={{ width: '85%', marginLeft: '10%', marginRight: '5%', marginTop: '20px' }}>
              <label className="dropdown_label">TOUR SPECIALTY *</label>
              <Select
                value={builderForm.tourSpecialty}
                onChange={(val) =>
                  onDropdownChangedHandler(val, 'tourSpecialty')
                }
                options={TOUR_SPECIALTY_OPTIONS}
                className={
                  chkEmpty && builderForm.tourSpecialty && builderForm.tourSpecialty.value == '' && 'selectError'
                }
                styles={customStyles}
              />
            </div>

            <ImageUploadWidget
              label="COVER IMAGE *"
              showLoaderDialog={showLoaderDialog}
              uploadFile={uploadFile}
              showDialog={showDialog}
              unSetDialog={unSetDialog}
              tourImages={tourImages}
              setTourImages={(images) => setTourImages(images)}
              removeTourImage={removeTourImage}
              chkEmpty={chkEmpty}
            />

            <InputWidget
              chkEmpty={chkEmpty}
              elementType={builderForm['tourismDescription'].elementType}
              elementConfig={builderForm['tourismDescription'].elementConfig}
              value={builderForm['tourismDescription'].value}
              elementLabel={builderForm['tourismDescription'].elementLabel}
              floatingButton={builderForm['tourismDescription'].floatingButton}
              changed={(event) =>
                onInputChangedHandler(
                  event,
                  builderForm['tourismDescription'].id,
                )
              }
            />

            <TourAudioWidget
              showLoaderDialog={showLoaderDialog}
              uploadFile={uploadFile}
              tourCentralAudio={tourCentralAudio}
              setTourCentralAudio={(audio) => setTourCentralAudio(audio)}
              showDialog={showDialog}
              unSetDialog={unSetDialog}
              chkEmpty={chkEmpty}
            />

            <div style={{ width: '85%', marginLeft: '10%', marginRight: '5%', marginTop: '20px' }}>
              <label className="dropdown_label">TOUR LANGUAGE *</label>
              <Select
                value={builderForm.tourLanguage}
                onChange={(val) =>
                  onDropdownChangedHandler(val, 'tourLanguage')
                }
                options={cleanLanguageOptions(LANGUAGE_OPTIONS)}
                className={
                  chkEmpty && builderForm.tourLanguage && builderForm.tourLanguage.value == '' && 'selectError'
                }
                styles={customStyles}
              />
            </div>

            <div style={{ width: '85%', marginLeft: '10%', marginRight: '5%', marginTop: '20px' }}>
              <label className="dropdown_label">TOUR CURRENCY *</label>
              <Select
                value={builderForm.tourCurrency}
                onChange={(val) =>
                  onDropdownChangedHandler(val, 'tourCurrency')
                }
                options={CURRENCY_OPTIONS}
                className={
                  chkEmpty && builderForm.tourCurrency == '' && 'selectError'
                }
                styles={customStyles}
              />
            </div>

            <InputWidget
              chkEmpty={chkEmpty}
              elementType={builderForm['tourPrice'].elementType}
              elementConfig={builderForm['tourPrice'].elementConfig}
              value={builderForm['tourPrice'].value}
              elementLabel={builderForm['tourPrice'].elementLabel}
              floatingButton={builderForm['tourPrice'].floatingButton}
              prefix={builderForm['tourPrice']['prefix']}
              changed={(event) => {
                onInputChangedHandler(event, builderForm['tourPrice'].id)
              }}
            />

            {/*<button
              className="save_btn"
              style={{ background: isError ? 'red' : '' }}
              onClick={createOrEditTourHandler}
            >
              {props.isEdit 
                ? isComplete
                  ? 'Update Tour'
                  : 'Save Tour' 
                : 'Create Tour'}
            </button>*/}
          </div>
        </div>
        <div>
          <div ref={mapContainer} className="map">
            {!isSearchVisible && selectedStop != '' ? (
              <div className="map_helper_txt">
                <img src={HelpIcon} />
                { selectedARMarkerType == '' ? <p> Clicking on the map will place the </p> 
                                         : <p> Clicking on the map will place an </p>}
                { selectedARMarkerType == '' ? <p className="subtext_colored_3"> Stop Marker </p>
                                         : <p className="subtext_colored_3"> AR Marker [{prettifyARMarkerType(selectedARMarkerType)}] </p>}
                <p> against </p>
                <p className="subtext_colored_3"> Stop {stops[selectedStop]["order"] + 1} [{clipText(stops[selectedStop]["name"])}] </p>
              </div>
            ) : 
            null}

            {!isSearchVisible && selectedStop == '' ? (
              <div className="map_helper_txt">
                <img src={HelpIcon} />
                <p> Click on the map to create the next</p>
                <p className="subtext_colored_3">Stop Marker </p>
              </div>
            ) : 
            null}

            {isSearchVisible ? (
              <div id="search_map_bar" className="slide_in_search">
                <SearchField
                  customStyles={customStyles}
                  search={(searchTerm) => {
                    if (searchTerm.length > 0) {
                      updateMapBySearchLocation(
                        map.current,
                        searchTerm,
                        setUpdatedLocation,
                        () => {
                          setIsSearchVisible(false)
                        },
                      )
                    }
                  }}
                  onBlur={() => {setIsSearchSlideOut(true)}}
                />
              </div>
            ) : (
              <button className="map_search_btn">
                <img
                  src={SearchIcon}
                  onClick={() => setIsSearchVisible(true)}
                />
              </button>
            )}

            <button className="zoom_in_btn">
              <img
                src={PlusIcon}
                onClick={() => map.current.zoomTo(map.current.getZoom() + 1)}
              />
            </button>

            <button className="zoom_out_btn">
              <img
                src={MinusIcon}
                onClick={() => map.current.zoomTo(map.current.getZoom() - 1)}
              />
            </button>

            <button className="map_center_btn">
              <img
                src={CenterIcon}
                onClick={() => setIsLocationSetterDialogVisible(true)}
                /*onClick={() => {
                  const coordinates = Object.values(stops).map(item => ({
                                                        lat: item.location.lat,
                                                        lng: item.location.lng
                                                      }));
                  let bounds = new mapboxgl.LngLatBounds(
                      [coordinates[0].lng, coordinates[0].lat],
                      [coordinates[0].lng, coordinates[0].lat]
                  );

                  // Extend the bounds for each coordinate
                  for (const coord of coordinates) {
                      bounds.extend([coord.lng, coord.lat]);
                  }

                  map.current.fitBounds(bounds, { duration: 500, padding: 70});
                }}*/
              />
            </button>
          </div>
          <div className="bottom_right_bar" onMouseEnter={()=>handleBottomRightBarHover(true)} onMouseLeave={()=>handleBottomRightBarHover(false)}>
            { hideBottomRightBar 
                ? <div className="ar_helper_text"> <p> Please select a created stop from the left bar first! </p> </div>
                : <ARObjectsContainer
                    selectedARMarkerType={selectedARMarkerType}
                    onARMarkerClickedHandler={(arObject) => selectARMarker(arObject)}
                    showError={selectedStop == ''}
                  />
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default TourBuilder
