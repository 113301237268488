import { useState, useEffect, useRef } from 'react'
import AudioWidget from '../AudioWidget/AudioWidget'
import AudioRecordingDialog from '../AudioRecordingDialog/AudioRecordingDialog'
import { useReactMediaRecorder } from 'react-media-recorder'
import { blobToBase64 } from '../../Utils/utils'

import { infoToast, successToast, errorToast, warningToast } from "../Dialog/Toast";

import  { UPLOAD_SIZE_LIMIT, UPLOAD_SIZE_LIMIT_TEST } from '../../constants'

function TourAudioWidget({
  showLoaderDialog,
  uploadFile,
  tourCentralAudio,
  setTourCentralAudio,
  showDialog,
  unSetDialog,
  chkEmpty,
}) {
  const tourAudioUploadRef = useRef(null)
  const [save, setSave] = useState(false)
  const [openRecordingCard, setOpenRecordingCard] = useState(false)

  const {
    status,
    startRecording,
    stopRecording,
    pauseRecording,
    mediaBlobUrl,
    clearBlobUrl,
  } = useReactMediaRecorder({ video: false })

  useEffect(() => {
    if (mediaBlobUrl !== undefined) {
      showLoaderDialog()

      const getBlob = async (blobURL) => {
        let blob = await fetch(blobURL).then((r) => r.blob())

        const getBase64 = async (blob) => {
          let base64 = await new Promise((resolve) =>
            resolve(blobToBase64(blob)),
          )

          getDuration(base64, (duration) => {
            uploadFile(
              'audio',
              `audio_rec_${Math.floor(new Date().getTime() / 1000)}.mp3`,
              base64.split(',')[1],
              (url, identifier) => {
                unSetDialog()
                clearBlobUrl()
                setTourCentralAudio({
                  url,
                  name: identifier,
                  length: duration,
                })
                successToast('Audio added successfully')
              },
            )
          })
        }

        getBase64(blob)
      }

      getBlob(mediaBlobUrl)
    }
  }, [save])

  useEffect(() => {}, [mediaBlobUrl])

  const getDuration = (src, cb) => {
    var audio = new Audio(src)
    audio.addEventListener('durationchange', function (e) {
      if (this.duration != Infinity) {
        const duration = this.duration
        audio.remove()

        cb(duration)
      }
    })
    audio.load()
    audio.currentTime = 24 * 60 * 60
    audio.volume = 0
    audio.play()
  }

  const isAudioFile = (file) => {
    const allowedTypes = ["audio/mp3", "audio/wav", "audio/m4a", "audio/mpeg"];

    return allowedTypes.includes(file.type);
  };

  return (
    <div className="audio_upload_widget">
      <label>ATTACH AUDIO DESCRIPTION *</label>
      <div>
        <div
          className="upload_btn"
          onClick={() => {
            tourAudioUploadRef.current.click()
          }}
          style={{
            display: tourCentralAudio != null ? 'none' : '',
            border: chkEmpty && (tourCentralAudio === '' || tourCentralAudio == null) && '1px solid red',
          }}
        >
          Upload Audio
        </div>
        <div
          className="upload_btn"
          // onClick={status === "recording" ? stopRecording : startRecording}
          onClick={() => setOpenRecordingCard(true)}
          style={{
            margin: '15px 0',
            display: tourCentralAudio != null ? 'none' : '',
          }}
        >
          {status === 'recording' ? 'Stop Recording' : 'Record Audio'}
        </div>
        <input
          ref={tourAudioUploadRef}
          type="file"
          onChange={async (e) => {
            const file = e.target.files[0];

            if (file && isAudioFile(file)) {
              let result_base64 = await new Promise((resolve) => {
                var reader = new window.FileReader()
                reader.onload = (e) => resolve(reader.result)
                reader.readAsDataURL(e.target.files[0])
              })

              let fileSize = e.target.files[0].size

              console.log("File size: "+fileSize)

              if(fileSize > UPLOAD_SIZE_LIMIT){
                errorToast("File size must be less than 30MB")
              } else {
                let base64 = result_base64

                showLoaderDialog()

                getDuration(base64, (duration) => {
                  uploadFile('audio', e.target.files[0].name , base64.split(',')[1], (url, identifier) => {
                    unSetDialog()
                    setTourCentralAudio({
                      url,
                      name: identifier,
                      length: duration,
                    })
                    successToast('Audio added successfully')
                  })
                })
              }
            } else {
              errorToast('Please select a valid audio file.');
            }
            
          }}
          style={{ display: 'none' }}
          accept=".mp3, .wav, .ogg"
        />

        {openRecordingCard ? (
          <AudioRecordingDialog
            openRecordingCard={openRecordingCard}
            stopRecording={stopRecording}
            pauseRecording={pauseRecording}
            startRecording={startRecording}
            clearBlobUrl={clearBlobUrl}
            status={status}
            audio={mediaBlobUrl}
            onSave={() => {
              setSave((prev) => !prev)
              setOpenRecordingCard(false)
            }}
            onClose={() => {
              setOpenRecordingCard(false)
            }}
          />
        ) : (
          <></>
        )}

        {tourCentralAudio != null ? (
          <AudioWidget
            audio={tourCentralAudio.url}
            audioName={tourCentralAudio.name}
            onRemoveAudio={() =>
              showDialog(
                'alert',
                `Are you sure that you want to delete this audio?`,
                'DELETE',
                () => {
                  setTourCentralAudio(null)
                  unSetDialog()
                },
                'CANCEL',
                () => unSetDialog(),
              )
            }
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default TourAudioWidget
