import PlaceholderAddImage from '../../assets/imgs/placeholder_add_img.png'
import CancelIcon from '../../assets/imgs/close_icon.png'
import {useState, useEffect} from 'react'
import { infoToast, successToast, errorToast, warningToast } from "../Dialog/Toast";

function StopPictureDialog({
  stops,
  updateStop,
  selectedStopImage,
  setSelectedStopImage,
  stopImageRef,
  stopPictureUploadRef,
  selectedStop,
  showDialog,
  unSetDialog,
  isVisible,
  setIsVisible,
}) {

  const [tempStopPictureUploadURL, setTempStopPictureUploadURL] = useState(null)
  const [imageToUpload, setImageToUpload] = useState(null)
  const [imageToUploadName, setImageToUploadName] = useState(null)

  const isImageFile = (file) => {
    const allowedTypes = ["image/png", "image/jpg", "image/jpeg"];

    return allowedTypes.includes(file.type);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const imageName = event.target.files[0].name;

    if (file && isImageFile(file)) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const dataUrl = reader.result; // This is the file URL in base64 format
        // Use the dataUrl as needed (e.g., display it in an image)
        //setSelectedStopImage(dataUrl);
        setTempStopPictureUploadURL(dataUrl);
      };

      reader.readAsDataURL(file);

      setImageToUpload(file);
      setImageToUploadName(imageName);
    } else {
      errorToast('Please select a valid image file.');
    }
  };

  useEffect(() => {
    if(!isVisible){
      setTempStopPictureUploadURL(null)
    }
  },[isVisible])

  const replacePicture = () => {
    stopPictureUploadRef.current.click()
  }

  const uploadNewPicture = () => {
    setIsVisible(false)
    updateStop(selectedStop, {
                  img: imageToUpload,
                  imgName: imageToUploadName,
                  img_index: selectedStopImage })
    stopPictureUploadRef.current.value = '';
  }

  return (
    <div id="stopPictureDialog" style={{ display: isVisible ? '' : 'none' }}>
      <div
        className="dialog_bg"
        onClick={() => {
          //setSelectedStopImage(-1)
          setIsVisible(false)
        }}
      ></div>
      <div id="stopPictureDialogBody">
        <div className="dialog_header">
          <h3>Edit Stop Picture</h3>
          <img
            src={CancelIcon}
            alt="Close Icon"
            onClick={() => {
              //setSelectedStopImage(-1)
              setIsVisible(false)
              setTempStopPictureUploadURL(null)
            }}
          />
        </div>

        <div className="img_container">
          {/*<img
            ref={stopImageRef}
            src={
              selectedStop != '' && selectedStopImage >= 0
                ? stops[selectedStop]['imgs'][selectedStopImage]['link']
                : PlaceholderAddImage
            }
          />*/}

          <img
            ref={stopImageRef}
            src={
              stopPictureUploadRef.current !== null && stopPictureUploadRef.current.files.length > 0 
                && tempStopPictureUploadURL !== null
                ? tempStopPictureUploadURL
                : selectedStop != '' && selectedStopImage >= 0 && ('link' in stops[selectedStop]['imgs'][selectedStopImage])
                    ? stops[selectedStop]['imgs'][selectedStopImage]['link']
                    : PlaceholderAddImage
            }
          />
          {/*<button onClick={() => stopPictureUploadRef.current.click()}>
            Replace Picture
          </button>*/}
          <input
            ref={stopPictureUploadRef}
            type="file"
            style={{ display: 'none' }}
            accept="image/png, image/gif, image/jpeg"
            onChange={handleFileChange}
          />
        </div>

        <div className="action_buttons_2">
          <button
            className="save_btn delete_btn"
            style={{ marginLeft: '0' }}
            onClick={() => {
              setIsVisible(false)
              showDialog(
                'alert',
                'Are you sure that you want to delete this image?',
                'DELETE',
                () => {
                  updateStop(selectedStop, {
                    img_index: selectedStopImage,
                  })
                  setSelectedStopImage(-1)
                  stopPictureUploadRef.current.value = ''
                  unSetDialog()
                },
                'CANCEL',
                () => unSetDialog(),
              )
            }}
          >
            Delete
          </button>
          <div className="edit_btns">
            {/*<button
              className="save_btn"
              style={{ marginLeft: '0', opacity: 0, pointerEvents: 'none' }}
              onClick={() => {
                setSelectedStopImage(-1)
                setIsVisible(false)
              }}
            >
              Cancel
            </button>*/}
            {/*<button
              className="save_btn"
              style={{ marginLeft: '0' }}
              onClick={() =>
                stopPictureUploadRef.current &&
                stopPictureUploadRef.current.files.length
                  ? setIsVisible(false)
                  : setIsVisible(false)
              }
            >
              {stopPictureUploadRef.current &&
              stopPictureUploadRef.current.files.length > 0
                ? 'Upload'
                : 'Ok'}
            </button>*/}
            <button 
              className="normal_btn"
              onClick={stopPictureUploadRef.current &&
                        stopPictureUploadRef.current.files.length > 0 &&
                        tempStopPictureUploadURL !== null
                        ? uploadNewPicture
                        : replacePicture}
            >
              {stopPictureUploadRef.current &&
              stopPictureUploadRef.current.files.length > 0 &&
              tempStopPictureUploadURL !== null
                ? 'Upload'
                : 'Replace'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StopPictureDialog
