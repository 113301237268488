import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios'
import { useAuth } from '../../Contexts/AuthContext'
import { useProfile } from '../../Contexts/ProfileContext'
import { useNavigate } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import { useDialog } from '../../Contexts/DialogContext'
import InputWidget from '../InputWidget/InputWidget'
import {
  READ_USER_DATA,
  FIREBASE_API_KEY,
  READ_GUIDE_TOURS,
  READ_TOUR_DATA,
  READ_GUIDE_REVIEWS,
  DELETE_TOUR,
} from '../../constants'
import Dialog from '../Dialog/Dialog'

import './styles/dashboard.css'

import TickIcon from '../../assets/imgs/tick_icon.png'
import SearchIcon from '../../assets/imgs/search_icon.png'
import { async } from '@firebase/util'
import { useCache } from '../../Hooks/useCache'
import UserDataSidePanel from './UserDataSidePanel'
import { TourCard } from './TourCard'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Dashboard() {
  const navigate = useNavigate()
  const { currentUser, logoutCurrentUser, idToken, isSuperUser } = useAuth()
  const { setDialog, unSetDialog } = useDialog()
  const { profileData, setProfileData, setUserPrefData } = useProfile()
  const { addToCache, getCache } = useCache()

  const [searchTerm, setSearchTerm] = useState('')
  const [loadingTours, setLoadingTours] = useState(true)
  const [tours, setTours] = useState([])

  const [tourIds, setTourIds] = useState([])
  const [ratings, setRatings] = useState({})

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  useEffect(() => {
    let isMounted = true;
    
    if (currentUser && idToken != null) {
      if (profileData == null) {
        showLoaderDialog()
        axios
          .get(READ_USER_DATA, {
            headers: {
              Authorization: `Token ${idToken}`,
            },
            params: {
              uid: currentUser.uid,
              userType: 'guide',
            },
          })
          .then((res) => {
            unSetDialog()
            if (res.status == 200) {
              // setUserDetails(res.data.data);
              addToCache('profile', res.data.data)
              setProfileData(res.data.data)
              console.log("User data: " + res.data.data)
            }
          })
          .catch((e) => {
            console.log(e)
          })
      }

      // axios
      //   .get(READ_GUIDE_REVIEWS, {
      //     headers: {
      //       Authorization: `Token ${idToken}`,
      //     },
      //     params: {
      //       uid: currentUser.uid,
      //       userType: "guide",
      //     },
      //   })
      //   .then((res) => {
      //     console.log(res);
      //     if (res.status == 200) {
      //       console.log(res.data);
      //       // setUserDetails(res.data.data);
      //     }
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   });
      const toursFromCache = getCache('Tours')
      if (toursFromCache != null) {
        setTours(toursFromCache)
      }

      let tourIDList = []

      axios
        .get(READ_GUIDE_TOURS, {
          headers: {
            Authorization: `Token ${idToken}`,
          },
          params: {
            uid: currentUser.uid,
            userType: 'guide',
          },
        })
        .then(async (res) => {
          if (res.status == 200) {
            if ('data' in res.data) {
              const tours = res.data.data

              addToCache('Tours', tours)

              let tourIDList = []

              for(let i in tours){
                tourIDList.push(tours[i]["tourId"])
              }

              setTours(res.data.data)
              setLoadingTours(false)
              setTourIds(tourIDList)
            }
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }
    // }
    return () => {
      isMounted = false; // Set the flag to false on unmount
    };
  }, [currentUser, idToken])

  useEffect(() => {/*console.log("idToken: " + idToken)*/}, [searchTerm])

  const showLoaderDialog = () => {
    let dialog = {
      isLoader: true,
    }

    setDialog(dialog)
  }

  const showDialog = (type, message, btnTxt, btnAction) => {
    let dialog = {
      type: type,
      message: message,
      btnTxt: btnTxt != undefined ? btnTxt : 'OK',
      btnAction: btnAction == undefined ? () => unSetDialog() : btnAction,
    }

    setDialog(dialog)
  }

  const getFilteredTours = useMemo(() => {
    let searchTermLower = searchTerm.toLowerCase().trim()

    console.log("Tours: ",tours)

    return tours?.filter((tour) =>
      tour['name']?.toLowerCase().includes(searchTermLower),
    )
  }, [tours, searchTerm])

  const fetchReviews = async () => {
    //console.log("Reviews time!!!")

    let aggregatedRatings = {}

    const requests = []
    for(const i in tourIds){
      //console.log("Reviews time!!!: " + tourIds[i])

      requests.push(axios
      .get(READ_GUIDE_REVIEWS, {
        params: {
          tourId: tourIds[i],
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${idToken}`,
        },
      }))
    }

    Promise.all(requests)
    .then((responses) => {
      for(const i in responses){
        const reviewsData = responses[i]
        const reviews = reviewsData.data.data.reviews
        console.log("Reviews against "+tourIds[i]+ " : "+JSON.stringify(reviews))

        let numReviews = reviews.length
        let runningSum = 0
        for(const i in reviews){
          runningSum += parseFloat(reviews[i]["rating"])
        }

        let avgRating = ((runningSum / (numReviews * 5))*5).toFixed(1)

        aggregatedRatings[tourIds[i]] = {"rating": avgRating, "count": numReviews}
      }

      setRatings(aggregatedRatings)
    })
    .catch((error) => {
      // Handle if any of the requests failed
      console.error('Some requests failed:', error);
    })
    
  }

  useEffect(() => {
    if(!isEmpty(tourIds)){
      fetchReviews()
    }
  },[tourIds])

  return (
    <div id="dashboard">
      <UserDataSidePanel
        profileData={profileData}
        setProfileData={setProfileData}
        setUserPrefData={setUserPrefData}
        logoutCurrentUser={logoutCurrentUser}
        tours={tours}
        showLoaderDialog={showLoaderDialog}
        unSetLoaderDialog={unSetDialog}
        ratingVal={(profileData != null && profileData['rating'] != null) ? profileData?.rating?.average : 0}
      />

      <div className="Tour">

        <div className="featured">
          <div className="dashboard_header">
            <div className="dashboard_span">
              <span> {!isSuperUser && "My"} Tours ({tours.length})</span>
            </div>

            <div className="dashboard_search_bar"> 
              <InputWidget
                elementType="input"
                elementConfig={{
                  type: 'text',
                  name: 'searchTours',
                  placeholder: 'Filter by keyword',
                }}
                value={searchTerm}
                changed={(event) => {
                  setSearchTerm(event.target.value)
                }}
              />
            </div>
          </div>

          <div className="new_tour_button">
            <button onClick={() => navigate('/builder')}>
              + Build new tour
            </button>
            {/* <select>All time</select>  */}
          </div>
        </div>



        <div className="TourGuides">
          <div className="cards">
            {getFilteredTours.map((tour, i) => (
              <TourCard
                showDialog={showDialog}
                tours={tours}
                setTours={setTours}
                idToken={idToken}
                key={`tour_${i}`}
                {...tour}
                ratings={ratings}
              />
            ))}
            {loadingTours ? <div className="lds-dual-ring-2"></div> : ''}
          </div>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  )
}

export default Dashboard
