import React, { useState, useEffect } from 'react'
import { useReactMediaRecorder } from 'react-media-recorder'
import micImage from '../../assets/imgs/mic_icon.png'
import AudioWidget from '../AudioWidget/AudioWidget'
import { blobToBase64 } from '../../Utils/utils'
import AudioRecordingDialog from '../AudioRecordingDialog/AudioRecordingDialog'

function StopAudio({
  stopAudio,
  currentStop,
  onStopAudioChange,
  onUploadClickedHandler,
  showLoaderDialog,
  onRemoveAudio,
}) {
  const [save, setSave] = useState(false)
  const [stopAudioActive, setStopAudioActive] = useState(false)
  const [openRecordingCard, setOpenRecordingCard] = useState(false)
  const {
    status,
    startRecording,
    stopRecording,
    pauseRecording,
    mediaBlobUrl,
    clearBlobUrl,
  } = useReactMediaRecorder({ video: false })

  useEffect(() => {
    if (mediaBlobUrl != undefined) {
      showLoaderDialog()

      const getBlob = async (blobURL) => {
        let blob = await fetch(blobURL).then((r) => r.blob())

        const getBase64 = async (blob) => {
          let base64 = await new Promise((resolve) =>
            resolve(blobToBase64(blob)),
          )

          onStopAudioChange(currentStop, {
            recording: base64.split(',')[1],
            recordingName: `audio_rec_${Math.floor(
              new Date().getTime() / 1000,
            ).toString().substring(0, 8)}.mp3`,
          })

          clearBlobUrl()
        }

        getBase64(blob)
      }

      getBlob(mediaBlobUrl)
    }
  }, [save])

  return (
    <div>
      {openRecordingCard ? (
        <AudioRecordingDialog
          openRecordingCard={openRecordingCard}
          stopRecording={stopRecording}
          pauseRecording={pauseRecording}
          startRecording={startRecording}
          clearBlobUrl={clearBlobUrl}
          status={status}
          audio={mediaBlobUrl}
          onSave={() => {
            setSave((prev) => !prev)
            setOpenRecordingCard(false)
          }}
          onClose={() => {
            setOpenRecordingCard(false)
          }}
        />
      ) : (
        <></>
      )}
      {stopAudio.audioURL ? (
        <AudioWidget
          audio={stopAudio.audioURL}
          audioName={stopAudio.audioName}
          onRemoveAudio={() => onRemoveAudio()}
        />
      ) : stopAudio.audio && stopAudio.audioName ? (
        <AudioWidget
          audio={stopAudio.audio}
          audioName={stopAudio.audioName}
          onRemoveAudio={() => onRemoveAudio()}
        />
      ) : (
        <>
          <div
            className={
              stopAudioActive ? 'stop_audio_btn_active' : 'stop_audio_btn'
            }
          >
            <div
              className={
                stopAudioActive
                  ? 'audio_mic_icon audio_mic_icon_active'
                  : 'audio_mic_icon'
              }
              onClick={() => setStopAudioActive(!stopAudioActive)}
            >
              <img src={micImage} />
            </div>
            {stopAudioActive ? (
              <>
                <div className="" onClick={onUploadClickedHandler}
                  style={{
                    margin: '15px 0',
                    cursor: 'pointer',
                  }}>
                  Upload Audio
                </div>
                <div
                  className=""
                  // onClick={status === 'recording' ? stopRecording : startRecording}
                  onClick={() => setOpenRecordingCard(true)}
                  style={{
                    margin: '15px 0',
                    cursor: 'pointer',
                  }}
                >
                  {status === 'recording' ? 'Stop Recording' : 'Record Audio'}
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default StopAudio
