import React, { useState } from 'react'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import logo from '../../assets/insidartours_logo.png'
import { useWarningOnNavigate } from '../../Contexts/WarningOnNavigateContext'
import './styles/navbar.css'

function Navbar() {
  const navigate = useNavigate()
  const location = useLocation()
  const { isShowWarning, setIsShowWarning } = useWarningOnNavigate()

  // const getClassName = (tab) => {
  //   switch (tab) {
  //     case "dashboard":
  //       return location.pathname == "/" ? "nav_active_tab" : "";
  //     case "builder":
  //       return location.pathname == "/builder" ? "nav_active_tab" : "";
  //     case "analytics":
  //       return location.pathname == "/analytics" ? "nav_active_tab" : "";
  //     case "edit_profile":
  //       return location.pathname == "/profile/edit" ? "nav_active_tab" : "";
  //     case "settings":
  //       return location.pathname == "/settings" ? "nav_active_tab" : "";
  //     default:
  //       return "nav_active_tab";
  //   }
  // };

  return (
    <div id="navbar">
      <div className="navlogo">
        <img
          src={logo}
          alt=""
          onClick={() => {
            if (location.pathname.includes('builder')) {
              setIsShowWarning('/')
            } else {
              navigate('/')
            }
          }}
          style={{ height: '3.4em' }}
        />
      </div>
      <div className="nav">
        <NavLink
          to="/"
          className={location.pathname == '/' ? 'nav_active_tab' : ''}
          onClick={(e) => {
            if (location.pathname.includes('builder')) {
              e.preventDefault()
              setIsShowWarning('/')
            }
          }}
        >
          Dashboard
        </NavLink>
        <NavLink
          to="/builder"
          className={({ isActive }) => (isActive ? 'nav_active_tab' : '')}
          onClick={(e) => {
            if (location.pathname.includes('builder')) {
              e.preventDefault()
              setIsShowWarning('/builder')
            }
          }}
        >
          Tour Builder
        </NavLink>
        <NavLink
          to="/analytics"
          className={({ isActive }) => (isActive ? 'nav_active_tab' : '')}
          onClick={(e) => {
            if (location.pathname.includes('builder')) {
              e.preventDefault()
              setIsShowWarning('/analytics')
            }
          }}
        >
          Analytics
        </NavLink>
        <NavLink
          to="/profile/edit"
          className={({ isActive }) => (isActive ? 'nav_active_tab' : '')}
          onClick={(e) => {
            if (location.pathname.includes('builder')) {
              e.preventDefault()
              setIsShowWarning('/profile/edit')
            }
          }}
        >
          Profile
        </NavLink>
        <NavLink
          to="/settings"
          className={({ isActive }) => (isActive ? 'nav_active_tab' : '')}
          onClick={(e) => {
            if (location.pathname.includes('builder')) {
              e.preventDefault()
              setIsShowWarning('/settings')
            }
          }}
        >
          Settings
        </NavLink>
      </div>
    </div>
  )
}

export default Navbar
