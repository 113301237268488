import React, { useState } from 'react'
import InputWidget from '../InputWidget/InputWidget'
import { updateMapByCoordinates } from '../../Utils/BuilderUtils'
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax

function LocationSelectionDialog({
  isVisible,
  setIsVisible,
  map,
  showErrorDialog,
  chkEmpty,
  stops,
}) {
  const [location, setLocation] = useState({
    latitude: 0,
    longitude: 0,
  })

  return (
    <div
      id="locationSelectionDialog"
      style={{ display: isVisible ? '' : 'none' }}
    >
      <div className="dialog_bg" onClick={() => setIsVisible(false)}></div>
      <div id="locationSelectionDialogBody" className="edit_btns">
        <div className='move_section'>
          <h3>Quick Move</h3>

          <div className='buttons_row'>
            {stops && Object.values(stops).length != 0 && <button
              className="basic_btn"
              style={{ marginLeft: '0' }}
              onClick={() =>{
                const coordinates = Object.values(stops).map(item => ({
                  lat: item.location.lat,
                  lng: item.location.lng
                }));
                let bounds = new mapboxgl.LngLatBounds(
                [coordinates[0].lng, coordinates[0].lat],
                [coordinates[0].lng, coordinates[0].lat]
                );

                // Extend the bounds for each coordinate
                for (const coord of coordinates) {
                bounds.extend([coord.lng, coord.lat]);
                }

                map.fitBounds(bounds, { duration: 500, padding: 70});

                setIsVisible(false);
              }}
            >
              CENTER ON TOUR STOPS
            </button>}

            <button
              className="basic_btn"
              style={{ marginLeft: '0' }}
              onClick={() => {
                navigator.geolocation.getCurrentPosition(function (position) {
                  map.flyTo({
                    center: [position.coords.longitude, position.coords.latitude],
                    zoom: 14,
                  });

                  setIsVisible(false);
                });
              }}
            >
              MOVE TO MY LOCATION
            </button>
          </div>
        </div>
        <br/>

        <div className='move_section'>
          <h3 className='pull_down'>Move to Coordinates</h3>
          
          <InputWidget
            elementType="corsInput"
            chkEmpty={chkEmpty}
            elementConfig={{
              type: 'text',
              name: 'latitude',
            }}
            value={location['latitude']}
            elementLabel={'LATITUDE'}
            floatingButton={null}
            changed={(event) =>
              setLocation({
                ...location,
                latitude: event.target.value,
              })
            }
          />

          <InputWidget
            elementType="corsInput"
            chkEmpty={chkEmpty}
            elementConfig={{
              type: 'text',
              name: 'longitude',
            }}
            value={location['longitude']}
            elementLabel={'LONGITUDE'}
            floatingButton={null}
            changed={(event) =>
              setLocation({
                ...location,
                longitude: event.target.value,
              })
            }
          />

          <button
            className="expanded_btn"
            style={{ marginLeft: '0' }}
            onClick={() =>
              updateMapByCoordinates(map, location, setIsVisible, showErrorDialog)
            }
          >
            MOVE
          </button>
        </div>
      </div>
    </div>
  )
}

export default LocationSelectionDialog
