export const getDates = () => {
  const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

  let dates = []

  let date = new Date()
  let currentMonthDays = daysInMonth[new Date().getMonth()]

  for (let i = 0; i < currentMonthDays; i += 2) {
    dates.push(`${1 + i}/${date.getMonth()}/${date.getFullYear()}`)
  }

  return dates
}

// export function getBase64Image() {
//   var canvas = document.createElement("canvas");
//   // canvas.width = profileImageRef.current.width;
//   // canvas.height = profileImageRef.current.height;
//   var ctx = canvas.getContext("2d");
//   ctx.drawImage(profileImageRef.current, 0, 0);
//   var dataURL = canvas.toDataURL("image/png");
//   return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
// }

export function clipText(text) {
  let maxChars = 15

  if (text.length > maxChars) {
    return text.substring(0, maxChars) + '...';
  }
  return text;
}

export function prettifyARMarkerType(arMarkerType) {
  switch(arMarkerType){
    case 'iceCream':
      return 'Ice Cream';
    case 'washroom':
      return 'Washrooms';
    case 'wheelChair':
      return 'Wheelchair';
    case 'food':
      return 'Food Area';
    case 'coffeeShop':
      return 'Coffee Shop';
    case 'firstAid':
      return 'First Aid';
    default:
      return arMarkerType;
  }
}

export function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export function isNumeric(str) {
  if (typeof str !== 'string') return false // we only process strings!
  return (
    !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ) // ...and ensure strings of whitespace fail
}

export function formatDist(dist) {
  if (dist >= 1000) {
    let distInKm = (Math.ceil(dist / 100) * 100) / 1000

    return `${distInKm} km`
  } else {
    let distInM = Math.ceil(dist / 10) * 10

    return `${distInM} m`
  }
}

export function formatDur(dur) {
  if (dur / 60 >= 59) {
    let distInHrs = Math.ceil(dur / 3600)

    return `${distInHrs} hrs`
  } else {
    let distInM = Math.ceil(dur / 60)

    return `${distInM} min`
  }
}


export const CODE_LENGTHS = {
  AD: 24,
  AE: 23,
  AT: 20,
  AZ: 28,
  BA: 20,
  BE: 16,
  BG: 22,
  BH: 22,
  BR: 29,
  CH: 21,
  CR: 21,
  CY: 28,
  CZ: 24,
  DE: 22,
  DK: 18,
  DO: 28,
  EE: 20,
  ES: 24,
  FI: 18,
  FO: 18,
  FR: 27,
  GB: 22,
  GI: 23,
  GL: 18,
  GR: 27,
  GT: 28,
  HR: 21,
  HU: 28,
  IE: 22,
  IL: 23,
  IS: 26,
  IT: 27,
  JO: 30,
  KW: 30,
  KZ: 20,
  LB: 28,
  LI: 21,
  LT: 20,
  LU: 20,
  LV: 21,
  MC: 27,
  MD: 24,
  ME: 22,
  MK: 19,
  MR: 27,
  MT: 31,
  MU: 30,
  NL: 18,
  NO: 15,
  PK: 24,
  PL: 28,
  PS: 29,
  PT: 25,
  QA: 29,
  RO: 24,
  RS: 22,
  SA: 24,
  SE: 24,
  SI: 19,
  SK: 24,
  SM: 27,
  TN: 24,
  TR: 26,
  AL: 28,
  BY: 28,
  CR: 22,
  EG: 29,
  GE: 22,
  IQ: 23,
  LC: 32,
  SC: 31,
  ST: 25,
  SV: 28,
  TL: 23,
  UA: 29,
  VA: 22,
  VG: 24,
  XK: 20,
}

/*
 * Returns 1 if the IBAN is valid
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
function mod97(string) {
  let checksum = string.slice(0, 2),
    fragment
  for (let offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7)
    checksum = parseInt(fragment, 10) % 97
  }
  return checksum
}

export function isValidIBANNumber(input) {
  var iban = String(input)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
    code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
    digits
  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55
  })
  // final check
  return mod97(digits)
}
