import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { useAuth } from '../../Contexts/AuthContext'
import { useProfile } from '../../Contexts/ProfileContext'
import { useNavigate } from 'react-router-dom'
import {
  READ_USER_DATA,
  CREATE_TOUR_DATA,
  UPDATE_USER_DATA,
  FIREBASE_API_KEY,
} from '../../constants'
import { useDialog } from '../../Contexts/DialogContext'

import InputWidget from '../InputWidget/InputWidget'

import profile from '../../assets/dashboard/profile.png'
import LogoutIcon from '../../assets/imgs/logout_gray_icon.png'

import './styles/editProfile.css'
import { useCache } from '../../Hooks/useCache'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { infoToast, successToast, errorToast, warningToast } from "../Dialog/Toast";

function EditProfile() {
  const navigate = useNavigate()
  const { currentUser, logoutCurrentUser, idToken } = useAuth()
  const {
    profileData,
    setProfileData,
    setUserPrefData,
    reloadProfile,
  } = useProfile()
  const { setDialog, unSetDialog } = useDialog()

  const isLogoutRef = useRef(false)

  const [isProcessing, setIsProcessing] = useState(false)
  const [userDetails, setUserDetails] = useState({})
  const [profileForm, setProfileForm] = useState({
    firstName: {
      id: 'firstName',
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'firstName',
        placeholder: '',
        required: true,
      },
      value: '',
      elementLabel: 'FIRST NAME',
      floatingButton: null,
    },
    lastName: {
      id: 'lastName',
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'lastName',
        placeholder: '',
        required: true,
      },
      value: '',
      elementLabel: 'LAST NAME',
      floatingButton: null,
    },
    email: {
      id: 'email',
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        placeholder: '',
        required: true,
      },
      value: '',
      elementLabel: 'EMAIL',
      floatingButton: null,
    },
    location: {
      id: 'location',
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'location',
        placeholder: '',
        required: true,
      },
      value: '',
      elementLabel: 'LOCATION',
      floatingButton: null,
    },
    occupation: {
      id: 'occupation',
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'occupation',
        placeholder: '',
        required: false,
      },
      value: '',
      elementLabel: 'OCCUPATION',
      floatingButton: null,
    },
    tourismExperience: {
      id: 'tourismExperience',
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'tourismExperience',
        placeholder: '',
        required: false,
      },
      value: '',
      elementLabel: 'TOURISM EXPERIENCE',
      floatingButton: null,
    },
    specialty: {
      id: 'specialty',
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'specialty',
        placeholder: '',
        required: false,
      },
      value: '',
      elementLabel: 'SPECIALTY',
      floatingButton: null,
    },
    biography: {
      id: 'biography',
      elementType: 'textarea',
      elementConfig: {
        type: 'text',
        name: 'biography',
        placeholder: '',
      },
      value: '',
      elementLabel: 'BIOGRAPHY',
      floatingButton: null,
    },
  })
  const [profileImageFile, setProfileImageFile] = useState(null)

  const profileImageUploadRef = useRef(null)
  const profileImageRef = useRef(null)
  const { deleteAll, deleteCache } = useCache()

  const onInputChangedHandler = (event, inputIdentifier) => {
    const updatedProfileForm = { ...profileForm }
    const updatedFormElement = { ...updatedProfileForm[inputIdentifier] }
    updatedFormElement.value = event.target.value
    updatedProfileForm[inputIdentifier] = updatedFormElement
    setProfileForm(updatedProfileForm)
  }

  const initUserProfile = (userData) => {
    const updatedProfileForm = { ...profileForm }

    const allFields = Object.keys(updatedProfileForm)

    for (let i = 0; i < allFields.length; i++) {
      if (allFields[i] in userData) {
        const updatedFormElement = {
          ...updatedProfileForm[allFields[i]],
        }
        updatedFormElement.value = userData[allFields[i]]
        updatedProfileForm[allFields[i]] = updatedFormElement
      }
    }

    setProfileForm(updatedProfileForm)
    setUserDetails(userData)
  }

  useEffect(() => {
    if (currentUser && idToken !== null && !isLogoutRef.current) {
      if (profileData == null) {
        showLoaderDialog()
        axios
          .get(READ_USER_DATA, {
            headers: {
              Authorization: `Token ${idToken}`,
            },
            params: {
              uid: currentUser.uid,
              userType: 'guide',
            },
          })
          .then((res) => {
            if (res.status == 200) {
              setProfileData(res.data.data)
              initUserProfile(res.data.data)
            }
            unSetDialog()
          })
          .catch((e) => {
            unSetDialog()
            console.log(e)
          })
      } else {
        initUserProfile(profileData)
      }
    }
  }, [currentUser, profileData, idToken])

  const resetFieldValues = () => {
    const updatedProfileForm = { ...profileForm }

    const allFields = Object.keys(updatedProfileForm)

    for (let i = 0; i < allFields.length; i++) {
      const updatedFormElement = { ...updatedProfileForm[allFields[i]] }
      if (allFields[i] in userDetails) {
        updatedFormElement.value = userDetails[allFields[i]]
      } else {
        updatedFormElement.value = ''
      }
      updatedProfileForm[allFields[i]] = updatedFormElement
    }
    setProfileForm(updatedProfileForm)
    setProfileImageFile(null)
  }

  const getAllFieldValues = () => {
    let allFieldValues = {}

    const allFields = Object.keys(profileForm)

    for (let i = 0; i < allFields.length; i++) {
      allFieldValues[allFields[i]] = profileForm[allFields[i]].value
    }

    return allFieldValues
  }

  function readURL() {
    if (profileImageFile && profileImageRef.current) {
      var reader = new FileReader()

      reader.onload = function (e) {
        profileImageRef.current.setAttribute('src', e.target.result)
      }

      reader.readAsDataURL(profileImageFile)
    }
  }

  useEffect(() => {
    readURL()
  }, [profileImageFile])

  const onFormSubmitHandler = async (e) => {
    e.preventDefault()

    if (currentUser) {
      const allFieldValues = getAllFieldValues()
      const allFields = Object.keys(allFieldValues)

      let userData = {}
      for (let i = 0; i < allFields.length; i++) {
        userData[allFields[i]] = allFieldValues[allFields[i]]
      }

      let data = {
        uid: currentUser.uid,
        userType: 'guide',
        userData: userData,
      }

      if (profileImageFile != null) {
        data['profilePicture'] = profileImageRef.current.src.split(',')[1]
      }

      showLoaderDialog()
      setIsProcessing(true)
      const token = await currentUser.getIdToken()
      axios
        .put(UPDATE_USER_DATA, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${token}`,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            unSetDialog()
            if (res.data['status'] != 'error') {
              deleteCache('profile')
              reloadProfile(currentUser.uid)
              //showDialog('success', 'Profile updated successfully.')
              successToast("Profile updated successfully.")
              unSetDialog()
            }
          } else {
            setIsProcessing(false)
            /*showDialog(
              'error',
              'Incorrect Information Entered. Please try again.',
            )*/
            errorToast("Incorrect Information Entered. Please try again")
            unSetDialog()
          }
        })
        .catch((e) => {
          setIsProcessing(false)
          console.log(e)
          /*showDialog(
            'error',
            'Incorrect Information Entered. Please try again.',
          )*/
          errorToast("Incorrect Information Entered. Please try again")
          unSetDialog()
        })
    }
  }

  const showLoaderDialog = () => {
    let dialog = {
      isLoader: true,
    }

    setDialog(dialog)
  }

  const showDialog = (type, message, btnAction) => {
    let dialog = {
      type: type,
      message: message,
      btnTxt: 'OK',
      btnAction: btnAction == undefined ? () => unSetDialog() : btnAction,
    }

    setDialog(dialog)
  }

  const isImageFile = (file) => {
    const allowedTypes = ["image/png", "image/jpg", "image/jpeg"];

    return allowedTypes.includes(file.type);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file && isImageFile(file)) {
      setProfileImageFile(e.target.files[0])
    } else {
      errorToast('Please select a valid image file.');
    }
  }

  return (
    <div id="EditProfile">
      {/*<div className="editProfH">
        <span>Edit Profile</span>
      </div>*/}
      <div className="EPData">
        <div className="Uprofile">
          <div>
            <img
              ref={profileImageRef}
              src={
                profileImageFile != null
                  ? readURL()
                  : 'profilePictureURL' in userDetails
                  ? userDetails['profilePictureURL']
                  : profile
              }
              alt="eProfile"
            />
            <button onClick={() => profileImageUploadRef.current.click()}>
              Upload Photo
            </button>
            <input
              ref={profileImageUploadRef}
              type="file"
              onChange={(e) => handleFileChange(e)}
              style={{ display: 'none' }}
              accept="image/png, image/jpg, image/jpeg"
            />
          </div>
          <button
            className="profile_logout_btn"
            onClick={async () => {
              isLogoutRef.current = true
              setProfileData(null)
              setUserPrefData(undefined)
              deleteAll()
              await logoutCurrentUser()
              navigate('/auth')
            }}
          >
            <img src={LogoutIcon} /> <span>LOGOUT</span>
          </button>
        </div>
        <div>
          <form
            onSubmit={onFormSubmitHandler}
            autoComplete="off"
            style={{
              overflowY: 'hidden',
              /*height: 'calc(100vh - 185px - 1.2em)',*/
            }}
          >
            <div className="EditProfBtn">
              <div className="basic_info_heading">
                <span>Edit Profile</span>
              </div>
              <div className="setBtns">
                <button
                  onClick={(e) => {
                    e.preventDefault()
                    resetFieldValues()
                  }}
                >
                  CANCEL
                </button>
                <button  type="submit">SAVE</button>
              </div>
            </div>
            <div className="Editing">
              <div>
                {Object.keys(profileForm).map((key, index) =>
                  key != 'biography' ? (
                    <InputWidget
                      elementType={profileForm[key].elementType}
                      elementConfig={profileForm[key].elementConfig}
                      value={profileForm[key].value}
                      elementLabel={profileForm[key].elementLabel}
                      floatingButton={profileForm[key].floatingButton}
                      changed={(event) =>
                        onInputChangedHandler(event, profileForm[key].id)
                      }
                      isDisabled={key == 'email' && true}
                    />
                  ) : (
                    ''
                  ),
                )}
              </div>
              <div>
                {/*<div className="about_us_heading">
                  <span>About Me</span>
                </div>*/}
                <InputWidget
                  elementType={profileForm['biography'].elementType}
                  elementConfig={profileForm['biography'].elementConfig}
                  value={profileForm['biography'].value}
                  elementLabel={profileForm['biography'].elementLabel}
                  floatingButton={profileForm['biography'].floatingButton}
                  changed={(event) =>
                    onInputChangedHandler(event, profileForm['biography'].id)
                  }
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  )
}

export default EditProfile
